$violet: #4e2580;
$blueGrey: #52617a;
$blueGreen: #00565e;
$blockVioletBg: #edebf5;
$blockLightBg: #e6e9f0;
$light-grey: #bfbfbf;
$grey: #cccccc;
$dark-grey: #4d4c4c;
$light-violet: #cdc8e3;
$row-bg: #f2f2f2;

#enterprisePage {
  h1 {
    font-size: 42px;
    color: $violet;
    font-weight: bold;
  }

  h3 {
    color: white;
  }

  li {
    font-size: 20px;
    white-space: normal;
    word-break: keep-all;
    margin-bottom: 5px;
    line-height: 1.33;
  }

  .content-block {
    margin: 30px 0;
  }

  .upgrade-now-btn {
    color: white;
    line-height: 36px;
    padding: 10px 0;
    font-weight: bold;
    font-size: 24px;
    margin: 15px 0 0;
  }

  .container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
  }

  .row {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    margin-left: 0;
  }

  div[class*="col-"] {
    flex: 1;
    padding: 0 15px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  .key-features {
    width: 100%;
    height: 210px
  }

  /* ------------------------ Contact block ------------------------ */

  .contact {
    &__title {
      font-weight: bold;
    }

    &__btn {
      width: 100%;
      background-color: $violet;
      font-size: 27px;
      line-height: 33px;
      margin-left: 0;
    }
  }

  /* ------------------------ Contact block ------------------------ */

  /* ------------------------ Quantuvis contacts ------------------------ */

  .quantuvis-contacts {
    margin: 10px auto;

    &__row {
      display: flex;
    }

    &__telephone-icon, &__telephone-number, &__email, &__email-icon {
      flex-basis: auto;
      font-size: 21px;
      font-weight: normal;
    }

    &__telephone-icon {
      background-position:0 -356px;
      width: 24px;
      height: 24px;
      margin-right: 15px;
    }

    &__email-icon {
      background-position: 0 -154px;
      width: 24px;
      height: 24px;
      margin-right: 15px;
    }

    &__email {
      text-decoration: underline;
    }
  }

  /* ------------------------ Quantuvis contacts ------------------------ */

  /* ------------------------ Top Item Block ------------------------------*/

  .top-item {
    display: flex;
    align-items: center;
    flex-basis: 410px;
    font-size: 1.5rem;
    line-height: normal;
    font-weight: lighter;

    &--offset-left {
      margin-left: 30px;
    }

    &__image {
      width: 2.5rem;
      height: 2.5rem;
    }

    &__text {
      padding-left: 10px;
    }
  }

  /* ------------------------ Top Item Block ------------------------------*/

  /* ------------------------ Pharma Value block ------------------------- */

  .pharma-value {
    &__header {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      border-radius: 35px;

      &--violet {
        background-color: $violet;
      }

      &--grey {
        background-color: $blueGrey;
      }

      &--green {
        background-color: $blueGreen;
      }
    }

    &__content {
      margin-top: 15px;
    }

    &__icon {
      width: 2.5rem;
      height: 2.5rem;
    }

    &__title {
      padding-left: 10px;
      font-size: 1.5rem;
      color: white;
      font-weight: bold;
    }

    &__list {
      &--violet {
        list-style: square outside url('/assets/css/images/enterprise/circle-violet.svg');
      }

      &--grey {
        list-style: square outside url('/assets/css/images/enterprise/circle-grey.svg');
      }

      &--green {
        list-style: square outside url('/assets/css/images/enterprise/circle-green.svg');
      }
    }
  }

  /* ------------------------ Pharma Value block ------------------------- */


  /* ------------------------ Platform block ----------------------------- */

  .platform-block {
    padding: 25px;
    margin: 20px 0;
    position: relative;

    &--violet {
      background-color: $blockVioletBg;
    }

    &--light {
      background-color: $blockLightBg;
    }

    &__title {
      font-size: 30px;
      font-weight: bold;
      color: $violet;
      padding: 15px 0;
      line-height: 1.33;

      &--short {
        max-width: 230px;
      }

      &--md-short {
        max-width: 230px;
      }

      @media (min-width: 1200px) {
        &--short {
          max-width: 370px;
        }
      }

      @media (min-width: 1200px) {
        &--md-short {
          max-width: 100%;
        }
      }
    }

    &__icon {
      position: absolute;
      top: 15px;
      right: 0;
      width: 30%;
    }

    &__list {
      font-size: 22px;
      white-space: normal;
      word-break: keep-all;
      list-style: square outside url('/assets/css/images/enterprise/circle-violet-light.svg');

      &--light {
        list-style: square outside url('/assets/css/images/enterprise/circle-light.svg');
      }
    }

    &__list-item--short {
      max-width: 230px;
    }

    @media (min-width: 1200px) {
      &__list-item--short {
        max-width: 370px;
      }
    }

    &__list-item--lg-short {
      max-width: 100%;
    }

    @media (min-width: 1200px) {
      &__list-item--lg-short {
        max-width: 370px;
      }
    }
  }

  /* ------------------------ Platform block ----------------------------- */

  /* ------------------------ Feature table ------------------------------ */

  .feature-table {
    display: flex;
    flex-flow: column nowrap;
    font-size: .8rem;
    margin: 0.5rem;
    line-height: 1.5;
    flex: 1 1 auto;

    &__unchecked-icon {
      width: 26px;
      height: 26px;
    }

    &__checked-icon {
      width: 28px;
      height: 28px;
    }

    &__row {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      font-weight: bold;
      background-color: $row-bg;


      &-item {
        display: block;
        width: 100%;
        padding: 5px 5px 5px 15px;
        font-weight: 600;
        font-size: 18px;
        border: solid 1px white;

        &--center {
          text-align: center;
        }

        &--header {
          font-size: 24px;
          text-transform: uppercase;
          color: white;
          padding: 0 5px;
        }

        &--light-grey {
          background-color: $light-grey;
        }

        &--grey {
          background-color: $grey;
        }

        &--dark-grey {
          background-color: $dark-grey;
        }

        &--violet {
          background-color: $violet;
        }

        &--light-violet {
          background-color: $light-violet;
        }
      }
    }
  }

  /* ------------------------ Feature table ------------------------------ */

}

$spinner-color: #6436a1;

.spinner-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid $spinner-color;
    border-radius: 50%;
    animation: spinner-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $spinner-color transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &--centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--16 {
    div {
      width: 16px;
      height: 16px;
      border-width: 2px;
    }
  }

  &--32 {
    div {
      width: 32px;
      height: 32px;
      border-width: 4px;
    }
  }
}

@keyframes spinner-ring {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

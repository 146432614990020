@import "../../assets/css/jquery.fileupload-ui.css";
@import "~bootstrap/scss/_functions";
@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~font-awesome/scss/font-awesome.scss';
@import 'mixins.scss';
@import "../../ngx-src/assets/styles/shared/mixins";
@import 'general.scss';
@import 'navbar.scss';
@import 'dropdown.scss';
@import 'tooltip.scss';
@import 'type.scss';
@import 'forms.scss';
@import 'tables.scss';
@import 'utils.scss';
@import 'ngtable.scss';
@import 'item-view.scss';
@import 'biddetails.scss';
@import 'terms.scss';
@import 'filter.scss';
@import 'uicomponents.scss';
@import 'enterprise.scss';
@import 'pages.scss';
@import 'alerts.scss';
@import 'buttons.scss';
@import 'modals-overrides.scss';
@import 'drug-scenarios.scss';
@import 'quantuvis-plus.scss';
@import 'copy-bid.scss';
@import 'internal-bid.scss';
@import 'contract.scss';
@import 'product-enrollment.scss';
@import 'modal-windows.scss';
@import 'resize.scss';
@import 'expansion-panel.scss';

html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-size: 16px;
}

body {
  background-color: $white;
  display: flex;
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0;
  padding: 0;

  .top-space-small {
    margin-top: 30px;
  }
}

.qu-modal-open {
  overflow-y: hidden;
}

root {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
}

.full-height {
  flex: 1 1 auto;
  -webkit-flex-basis: 0%;
  overflow: auto;
}

.flex-column {
  display: flex;
  flex: 1 1 0%;
}

.qv-hidden {
  display: none !important;
}

secure-sign-out {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}

#qLogo {
  margin-top: -80px;
}

.quantuvisLogo {
  background: url("/assets/css/images/quantuvis_logo.png") no-repeat;
  display: block;
  height: 150px;
  width: 450px;
  background-size: auto 150px;
  cursor: pointer;
}

.login-link {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.modal-small .modal-dialog {
  width: 450px;
}

.inlineText {
  display: inline-block;
  vertical-align: baseline;
  margin-right: 20px;
}

a, a:hover, a:focus {
  cursor: pointer;
  &.nodecoration {
    text-decoration: none;
  }
}

.small-bottom-margin {
  margin-bottom: rem(5px);
}

.add-attachment-icon,
.download-attachment-icon,
.remove-attachment-icon {

  svg {
    margin-bottom: rem(5px);
  }
}

.add-website-link-icon,
.visit-website-link-icon,
.remove-website-link-icon {

  svg {
    margin-bottom: rem(10px);
  }
}

// ----------------------
//    Quantuvis Dialog
// ----------------------
.dialog {
  width: 370px;
  margin: auto;
  padding: 30px 30px 30px 30px;
  border: 1px solid $gray;
  box-sizing: content-box;

  p {
    margin-bottom: 20px;
  }
  .title {
    font-weight: 300;
    font-style: normal;
    font-size: 32px;
    color: $gray-dark;
    line-height: normal;
  }
  .info {
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    color: $gray-dark;
  }
  form {
    width: 100%;
    margin-bottom: 0;
    box-sizing: border-box;
    input {
      width: 370px;
      margin-bottom: 20px;
    }
    .actions {
      display: block;
      width: 370px;
      margin-bottom: 20px;
      &.center {
        text-align: center;
      }
      .fullWidth {
        width: 100%;
        margin: 0;
      }
    }
    .actions:last-child {
      margin-bottom: 0;
    }
  }
}

#wrapper {
  display: flex;
  flex-shrink: 1;
  flex-basis: auto;
  -webkit-flex-basis: 0%;
  position: relative;
  padding-bottom: 0;
  height: calc(100vh - #{$header-height} - 1px);
  overflow: auto;
  min-height: calc(100vh - #{$header-height} - 1px);

  &.no-header {
    min-height: 100vh;

    #content {
      padding: 0;
    }
  }

  &.padded {
    padding-bottom: 60px;
  }
}

#content {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}

.copyright-wrapper {
  display: inline-block;
  width: 100%;
  text-align: center;

  .copyright {
    font-size: 12px;
    color: $gray;
  }
}

th {
  .not-present {
    opacity: 0.3;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

}
//--------------------------
// Simple dropdown
//--------------------------
.simple-drop {
  position: absolute;
  min-width: 200px;
  background-color: $white;
  border: 1px solid $black;
  padding: 10px;
  z-index: $simple-drop-zindex;

  .scrollable {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
    padding: 3px;

    & > * {
      display: list-item;
    }
  }
}

.scrollables {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  margin-bottom: 5px;
  padding: 3px;

  &.small-height {
    max-height: 300px;
  }
}

//--------------------------
// Feedback and contact Quantuvis
//--------------------------
.user-input-modal {
  width: 700px;

  .info-text {
    font-size: 12px;
  }

  input {
    margin-bottom: 0;
  }

  textarea {
    overflow: auto;
  }

  .position-relative {
    position: relative;
  }

}

//--------------------------
// All purpose card display
//--------------------------
.cardDisplay {

  display: block;
  margin: 20px 0 0;

  .card {
    background-color: $backgroundGray;
    display: inline-block;
    margin-right: 80px;
    margin-bottom: 40px;
    padding: 10px;
    vertical-align: top;
    position: relative;
    max-width: 372px;
    width: 372px;
    max-height: 152px;
    height: 152px;
    overflow: hidden;
    border: 0;
    border-radius: 0;

    &.market-basket-card {
      height: 200px;
      max-height: 300px;

      .market-basket-container {
        height: 150px;
        max-height: 150px;
      }

      .market-basket-header {
        display: block;
        float: left;
        margin-bottom: 10px;
      }
    }

    .title {

      .inner-title {
        float: left;
        font-size: 18px;
        color: $gray-dark;
        margin-bottom: 10px;
        font-weight: bold;
        max-width: 280px;
        position: relative;

        @include truncate-text();

        &.market-basket-inner-title {
          margin-bottom: 2px;
        }
      }

      .small {
        font-size: 12px;
        color: $theme-color;
        font-weight: normal;
        margin-left: 5px;
        position: relative;
      }
    }

    .job-title {
      @include truncate-text();
    }

    .info-container {
      overflow: hidden;
      max-height: 80px;
      position: relative;
      width: 100%;

      .info {
        display: block;
        font-size: 16px;
        color: $gray-darker;
        line-height: 1.25rem;
      }

      .info-email {
        @include truncate-text();
      }

      .market-basket-info {
        margin-bottom: 10px;
      }
    }

    .more {
      background-color: transparent !important;
      color: $link-color !important;
      position: absolute;
      bottom: 0;
    }

    .field {
      display: block;

      &.market-basket-field {
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        white-space: nowrap;
      }
    }

    .action {
      display: none;
      position: absolute;
      right: 10px;
      top: 10px;
    }

    &:hover {
      background-color: $backgroundGray;
      outline: 1px solid $gray-darker;
      cursor: pointer;

      &.textCursor {
        cursor: text;
      }

      .action {
        display: block;
      }
    }

  }

}

//--------------------------
// Dashboard Bid Graph
//--------------------------
#bidStatusGraph {

  table {
    margin-bottom: 0;
  }

  .percentageHolder {
    margin: 0;
    padding: 0;
    position: relative;

    .percentageBarWrapper {
      display: inline-block;
      background-color: $gray-light;
      height: 15px;
      vertical-align: middle;
      margin-right: 4px;
      width: 90%;

      .percentageBar {
        display: inline-block;
        background-color: $teal;
        height: 15px;
        vertical-align: top;
      }
    }

    .percentageText {
      display: inline-block;
      position: absolute;
    }
  }

  td, th {
    font-weight: $bolded-font-weight;

    .divider {
      display: block;
      height: 2px;
      border-bottom: 1px solid $gray;
      vertical-align: middle;
    }

  }
}
.status-label {
  width: 155px;
}

th, .control-group {

  .fa-info-circle {
    visibility: hidden;
    vertical-align: middle;
    position: inherit;
    margin-left: 2px;
    margin-bottom: 4px;
    &.visible {
      visibility: visible;
    }
  }

  &:hover {
    .fa-info-circle {
      visibility: visible;
    }
  }
}

.planType {
  .dropdown-toggle {
    position: relative;

    .btn-text {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      width: 100px;
      padding-right: 10px;
    }
  }
}

//--------------------------
// Widgets
//--------------------------
.widget-frame {
  padding: 20px 0 12px;
  border: 1px solid $gray-light;
  @include box-shadow(0 0 1px rgba(0,0,0,.5));
  position: relative;

  .toggle {
    position: absolute;
    right: 20px;
    top: 30px;
  }
}

.infoMessage {
  color: $medium-grey;
  margin-top: 10px;
}

#createBidModal {
  overflow-y: auto;

  .picker, .drug-picker, .manufacturer-picker {
    overflow-y: auto;
  }
}

.create-internal-bid-component {
  @include stretched-flex-container();
}

#createBidModal, .copy-bid, .create-internal-bid {
  @include stretched-flex-container();
  height: 100%;

  .picker, .drug-picker, .manufacturer-picker {
    @include stretched-flex-container();
    overflow: auto;

    .picker-toolbar > .row {
      margin-bottom: 15px;
    }
  }

  .input-mini.days {
    margin-right: 5px !important;
  }

  .col-1 {
    position: relative;

    h3 {
      top: 50%;
      margin-top: -15px;
      position: absolute;
    }

    .fa-chevron-right {
      position: absolute;
      right: 5px;
      top: 40%;
    }

  }
  .column-2 label {
    width: 48%;
    min-width: 390px;
    float: left;
  }

  .contracted-business-cmp .navbar {
    margin-bottom: 0;
  }
}

.lock-icon-margin {
  margin-left: 2px;
  margin-bottom: 5px;
  margin-right: 2px;
}

.lock-icon-zindex {
  z-index: $lock-unlock-switch-zindex;
}

.infinite {
    bottom: 0;
    right: 0;
    left: 0;

    display: block;
    background-color: $white;

    position: absolute;
    text-align: center;

    border:1px solid $black;
    padding:10px 2px;
    z-index: $loading-zindex;

    @include box-shadow(0, 0, 3px);

    &--top {
      top: 114px;
      bottom: auto;
    }

  @media (min-width: 768px) and (max-width: 1300px) {
    &--top {
      top: 142px;
    }
  }
}

#contact-page {
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;

  h1 {
    margin-top: 2px;
    background-color: $theme-color-lite;
    color: $white;
  }

  h2 {
    color: $theme-color;
  }

  .separated {
    border-right: 1px dashed;
    min-height: 210px;
  }

  .content {
    margin-top: 10px;
    background-color: $white;
    color: $black;
  }
}

.search-info, .select-info {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    text-align:center;
    font-size: larger;
    color: $gray;
    padding-left: 0;

    &.line-height-25 {
      line-height: 25px;
    }

    &.icon-search-large {
      background: url("/assets/css/images/icon-search-large.png") no-repeat;
      width: 100px;
      height: 100px;
      display: inline-block;
    }
  }
}

.select-info {
  position: static;
  width: 100%;
  text-align: center;

  .image-text-wrapper {
    display: inline-block;
  }

  img {
    width: 100px;
    height: 100px;
  }
}

.minWidth170 {
  min-width: 170px;
}

.minWidth96 {
  min-width: 96%;
}

.drug-row {
  border-width: 0;
  margin: 4px 2px;
  display: block;


  &:hover {
    border-width: 1px 0;
    border-style: solid;
    border-color: $gray-light;
    cursor: pointer;
    box-shadow: 1px 1px 1px 1px $box-shadow-color;
    z-index: $drug-row-zindex;
  }

}

.tos {
    border: 60px solid #808080;
    color: $black;
    display: block;
    margin: 0 auto;
    padding: 30px;
    text-align: justify;

    .quantuvisLogo {
        background-size: 250px 100px;
        height: 110px;
        cursor: default;
    }

    .toc {
        display: block;
    }

    .title {
        font-size: 1.4em;
        font-weight: $strong-bolded-font-weight;
        margin-top: 35px;
        display: block;
        text-align: center;
        margin-bottom: 20px;
    }

    .subtitle {
        font-size: 1.2em;
        font-weight: $strong-bolded-font-weight;
        display: block;
        margin-top: 35px;
        margin-bottom: 10px;

        &.sub2 {
            font-size: 1em;
        }
    }

    p {
        margin-top: 10px;
    }

    a:hover {
        cursor: pointer;
    }

    ul {
        list-style-type: disc;

      li {
        margin-bottom: 7px;
      }
    }

  .font {
    &.bolded.italic {
      font-weight: $strong-bolded-font-weight;
      font-style: italic;
    }
  }

  .font {
      &.bolded {
        font-weight: $strong-bolded-font-weight;
      }
  }
  .font {
      &.bolded.underline {
        font-weight: $strong-bolded-font-weight;
        text-decoration: underline;
      }
  }
  .font {
    &.underline {
      text-decoration: underline;
    }
  }
}

.enterpriseFeatures .left-margin {
    margin-left: 30px;
}

.rightsEmbedded {
  position: relative;
  width: 100%;
  height: 200px;
  display: block;

  .rights {
    top: 0 !important;
    bottom: 0 !important;
    width: 100%;
    overflow-y: auto;
  }
}

.left10 {
  margin-left: -10px;
}

.rights {
  bottom: 50px;
  position: absolute;
  top: 100px;

  .wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 80%;
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 40px;

    &.fixed-height {
      height: 62%;
    }

    &.payerWrapper {
      width: 422px;
    }

    .namerow {
      position: sticky;
      left: 0;
      width: $cell-width;
      height: 80px;
      text-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      z-index: 0;

      .name {
        padding-top: 10px;
      }

      span {
        display: inline-block;
      }

      &:hover {
        cursor: pointer;
      }

      &.border-bottom {
        border-bottom: 1px solid lighten($gray-light, 10%);
      }
    }

    .right-row {
      display: flex;
      width: auto;

      &.selected {
        background-color: lighten($table-row-hover, 8%) !important;

        &:hover {
          background-color: lighten($table-row-hover, 8%) !important;
        }
      }

      &.border-bottom {
        border-bottom: 1px solid lighten($gray-light, 10%);
      }

      &.odd {
        background-color: $table-odd;

        .namerow {
          background-color: $table-odd;

          &.selected {
            background-color: lighten($table-row-hover, 8%) !important;

            &:hover {
              background-color: lighten($table-row-hover, 8%) !important;
            }
          }
        }
      }

      &.even {
        background-color: $table-even;

        .namerow {
          background-color: $table-even;

          &.selected {
            background-color: lighten($table-row-hover, 8%) !important;

            &:hover {
              background-color: lighten($table-row-hover, 8%) !important;
            }
          }
        }
      }

      &:hover {
        background-color: $table-row-hover !important;

        .namerow {
          background-color: $table-row-hover !important;
        }
      }
    }

    .whitespace {
      position: absolute;
      top: 0;
      left: 0;
      background-color: $slate-blue;
      width: $cell-width;
      height: 80px;
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      overflow: visible;

      &--nowrap {
        flex-wrap: nowrap;
      }
    }

    .cell {
      display: inline-block;
      min-width: $cell-width;
      overflow: hidden;
      padding: 0;
      margin: 0;
      border: 0;
      white-space: normal;
      text-align: center;
      vertical-align: middle;

      .width160 {
        margin-top: 20px;
      }

      .bottom-padding2 {
        padding-bottom: 2px;
      }

    }

    .top-padding30 {
      padding-top: 30px;
    }

    .minCell {
      display: inline-block;
      width: 120px;
      overflow: hidden;
      padding: 0;
      margin: 0;
      border: 0;
      white-space: normal;
      text-align: center;
      vertical-align: middle;

      .width120 {
        margin-top: 20px;
      }
    }

    .stheight {
      min-height: 80px;
      height: 80px;
    }

    .rheader {
      display: flex;
      position: sticky;
      top: 0;
      color: $white;
      height: 80px;
      min-height: 80px;
      font-size: 16px;
      font-weight: 500;
      overflow: visible;
      z-index: 1;

      .cell-wrapper {
        height: 80px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        background-color: $slate-blue;
        width: auto;

        .cell {
          margin-left: 5px;
          margin-right: 5px;
          min-width: 190px;
          width: 190px;
        }
      }

      .cell {
        background-color: $slate-blue;
      }

      &.fixed-width {
        width: 400px;
      }

      &.negative-margin-left {
        margin-left: -3px;
      }
    }
  }

  .changed {
    background-color: lighten($theme-color, 60%);
  }

  .option {
    display: inline-block;
    margin-right: 2px;
    padding: 2px;
    width: 40px;
    cursor: pointer;

    &.original {
      border: 1px solid $gray-dark;
    }

    &:hover {
      background-color: $white;
      @include box-shadow(1px, 1px);
    }

    &.selected {
      color: $white;
    }

    &.o0 {

      &.selected {
        background-color: $red;
      }

      &.disabled {
        color: $gray;

        &:hover {
          cursor: default;
          background-color: inherit;
          box-shadow: none;
        }
      }
    }

    &.o1.selected {
      background-color: $slate-blue;
    }

    &.o2.selected {
      background-color: $theme-color;
    }
  }

  .right {

    &.color {
      width: 40px;
      margin-right: 2px;
      padding: 0 2px;
      border: 1px solid $gray-dark;
      display: inline-block;

      &.blue {
        color: $white;
        background-color: $slate-blue;
      }

      &.purple {
        color: $white !important;
        background-color: $theme-color;
      }
    }
  }

}

.marker {
  padding: 2px;
  margin: 2px;
  font-size: 14px;
  font-weight: bold;
  line-height: $line-height-computed;
  text-align: center;
  display: inline-block;
  vertical-align: baseline;
  color: $white;

  &.scenario {
    background-color: $theme-color;
    color: $white;
  }

  &.dependency {
    background-color: $tab-hover;
    cursor: pointer;
    color: $white;

    &.revision {
      margin-left: 25px;
    }
  }
}

#reportMissingDrug, #reportFeedback {
  textarea {
    width: 100%;
    height: 100px;
  }
}

#mmapping {

  .wrapper {
    margin-top: 30px;
    border-width: 1px 0;
    border-style: solid;
    border-color: $gray-light;
    height: 350px;
    overflow: auto;
  }

  .highlightedClass {
    background-color: $group-section-checked;
    &:hover {
    }
  }

  #mappingInputs {
    width:99%;

    th {
      padding: 0 5px  0 5px;
    }
    td {
      padding: 0 5px  0 5px;
    };


  }
  .padLeft {
    position: relative;
    left: 8px !important;
  }
  .btn {
    position: relative;
    top: -4px;
  }
  .dataHeader {
    width:16%;
  };
  .smallHeader {
    width:10%;
  };
}

.toggleMyAccounts {
  margin-top: -3px;
  margin-right: 10px !important;
  .toggleGroup {
    display: block;
    float: left;
  }
  .switch {
    float: left;
    margin-right: 5px;
  }
}

/*
 * Used for sticky-container.directive.js
 */
.stuck-container {
  position: fixed!important;
  z-index: $sticky-container-zindex;
  &.sticky-bid-details-buttons, &.sticky-revision-comparison-buttons {
    margin-top: 4px;
    border: 1px solid $gray;
    padding: 5px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.2);
    ul {
      margin-bottom: 0;
    }
    .btn {
      margin-top: 0!important; //Need to overwrite selector with higher order.
    }
  }
  &.sticky-revision-comparison-left-container {
    margin-top: 4px;
    padding: 5px;
    border: 1px solid $gray;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

.filters-row {
  display: flex;
  flex-direction: row;
  & > *:not(:last-child) {
    margin-right: 5px;
  }
}
*[sc-bogus] {
  visibility: hidden;
}

.icon-archived {
  display: inline-flex;
  background: url('/assets/images/archived.svg') no-repeat 0 0;
  background-size: contain;
  width: 100%;
  height: 12px;
}

.icon-attention {
  display: inline-flex;
  background: url('/assets/images/attention.svg') no-repeat 0 0;
  background-size: contain;
  width: 16px;
  height: 16px;

  &.term-position {
    position: absolute;
    top: 8px;

    &--empty-view {
      left: 160px;
    }

    &--view {
      left: 220px;
    }

    &--edit {
      right: 10px;
    }
  }

  &.drug-position {
    position: relative;
    top: 2px;
  }
}

.table {
  .archived{
    &:hover {
      border: 1px solid $upgrade-enterprise-color;
      td {
        color: $upgrade-enterprise-color;
      }
    }
  }
}

.vertical-flex-container {
  display: flex;
  flex-direction: column;
}

.navigation-buttons {
  display: inline-block;
  padding-top: 10px;
  .btn {
    margin-left: 0;
  }
}

@media (max-width: 1600px) {
  .hidden-company-logo {
    display: none !important;
  }
}

@media (max-width: 1360px) {
  .hidden-username {
    display: none !important;
  }
}

@media (max-width: 1190px) {
  .hidden-brand {
    display: none !important;
  }
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

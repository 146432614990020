.navbar-light {
  .navbar-brand {
    padding: 10px 20px 10px 0;
    margin-right: 0;
    font-size: 20px;
    font-weight: 200;
  }
  .navbar-nav {
    .nav-item {
      margin-right: 2px;
      &.dropdown-menu {
        &.float-right {
          left: auto;
          right: 0;
          &::after {
            left: auto;
            right: 13px;
          }
        }
      }
    }
    .nav-link {
      font-size: 1rem;
      padding: 1px 10px;
      margin: 9px 2px;    //TODO: rewrite with rem.
      @include hover-focus {
        color: $navbar-light-hover-color;
        cursor:pointer;
      }
      &.tab {
        @include hover-focus {
          color: $navbar-light-hover-color;
          cursor:pointer;
          border-bottom: 2px solid;
        }
      }
    }

    .open > .nav-link,
    .active > .nav-link,
    .nav-link.open,
    .nav-link.active {
      color: $navbar-light-active-color;
      cursor:pointer;
      border-bottom: 2px solid;
    }
  }
  .ml-auto {
    .dropdown-menu {
      left: auto;
      right: 0;
      &::after {
        left: auto;
        right: 13px;
      }
    }
  }
}

@import "../shared/mixins";
@import "../themes/new-ui";

.internal-field {
  position: relative;
  margin-bottom: rem(40px);
  color: $medium-grey;

  &__header {
    display: flex;
    align-items: center;
  }

  &__label {

    &--required:after {
      content: "*";
    }
  }

  &__info {
    margin-left: rem(5px);
    color: $silver;

    &:hover {
      color: $tundora;
    }
  }

  &__control {

    &[readonly] {
      cursor: not-allowed;
    }

    &.ng-invalid {
      &.ng-touched,
      &.ng-dirty {
        background-color: $pink-lady;
      }
    }
  }
}

/**
 * There should be described main colors
 *
 * @description Colors named according to this service: http://chir.ag/projects/name-that-color/#E4EDFD
 */

//
// Internal variables (DEPRECATED)
// ---------------------------------------------------------------------
$black:                                 #000000;
$shark:                                 #212529;
$mine-shaft:                            #2E2E2E;
$cape-code:                             #4E4F4F;
$dusty-gray:                            #969696;
$dusty-gray-light:                      #9C9C9C;
$silver-chalice:                        #9E9E9E;
$silver-light:                          #C7C7C7;
$wild-sand:                             #F6F6F6;
$mercury:                               #E1E1E1;
$gallery:                               #EFEFEF;
$whisper:                               #EFEDF6;
$wild-sand-approx:                      #F5F4F4;
$wild-sand-solid:                       #F4F4F4;
$mako:                                  #404850;
$blue-bayoux:                           #50617A;
$blue-stone:                            #005861;
$elm:                                   #196870;
$blue-lagoon:                           #007985;
$blue-chill:                            #129EA5;
$astral:                                #2F96A1;
$jungle-green:                          #30B296;
$thunderbird-dark:                      #B71C1C;
$thunderbird:                           #CD1212;
$cinnabar:                              #E53A19;
$salmon:                                #F98871;
$golden-tainoi:                         #FFC85E;
$scorpion:                              #585858;
$oslo-gray:                             #8E9599;
$silver-chalice:                        #A7A7A7;
$heather:                               #B8C3D4;
$viking:                                #68C5DA;
$malibu:                                #7DA7FA;
$daisy-bush:                            #57219D;
$daisy-bush-light:                      #59288B;
$purple-heart:                          #6436a1;
$eminence:                              #582E88;
$eminence-light:                        #572D88;
$minsk:                                 #4E297D;
$meteorite:                             #4F2480;
$jakarta:                               #3F235F;
$melrose:                               #AC94FF;
$blue-bell:                             #A399C5;
$lavender-gray:                         #B9B0D6;

// https://zpl.io/VqEZ9X7
// zpl://screen?sid=5b602607e00651ca624581a2&pid=5a037724f4f57f59aca267b2
// ---------------- Color Shades - FINAL ---------------- //

$white:                                 #ffffff;
$purple:                                #4f2480;
$royal-purple:                          #623b94;
$studio:                                #7b4aba;
$amethyst:                              #956ec7;
$light-wisteria:                        #bda4dc;
$snuff:                                 #e4daf1;
$white-lilac:                           #f1ecf8;

$mine-shaft:                            #333333;
$tundora:                               #4e4e4e;
$medium-grey:                           #8a8a8a;
$silver:                                #b8b8b8;
$alto:                                  #dcdcdc;
$isabelline:                            #ececec;
$white-smoke:                           #f6f6f6;

$ocean_teal:                            #005760;
$genoa:                                 #136f78;
$eastern-blue:                          #188b97;
$hippie-blue:                           #46a2ab;
$half-baked:                            #8bc5cb;
$ziggurat:                              #b9dcdf;
$catskill-white:                        #e3f1f3;

$slate-blue:                            #51617c;
$slate-gray:                            #738096;
$gull-gray:                             #97a0b0;
$cadet-blue:                            #aab4c6;
$pigeon-post:                           #bdc8dd;
$botticelli:                            #cdd5e3;
$hawkes-blue:                           #e4edfd;

//  Highlight Colors

$scarlet:                               #e22500;
$pink-lady:                             #f9d3cc;

$orange-peel:                           #ffa000;
$sunglow:                               #ffbe32;
$light-yellow:                          #fff2d6;

$lochmara:                              #007dd5;
$azure-radiance:                        #0088e5;

// ---------------- Color Shades - FINAL ---------------- //

//
// Colors
// ---------------------------------------------------------------------
$background-color-black:                $shark;
$background-color-dark:                 $cape-code;
$background-color-darker:               $dusty-gray;
$background-color:                      $silver;
$background-color-lighter:              $alto;
$background-color-light:                $wild-sand;
$background-color-default:              $white;

$main-theme-color-dark:                 $minsk;
$main-theme-color-darker:               $royal-purple;
$main-theme-color:                      $studio;
$main-theme-color-lighter:              $blue-bell;
$main-theme-color-light:                $whisper;

$control-color-dark:                    $blue-stone;
$control-color-middle-dark:             $elm;
$control-color-light:                   $eastern-blue;
$control-color-lighter:                 $astral;
$control-color:                         $half-baked;
$control-color-warn:                    $thunderbird-dark;
$control-color-warn-lighter:            $cinnabar;
$control-color-disabled:                $gallery;

$alert-background:                      $blue-bayoux;
$alert-background-lighter:              $hawkes-blue;

$error-color:                           $scarlet;

$rebate-hightlight-color:               $salmon;
$contract-terms-color:                  $viking;
$utilization-management-color:          $malibu;
$market-share:                          $melrose;
$price-protection:                      $golden-tainoi;

$input-border-colour-idle:              $alto;
$input-border-colour-required:          $thunderbird;
$input-border-colour-hover:             $silver-chalice;
$input-border-colour-active:            $daisy-bush;
$input-border-colour-disabled:          $wild-sand;

$datepicker-color:                      $oslo-gray;
$datepicker-color-dark:                 $mako;

$label-background-color:                $heather;
$notification-bell__bg-color:           $silver-light;

$horizontal-menu__color--idle:          $silver-chalice;
$horizontal-menu__color--active:        $royal-purple;
$horizontal-menu__border-color--active: $eminence;

$small-button-color--active:            $snuff;
$tag__background-color--active:         $botticelli;

$upgrade-enterprise-color:              $jungle-green;

$loi-tabs-link-color:                   $daisy-bush-light;
$tab-label-color:                       $silver;
$tab-label-color--disable:              $alto;
$tab-label-background-color:            $white-smoke;
$tab-label-color--active:               $royal-purple;
$tab-label-background-color--active:    $white;
$tab-label-background-color--hover:     $gallery;

$base-table-title-color:        $daisy-bush-light;
$base-table-border-color:       $alto;
$base-table-button-color:       $dusty-gray-light;
$base-table-search-input-color: $eastern-blue;
$base-table-search-input-bg:    $wild-sand-solid;
$base-table-header-cell-color:  $shark;
$base-table-header-cell-bg:     $white;
$base-table-row-even-bg:        $wild-sand-approx;
$base-table-row-odd-bg:         $gallery;
$base-table-row-hover-bg:       $white-lilac;
//
// Fonts
// ---------------------------------------------------------------------
$font-family-default:                   "Roboto", sans-serif;
$font-family-bold:                      "Roboto-Bold", sans-serif;
$font-family-default-condensed:         "Roboto Condensed", sans-serif;
$font-family-signature:                 'Allura', cursive, "Times New Roman", Times, serif;
// This is the default setting for "sass-ems" mixin
$base-font-size: 16px;
// This is the default setting for "sass-rem" mixin
$rem-baseline: $base-font-size;

//
// Resolutions
// ---------------------------------------------------------------------
$tablet:                                1024px;
$laptop-sm:                             1280px;
$laptop:                                1366px;
$desktop-md:                            1440px;
$desktop-md-plus:                       1640px;
$desktop-lg:                            1920px;

$default-animation-duration:            750ms;
$header-animation-duration:             $default-animation-duration;

//
// z-index
//--------------------------
$zindex-grid-wrapper-fixed:             1;
$zindex-pane:                          10;
$zindex-grid-row:                      10;
$zindex-grid-substrate:                50;
$zindex-grid-row-placeholder:          50;
$zindex-grid-header:                   100;
$zindex-site-header:                   900;
$zindex-page-toolbar:                  1000;
$zindex-ng-select-icon:                1002;

//
// Sizes
//--------------------------
$header-height: 56px;
$header-border-width: 1px;
$content-margin-top: 32px;
$header-padding: 8px;
$toolbar-height: 52px;
$grid-header-height: 118px;

//
// Standard view
//--------------------------
$standard-ndc-margin: rem(8px);
$standard-scenario-section-name-width: rem(150px);
$standard-ndc-section-name-width: $standard-scenario-section-name-width - $standard-ndc-margin;
$box-shadow-default: 0 rem(1px) rem(3px) 0 rgba(0, 0, 0, 0.16);

// System menu
//--------------------------
$system-menu-width: 56px;
$fixed-content-width: calc(100% - #{$system-menu-width});

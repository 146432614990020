.mat-tooltip.tooltip-multiline {
  white-space: pre-line;
}

.mat-tooltip.tooltip-no-truncate {
  max-width: inherit;
  text-overflow: inherit;
}

.mat-tooltip.tooltip-primary {
  font-family: Roboto, 'sans-serif';
  font-size: rem(14px);
  letter-spacing: rem(0.1px);
  border-radius: 2px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 3px 4px 0 rgba(0, 0, 0, 0.14);
  background-color: $gallery;
  font-weight: 500;
  line-height: rem(16px);
  text-align: center;
  color: $tundora;
  padding: 8px;
  margin-left: 8px;
}

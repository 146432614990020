@import "../../styles/shared/mixins";
@import "../../styles/themes/new-ui";

.qv-paginator.mat-paginator {
  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-infix {
    border: 0;
  }

  .mat-form-field-infix {
    padding: 0;
  }

  .mat-form-field-flex {
    border: solid rem(1px) $alto;
    border-radius: rem(4px);
    padding: rem(6px 7px 6px 10px);

    &:focus, &:active, &:focus:hover, &:active:hover {
      border: solid rem(1px) $royal-purple;
    }

    &:hover {
      border: solid rem(1px) $tundora;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-paginator-page-size {
    align-items: center;
  }

  .mat-paginator-page-size-select {
    margin: 0;
    margin-left: rem(8px);
    width: rem(62px);
  }

  .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: $royal-purple;
  }

  .mat-select-value {
    padding-top: rem(1px);
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $royal-purple;
}

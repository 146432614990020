@import "../themes/new-ui";
@import "../shared/mixins";

$checkbox-size: rem(16px);

// @deprecated Don't use direct tag styling. Use 'mat-checkbox.qv-mat-checkbox' with modifiers
mat-checkbox {
  &.mat-checkbox-checked,
  &.mat-checkbox-indeterminate {
    &.mat-accent {
      .mat-checkbox-background {
        background-color: $meteorite;
      }
    }
  }

  .mat-checkbox-inner-container {
    width: rem($checkbox-size);
    height: rem($checkbox-size);
    background-color: $white;
  }

  .mat-checkbox-ripple {
    position: absolute;
    left: calc(50% - #{$checkbox-size});
    top: calc(50% - #{$checkbox-size});
    height: $checkbox-size * 2;
    width: $checkbox-size * 2;
    border-radius: 50%;

    .mat-ripple-element {
      background-color: rgba($tundora, .16) !important;
    }
  }

  &.mat-checkbox-disabled {
    cursor: not-allowed;
  }
}

mat-checkbox.qv-mat-checkbox {
  font-family: $font-family-default;
  @include fontSize(15px);
  color: $tundora;

  &--primary {
    &.mat-checkbox-checked,
    &.mat-checkbox-indeterminate {
      &.mat-accent {
        .mat-checkbox-background {
          background-color: $meteorite;
        }
      }
    }
  }

  &--sm {
    .mat-checkbox {
      &-layout {
        height: 32px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      &-label {
        @include fontSize(14px);
      }

      &-inner-container {
        margin: 0 8px 0 0;
      }
    }
  }

  .mat-checkbox-inner-container {
    width: rem(16px);
    height: rem(16px);
    margin: auto 8px;
  }

  &.mat-checkbox-checked, &.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: $eastern-blue;
    }

    .mat-checkbox-frame {
      border-color: $medium-grey;
    }
  }

  &.mat-checkbox-disabled {
    .mat-checkbox-label {
      color: $silver;
    }

    .mat-checkbox-frame {
      border-color: $silver;
    }
  }

  &.mat-checkbox-checked.mat-checkbox-disabled {
    .mat-checkbox-background {
      background-color: $silver;
    }
  }

  &--clear {
    .mat-checkbox-layout {
      margin-bottom: 0;
    }
  }

  &--no-margin-left {
    .mat-checkbox-inner-container {
      margin-left: 0;
    }
  }

  &--tree-parent, &--tree-child {
    .mat-checkbox-label {
      display: inline-flex;
      align-items: center;
    }

    .mat-checkbox-frame {
      border-color: $medium-grey;
    }
  }

  &--tree-parent {
    .mat-checkbox-inner-container {
      margin-right: rem(4px);
    }
  }

  &--label-width-248-truncated {
    padding-left: rem(24px);

    .mat-checkbox-label {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      width: rem(248px);
      text-overflow: ellipsis;
    }
  }
}

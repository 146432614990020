/**
 * Mixins
 */
@import '~sass-ems/index.scss';
@import '~sass-rem/_rem.scss';
@import "../themes/new-ui";

@mixin button-inverse-variant($color, $background, $border) {
  $active-background: lighten($background, 10%);
  $active-border: $border;

  color: $color;
  background-color: $background;
  border-color: $border;

  // Hover and focus styles are shared
  @include hover {
    color: $color;
    background-color: $active-background;
    border-color: $active-border;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: $background;
    border-color: $border;
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $color;
    background: $active-background none;
    border-color: $active-border;
    box-shadow: none;
  }
}

@mixin rotate($deg) {
  transform: rotate($deg);
}

@mixin truncate-text() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin stretched-flex-container() {
  flex: 1 1 auto;
  display: flex;
  -webkit-flex-basis: 0%;
  flex-direction: column;
}

/**
 * Mixin provide rem value or px in fallback
 */
@mixin fontSize($size) {
  font-size: rem($size);
}

@mixin breakpoint($point) {
  @media (min-width: $point) {
    @content;
  }
}

@mixin breakpoint-range($from, $to) {
  @media (min-width: $from) and (max-width: $to) {
    @content;
  }
}

@mixin breakpoint-up($point) {
  @media (max-width: $point) {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin flexFullHeight {
  display: flex;
  flex: 1 1 auto;
  -webkit-flex-basis: 0%;
}

@mixin selectedState($color) {
  &--selected {
    color: $color;
  }
}

@mixin emptyState($color: $silver) {
  &--empty {
    svg {
      path {
        fill: $color;
      }
    }
  }
}

@mixin fullState($color: $royal-purple) {
  &--full {
    svg {
      path {
        fill: $color;
      }
    }
  }
}

@mixin boxShadow($value) {
  -moz-box-shadow: $value;
  -webkit-box-shadow: $value;
  box-shadow: $value;
}

@mixin colorizeIcon($color) {
  svg {
    path {
      fill: $color;
    }
  }
}

@mixin colorizeIconWithBackground($contentColor, $backgroundColor) {
  svg {
    .content {
      fill: $contentColor;
    }
    .background {
      fill: $backgroundColor;
    }
  }
}

@mixin grayscale($percent) {
  -moz-filter: grayscale($percent);
  -webkit-filter: grayscale($percent);
  filter: grayscale($percent);
}

@mixin supportsPositionSticky() {
  @supports ((position: sticky) or (position: -webkit-sticky)) and (not (-ms-ime-align:auto)) { // -webkit-sticky for Safari; -ms-ime-align:auto special condition for EDGE 12+
    @content;
  }
}

@mixin hyphens($value) {
  -moz-hyphens: $value;
  -webkit-hyphens: $value;
  -ms-hyphens: $value;
  hyphens: $value;
}

@mixin prefix($property, $value) {
  $prefixes: (webkit moz ms);
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }

  // Output standard non-prefixed declaration
  #{$property}: $value;
}

@mixin resizeIcon($size) {
  display: inline-flex;
  height: rem($size);
  line-height: rem($size);
  width: rem($size);
}

@mixin absoluteFormFieldErrors() {
  position: absolute;
  bottom: rem(-24px);
  left: 0;
}

@import "../../../assets/styles/shared/mixins";
@import "../../../assets/styles/themes/new-ui";

.mat-slide-toggle:not(.mat-disabled) {
  .mat-slide-toggle-thumb {
    cursor: pointer;
  }
}

.mat-slide-toggle {
  &.mat-checked {
    &:not(.mat-disabled) {
      .mat-slide-toggle-bar {
        background-color: $light-wisteria;
      }
      .mat-slide-toggle-thumb  {
        background-color: $meteorite;
      }
    }
    :hover {
      .mat-ripple-element {
        background-color: $meteorite;
        opacity: 0.12;
      }
    }
  }
}
.itemView {
    * {
        box-sizing: border-box;
    }
    .itemViewList {
        margin: 0;
        padding: 0;
        display: flex;
    }
    .item {
        margin: 5px 1% 10px 0;
        padding: 5px 10px;
        position: relative;
        background-color: $backgroundGray;
        border: 1px solid $backgroundGray;
        .actions {
            display: none;
            position: absolute;
            bottom: 5px;
            right: 5px;
        }
        &:hover,
        &:active{
            cursor: pointer;
            background-color: darken($backgroundGray, 5%);
            box-shadow: 0 3px 3px $box-shadow-color;
            z-index: $item-zindex;
            .actions {
                display: block;
            }
        }
        .sharedItem {
            margin-left: 5px;
        }
    }
    h3 {
        font-weight: bold;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .subtitle {
        color: $gray-dark;
        font-weight: normal;
        margin-bottom: 0;
    }
    &.listView {
        .itemViewList {
            flex-direction: column;
            flex-wrap: wrap;
            align-items: stretch;
        }
        .item {
            margin-right: 0;
        }
    }
    &.gridView {
        .itemViewList {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: stretch;
        }
        .item {
            width: 49.5%;
            min-height: 70px;
            &:nth-child(2n+2) {
                margin-right: 0;
            }
        }

    }

    // Ellipsis implementation : https://codepen.io/martinwolf/pen/qlFdp
    $desc-font-size: 14px;
    $desc-line-height: 1.4;
    $desc-lines-to-show-grid: 2;
    $desc-lines-to-show-list: 1;
    .item-description {
        margin: 5px 0 0 0;
        display: block; /* Fallback for non-webkit */
        display: -webkit-box !important;
        font-size: $desc-font-size;
        line-height: $desc-line-height;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &.listView:not(.no-ellipsis) .item-description {
        max-width: 100%;
        -webkit-line-clamp: $desc-lines-to-show-list;
        height: $desc-font-size * $desc-line-height * $desc-lines-to-show-list; /* Fallback for non-webkit */
    }
    &.gridView:not(.no-ellipsis) .item-description {

        max-width: 100%;
        -webkit-line-clamp: $desc-lines-to-show-grid;
        height: $desc-font-size * $desc-line-height * $desc-lines-to-show-grid; /* Fallback for non-webkit */
    }
}


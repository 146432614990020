.alert {
  padding: 8px 0;
  margin-top: 5px;
  text-shadow: none;
}

// Adjust close link position
.alert .close {
  font-weight: 400;
}

.alert-danger,
.alert-error {
  background-color: transparent;
  border-color: transparent;
  color: #992626;
}

.alert-block {
  padding: 8px 15px;
  &.alert-danger {
    background-color: $alert-danger-border;
    border-color: $alert-danger-bg;
  }
  &.alert-warning {
    background-color: $alert-warning-bg;
    border: 1px solid $alert-warning-border;
  }
  &.alert-info {
    background-color: $alert-info-bg;
    border-color: $alert-info-border;
  }
}

.alert-inline {
  margin-top: 0;
  margin-bottom: 0;
}

.alert-animated.ng-enter, .alert-animated.ng-leave {
  -webkit-transition:all 0.5s;
  transition:all 0.5s;
}

.alert-animated.ng-enter,
.alert-animated.ng-leave.ng-leave-active {
  opacity:0;
}

.alert-animated.ng-leave,
.alert-animated.ng-enter.ng-enter-active {
  opacity:1;
}

.alert-fixed-top {
  position: fixed;
  top: $header-height;
  margin: 15px;
  left: -5px;
  z-index: $alert-fixed-top-zindex;
}

.alert-close-button {
  border: none;
  background: none;
  color: inherit;
  padding: 0;
  float: right;
  margin-right: 5px;
  font-size: 1.5em;
  &:after {
    content: "\00D7";
  }
}

.from-archived-rfp-message {
  font-size: 18px;
  line-height: 27px;
  background-color: $alert-danger-border;
  border-color: $alert-danger-bg;
}

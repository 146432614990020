@import "mixins";

.btn {
  display: inline-block;
  padding: 0.25rem 0.4rem;
  margin-bottom: 0;
  margin-left: 15px;
  font-size: $base-font-size;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: $btn-secondary-bg;
  color: $btn-secondary-color;
  border: 2px solid $btn-secondary-border;
  border-radius: 4px;
  min-width: 100px;

  &.disabled,
  &:disabled,
  &[disabled] {
    cursor: $cursor-disabled;
    opacity: .45;
    @include box-shadow(none);
  }


  &.btn-primary {
    @include button-inverse-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
  }

  &.btn-secondary {
    @include button-inverse-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
  }

  &.btn-cancel {
    @include button-inverse-variant($purple, $white, $purple);
    &:hover:not(:disabled) {
      background-color: lighten($btn-primary-bg, 10%);
      color: $btn-primary-color;
      border-color: lighten($btn-primary-bg, 10%);
    }
  }

  &.btn-cancel[disabled] {
    color: $purple;
  }

  &.btn-delete {
    @include button-inverse-variant($white, $btn-delete-background, $btn-delete-background);

    &:hover {
      background-color: $btn-delete-background-highlight;
    }
  }

  &.btn.dropdown-toggle:not(:first-of-type) {
    margin-left: -1px;
  }
}

.mce-btn.mce-primary {
  .mce-txt {
    color: $btn-primary-color;
  }
}

// Button Sizes
// --------------------------------------------------
.btn-narrow {
  min-width: 20px;
}

.btn-nullmargin {
  margin-left: 0;
}

.btn-link {
  border: 0;
}

.actionButtonContainer {
  padding: 0;
  .btn,
  .actionButtonLabel{
    margin: 0 15px 0 0;
  }
}


.badge {
  display: inline-block;
  min-width: 80px;
  padding: 10px 5px;
  color: $white;
  font-weight: 600;
  text-align: center;
}

.show {
  .btn.dropdown-toggle:not(:first-of-type) {
    margin-left: -1px;
    border-bottom-left-radius: $border-radius-base;
    border-top-left-radius: $border-radius-base;
  }
}

.dropdown-menu li {
  .text, .icon {
    vertical-align: middle;
  }
}

.btn-std {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid;
  padding: .25rem .5rem;
  font-size: .85rem;

  &:focus {
    outline: 0;
  }

  &.disabled {
    cursor: default;
    background-image: none;
    opacity: 0.35;
    box-shadow: none;
  }

  &--not-backgroung {
    background-image: none;
  }
}

@import "../../styles/shared/mixins";
@import "../../styles/themes/new-ui";

.popover {
  &--only-scaffold {
    max-width: initial;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background: none;

    .popover-body {
      padding: 0;
    }
  }

  &--width-520 {
    width: rem(520px);
  }

  &--width-600 {
    width: rem(600px);
  }

  &--material {
    .arrow {
      z-index: 5000;
      width: 2rem;
      height: 1rem;

      &:before {
        border: 0;
      }
    }
  }
}

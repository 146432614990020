$checkbox-primary-background-color: #4f2480;
$checkbox-primary-ripple-background-color: #4e4e4e;
$checkbox-primary-label-color: #4e4e4e;
$checkbox-primary-frame-color: #ffffff;
$checkbox-disabled-color: #b8b8b8;
$eastern-blue: #188b97;

.acc-checkbox {
  &--primary {
    &.mat-checkbox {
      &.mat-checkbox-checked {
        &.mat-accent .mat-checkbox-background {
          background-color: $checkbox-primary-background-color;
        }

        &.mat-checkbox-disabled {
          &.mat-accent .mat-checkbox-background {
            background-color: $checkbox-disabled-color;
          }

          .mat-checkbox-label {
            color: $checkbox-disabled-color;
          }

          .mat-checkbox-frame {
            border-color: $checkbox-disabled-color;
          }
        }
      }

      .mat-checkbox-label {
        display: inline-block;
        font-size: 14px;
        line-height: 16px;
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.25px;
        color: $checkbox-primary-label-color;
      }

      .mat-checkbox-inner-container {
        width: 18px;
        height: 18px;
      }

      .mat-checkbox-frame {
        background-color: $checkbox-primary-frame-color;
      }
    }
  }

  &--label-width-232 {
    &.mat-checkbox {
      .mat-checkbox-label {
        width: 232px;
      }
    }
  }

  &--label-width-256 {
    &.mat-checkbox {
      .mat-checkbox-label {
        width: 256px;
      }
    }
  }

  &--label-truncated {
    &.mat-checkbox {
      .mat-checkbox-label {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &--secondary {
    &.mat-checkbox {
      &.mat-checkbox-checked {
        &.mat-accent .mat-checkbox-background {
          background-color: $eastern-blue;
        }

        &.mat-checkbox-disabled {
          &.mat-accent .mat-checkbox-background {
            background-color: $checkbox-disabled-color;
          }

          .mat-checkbox-label {
            color: $checkbox-disabled-color;
          }

          .mat-checkbox-frame {
            border-color: $checkbox-disabled-color;
          }
        }
      }
    }
  }
}

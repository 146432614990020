@import "../shared/mixins";
@import "../themes/new-ui";

.wizard-section {
  font-family: $font-family-default;
  margin: 0 0 rem(64px);

  &__title,
  &__info {
    margin: 0 0 rem(18px);
  }

  &__title {
    font-size: rem(24px);
    font-weight: 400;
    color: $mine-shaft;
  }

  &__info {
    font-size: rem(14px);
    color: $medium-grey;
  }

  &__field {

    .term__field,
    .ng-select {

      &.ng-invalid {

        &.ng-touched,
        &.ng-dirty {
          background-color: $pink-lady;

          .ng-select-container {
            background-color: $pink-lady;
          }
        }
      }
    }
  }
}

.termview {
  //overflow: hidden;
  margin-bottom: 2px;
  padding: 2px;
  color: $black;

  &:hover {
    .fa-info-circle, .info {
      visibility: visible;
    }
  }

  .fa-info-circle {
    visibility: hidden;
    vertical-align: middle;
    position: inherit;
    margin-left: 2px;
    margin-bottom: 4px;
  }

  .alert {
    text-align: center;

    &--left {
      text-align: left;
    }

    &--absolute {
      position: absolute;
      text-align: left;
      width: 300px;
      left: 0;
      top: 50px;
    }
  }

  .btn {
    padding: 1px 6px;
  }

  td {
    color: $black;
  }

}

.term-content {
  vertical-align: middle;
  display: inline-block;
  min-width: 70px !important;

  &.error {
    background-color: $errorback;
    border: 2px solid $red;
  }

  &.minBaseRebateError {
    padding-left: 20px;
    background: url("/assets/css/images/down-arrow.png") no-repeat 2px 6px;

    input.input-mini {
      background-color: $errorback;
      border-style: none !important;
      width: 50px !important;
      min-width: 50px !important;
      max-width: 50px !important;
    }

    .appended-suffix {
      background-color: $errorback !important;
      border-style: none !important;
    }
  }

  &.invalidTerm {
    input, select, .input-group-addon, .appended-suffix{
      border-color: $red !important;
      background-color: #f5c8c4 !important;
      outline: none;
    }
  }
}

.dismissed .term-content.invalidTerm {
  input, select, .input-group-addon, .appended-suffix{
    border-color: #e6e6e6 !important;
    background-color: #ffffff !important;
    outline: none;
  }
}

.summary {
  .term-content {
    //overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 260px;
  }
}
.input-group-addon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.125rem 0.125rem;
  min-width: 25px;
  height: 1.75rem;
}
#createBidModal {
  .summary {
    .term-content {
      width: inherit;
      .dateinput {
        margin-bottom: 0;
      }
      .input-group-addon {
        border: 1px solid $slate-blue;
        min-height: 28px;
      }
    }
  }
}

.term-label {
  display: inline-block;
  width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
  margin-top: 5px;

  .cust-sprite {
    margin-bottom: 5px;
  }

}

.visible-term {
  .minBaseRebateError {
     background-position: 2px 12px;
  }
}

.dropdown-popup-anchor {
  position: absolute;
}

.dropdown-popup.term-value-popup {
  background-color: white;
  width: 600px;
  min-height: 160px;
  max-height: 200px;
  padding: 0 0 10px 0;
  z-index: $term-value-popup-zindex;

  .section-header {
    background-color: $theme-color;
    border-radius: $border-radius-base $border-radius-base 0 0;
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    padding: 5px 0 5px 10px;
  }

  .section-value {
    background-color: white;
    min-height: 100px;
    max-height: 140px;
    overflow-y: auto;
    padding: 15px;
  }

  &:after {
    right: auto !important;
    left: 10px !important;
  }

  @media (max-width: 1800px) {
    left: -350px;

    &:after {
      left: 360px !important;
    }
  }
}

// Ellipsis inmplementation : https://codepen.io/martinwolf/pen/qlFdp

$el-font-size: 14px;
$el-line-height: 1.4;
$el-lines-to-show: 4;

.um-details-section-view {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box !important;
  max-width: 246px;
  height: $el-font-size * $el-line-height * $el-lines-to-show; /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: $el-font-size;
  line-height: $el-line-height;
  -webkit-line-clamp: $el-lines-to-show;
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  word-wrap: break-word;

  .drugEditMode & {
    white-space: normal;
  }
}

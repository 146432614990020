$button-disabled-color: #d4d4d4;
$button-hover-color: #632d91;
$violet: #8537a4;
$meteorite: #4f2480;
$white: #ffffff;
$alto: #dcdcdc;
$gallery: #ececec;
$medium-grey: #8a8a8a;

.vertical-icon-button,
.horizontal-icon-button {
  &.mat-button {
    min-width: auto;
    padding: 4px;
    margin: 0 4px;
    font-family: Roboto, "sans-serif";
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #4e4e4e;
    letter-spacing: 0.31px;

    .mat-button-wrapper {
      display: flex;
      align-items: center;
    }

    .vertical-icon-button__icon,
    .vertical-icon-button__icon-svg,
    .vertical-icon-button__icon-svg svg {
      width: 18px;
      height: 18px;
      font-size: 16px;
      color: $medium-grey;
      fill: $medium-grey;

      &--font-size-18 {
        font-size: 18px;
      }
    }

    &:hover:not(:disabled) {
      color: $button-hover-color;
      fill: $button-hover-color;

      .vertical-icon-button__icon,
      .vertical-icon-button__icon-svg,
      .vertical-icon-button__icon-svg svg  {
        color: $button-hover-color;
        fill: $button-hover-color;
      }
    }

    &:disabled {
      background: transparent;
      color: $button-disabled-color;
      fill: $button-disabled-color;

      .vertical-icon-button__icon,
      .vertical-icon-button__icon-svg,
      .vertical-icon-button__icon-svg svg  {
        color: $button-disabled-color;
        fill: $button-disabled-color;
      }
    }
  }
}

.vertical-icon-button {
  &.mat-button {
    .mat-button-wrapper {
      flex-direction: column;
    }
  }
}

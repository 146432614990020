@import "../themes/new-ui";
@import "../shared/mixins";

$grid-scenario-placeholder-value-bg: $silver;
$grid-scenario-placeholder-border-radius: rem(2px);

:host {
  display: flex;
  flex-direction: column;
}

.grid-scenario-placeholder {

  &__value {
    width: 60%;
    height: rem(8px);
    background-color: $grid-scenario-placeholder-value-bg;
  }

  .grid__cell {
    align-items: center;
    flex-direction: row;
  }

  .drug {
    width: rem(500px);
    height: rem(20px);
    background-color: $grid-scenario-placeholder-value-bg;
  }

  .drug-info {
    display: flex;
    flex-direction: column;
    padding: rem(6px) rem(16px) rem(16px);
  }

  .scenario-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    padding: rem(8px) rem(10px) rem(8px) rem(12px);

    &--container {
      flex: 1;
      display: flex;
    }

    &__name,
    &__toggler {
      background-color: $grid-scenario-placeholder-value-bg;
      border-radius: $grid-scenario-placeholder-border-radius;
    }

    &__name {
      width: rem(32px);
      height: rem(28px);
      margin-right: rem(8px);
    }

    &__toggler {
      width: rem(14px);
      height: rem(14px);
    }
  }
}

@import "../shared/mixins";
@import "../themes/new-ui";

.qv-modal-dialog.market-basket-modal {

  &.market-basket-modal {
    .list-panel__content {
      max-height: rem(424px);
      min-height: rem(200px);

      .no-data {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        margin: auto;
        width: 100%;

        color: $silver;
        line-height: rem(20px);
        text-align: center;
      }
    }

    &--preview {
      height: 80vh;
      max-height: 600px;
      display: flex;
      flex-direction: column;

      .list-panel__content {
        height: calc(100vh - 428px);
      }

      .qv-modal-dialog-content {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
      }
    }

    &--edit {
      .list-panel__content {
        height: calc(100vh - 480px);
      }
    }

    &--assign {
      .list-panel {
        height: 100%;
      }
    }
  }

  .qv-modal-dialog-actions {
    padding: rem(28px) rem(32px);
  }

  .qv-modal-dialog-content {
    margin-top: rem(16px);
    padding: 0 rem(32px);
  }

  .panels-row {
    display: flex;
    flex-direction: row;
    line-height: 1;

    .left-column {
      flex-basis: rem(352px);
      max-width: rem(352px);
      margin-right: rem(32px);

      .mat-checkbox-layout {
        white-space: normal;
      }
    }

    .right-column {
      flex: 1;
    }
  }

  .list-panel {
    width: 100%;
    border: rem(1px) solid $isabelline;

    &__header {
      font-weight: 500;
      color: $tundora;
      background-color: $white-smoke;
      padding: rem(12px) rem(16px);

      &--relative {
        position: relative;
      }
    }

    &__content {
      overflow-x: hidden;
      overflow-y: auto;
      border-top: rem(1px) solid $isabelline;
      padding: rem(16px);

      &--empty {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .info-message {
    margin: rem(16px) 0;
    font-size: rem(12px);
    color: $eastern-blue;
  }

  .qv-mat-checkbox {
    display: block;

    &--add-to-other {
      padding-left: rem(16px);

      .mat-checkbox-label {
        font-size: rem(14px);
      }
    }
  }
}


@import "../../styles/themes/new-ui";
@import "../../styles/shared/mixins.scss";
@import "../../styles/shared/functions.scss";

.qv-page-toolbar {
  position: fixed;
  top: $header-height + $header-border-width;
  right: 0;
  width: $fixed-content-width;
  height: 52px;
  z-index: $zindex-page-toolbar;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(7px) rem(15px);

  background-color: $white;
  transition: headerTopTransition($header-animation-duration);

  & > * {
    display: inline-flex;
    align-items: center;
  }
}

.copy-title {
  text-transform: uppercase;
}

.keep-origin-val {
  background-color: $gray-light !important;

  &::placeholder {
    color: $black !important;
  }

  option {
    background-color: $white !important;
  }
}

.website-link.form-control {
  width: 125px;
  margin-bottom: 5px;
}

ul.rfp-header .item-value.preserve-locks {
  padding-top: 10px;
  padding-bottom: 0;
}

.copy-bid-component {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}

.copy-bid {
  .summary-right-panel {
    margin-bottom: 5px;
  }

  .qv-icon-info {
    display: block;
    float: left;
    width: 25px;
    height: 25px;
  }

  .info-wrapper {
    padding: 0 15px;

    .info {
      margin-left: 50px;
      margin-bottom: 0;
    }
  }

  .step-info {
    min-height: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 0;

    .graysection {
      min-height: 0 !important;
      display: block;
    }
  }

  .origin-pharma-rights-wrapper {
    margin-bottom: 15px;
  }

  .copy-wrapper {
    display: flex;
    flex-wrap: wrap;

    .wrapper {
      margin-right: rem(40px);
    }

    .remove-dismissed-scenarios {

      &__warning {
        display: flex;
      }

      &__message {
        margin-left: rem(5px);
        color: $warning-color;
      }
    }

    .item {
      display: flex;
      justify-content: space-between;
      min-height: rem(28px);
      min-width: rem(275px);
      width: rem(275px);
    }
  }

  .no-min-height {
    min-height: 0;
  }
}

.copy-price-protection-term {
  width: rem(200px);
  padding: 0 rem(16px);
}

ul.info li {
  list-style-type: disc;
}

.reset-value-wrapper {
  min-height: 0 !important;

  a {
    cursor: pointer;
    text-decoration: none;

    .text {
      display: inline-block;
      vertical-align: middle;
    }

    &:hover {
      mat-icon {
        visibility: visible;
      }

      .text {
        text-decoration: underline;
      }
    }
  }
}

.keep-original-size {
  select, input[type='text'] {
    max-width: 135px;
  }

  @media (max-width: 1280px) {
    input[type='text'] {
      max-width: 103px;
    }
  }
}

// WebKit-style focus

@mixin tab-focus() {
  // WebKit-specific. Other browsers will keep their default outline style.
  // (Initially tried to also force default via `outline: initial`,
  // but that seems to erroneously remove the outline in Firefox altogether.)
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

@mixin button-inverse-variant($color, $background, $border) {
  $active-background: lighten($background, 10%);
  $active-border: $border;

  color: $color;
  background-color: $background;
  border-color: $border;

  // Hover and focus styles are shared
  @include hover-focus-active() {
    box-shadow: none;
    color: $color;
    background-color: $active-background;
    border-color: $active-border;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: $background;
    border-color: $border;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &.focus,
  &:focus,
  .show > &.dropdown-toggle {
    color: $color;
    background: $active-background none;
    border-color: $active-border;
    box-shadow: none;
  }
}

@mixin truncate-text() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@import "../../../assets/styles/shared/mixins";
@import "../../../assets/styles/themes/new-ui";
@import "../qv/icons";

.qv-select {
  &.ng-select {
    &.ng-select-single,
    &.ng-select-multiple {
      .ng-select-container {
        height: rem(40px);
        border-radius: 0;
        border: rem(1px) solid $alto;
        color: $cape-code;
        font-weight: normal;
        cursor: pointer;

        .ng-arrow-wrapper {
          width: rem(24px);
          height: rem(24px);
          border-radius: rem(16px);

          @include svg((
            viewBox: 0 0 24 24
          )) {
            @include svg('path', (
              fill: $medium-grey,
              d: $chevron-bottom-svg
            ))
          }
          background-position: rem(1px);
          right: rem(8px);

          .ng-arrow {
            border: none;
          }

          &:hover {
            background-color: $alto;
          }
        }
      }

      &:hover {
        .ng-select-container {
          border-color: $tundora;
        }
      }

      &.ng-select-focused {
        .ng-select-container {
          border-color: $alto;
          box-shadow: none;
        }
      }

      &.ng-select-opened {
        .ng-select-container {
          border-color: $royal-purple;

          &:after {
            border-color: $royal-purple;
          }

          .ng-arrow-wrapper {
            @include svg((
              viewBox: 0 0 24 24
            )) {
              @include svg('path', (
                fill: $medium-grey,
                d: $chevron-top-svg
              ))
            }

            background-position: 0;
          }
        }
      }

      &.ng-select-disabled {
        .ng-select-container {
          cursor: not-allowed;
        }
      }
    }

    &.ng-select-disabled .ng-select-container {
      background-color: $white-smoke;
    }
  }

  &.ng-dropdown-panel {
    box-shadow: 0 rem(1px) rem(4px) 0 $alto;
    border-radius: 0;

    &.ng-select-top {
      margin-bottom: rem(8px);
    }

    &.ng-select-bottom {
      margin-top: rem(8px);
    }

    .ng-dropdown-panel-items {
      margin-bottom: 0;

      .ng-option {
        height: rem(40px);
        padding: rem(12px);
        line-height: 1.25;
        border-radius: 0;
        font-family: $font-family-default;
        font-weight: normal;

        &.ng-option-selected {
          .ng-option-label {
            font-weight: normal;
          }
        }

        &.ng-option-marked, &.ng-option-selected.ng-option-marked, &:hover {
          background-color: $whisper;
        }
      }
    }
  }
}

.table-section .bid-binding-status-editor .ng-select {
  width: rem(160px);
}

.qv-multi-select {

  &--primary {
    .ng-dropdown-header {
      padding: 16px 16px 8px;
    }

    &.ng-select.ng-select-multiple {
      .ng-select-container {
        padding-left: 12px;

        .ng-value-container  {
          padding: 0;

          .ng-input {
            flex: 0;
            padding: 0;
          }

          .ng-placeholder {
            position: static;
            padding: 0;
          }
        }
      }
    }

    &.ng-dropdown-panel {
      margin-top: 0;

      .ng-dropdown-panel-items .ng-option {
        display: flex;

        &-selected {
          &.ng-option {
            background: $snuff;
          }
        }
      }
    }
  }

  &--error {
    &.ng-select.ng-select-multiple .ng-select-container {
      border-color: $scarlet;
    }
  }
}

$column-horizontal-margin: 2px;

/*Column width*/
$title-width: 35px;
$lock-width: 27px;
$tier-placement-width: 0.14;
$tier-competition-width: 0.10;
$competition-group-width: 0.10;
$competitors-tier-width: 0.10;
$st-allowed-width: 0.09;
$st-required-width: 0.09;
$pa-allowed-width: 0.08;
$pa-required-width: 0.09;
$quantity-limit-width: 53px;
$other-um-width: 53px;
$um-details-width: 0.21;
$actions-width: 45px;

// Summary of fixed columns width with margins. 15 - number of columns
$fixed-width-columns-sum: $title-width + 2*$lock-width + $quantity-limit-width + $other-um-width + $actions-width +
                          15*$column-horizontal-margin*2;

/* Mixin for calculating width for flexible row with columns containing both pixels and percentage
   Takes $percentage in decimal ex.: 0.15
*/
@mixin width-calc-mixin ($percentage) {
  width: calc((100% - #{$fixed-width-columns-sum})*#{$percentage});
}

.drug-scenario {
  width: 100%;
  &__header {
    margin: 0 15px 0 0;
  }
  &__col {
    margin: 0 $column-horizontal-margin;
    display: inline-block;
    position: relative;
    font-size: calc(0.6em + 0.33vw); // 0.6em - min font size for width 1024px, 0.33vw - determined experimentally.
    select, input, textarea {
      margin-bottom: 0!important;
      min-width: 0;
      max-width: 100%;
      width: 100%;
    }
    textarea {
      resize: none;
      position: absolute;
      top: -18px;
      z-index: $drug-scenario-textarea-zindex;
      transition: all 0.25s ease-in-out;
      &:focus {
        height: 180px;
        z-index: $drug-scenario-textarea-focus-zindex;
      }
    }
    &--bold {
      font-weight: 600;
    }
    &--underlined {
      border-bottom: 1px $gray solid;
    }
    &--centered {
      text-align: center;
    }
    &--colored {
      background-color: $slate-blue;
      color: $white;
    }
    &--tier-options-header {
      margin-left: calc((#{$title-width} + #{$lock-width} + 6*#{$column-horizontal-margin}));
      width: calc(((100% - #{$fixed-width-columns-sum})*(#{$tier-placement-width} + #{$tier-competition-width} +
             #{$competition-group-width} + #{$competitors-tier-width}) + 4*#{$column-horizontal-margin}));
      margin-right: calc((#{$lock-width} + 4*#{$column-horizontal-margin}));
    }
    &--um-options-header {
      width: calc(((100% - #{$fixed-width-columns-sum})*(#{$st-allowed-width} + #{$st-required-width} +
             #{$pa-allowed-width} + #{$pa-required-width} + #{$um-details-width}) + #{$quantity-limit-width} +
             #{$other-um-width} + 12*#{$column-horizontal-margin}));
      margin-right: calc((#{$actions-width} + 3*#{$column-horizontal-margin}));
    }
    &--step-therapy {
      margin: 5px calc(2*#{$column-horizontal-margin}) 0 calc(((100% - #{$fixed-width-columns-sum})*
              (#{$tier-placement-width} + #{$tier-competition-width} + #{$competition-group-width} + #{$competitors-tier-width}) +
              #{$title-width} + 2*#{$lock-width} + 15*#{$column-horizontal-margin}));
      width: calc(((100% - #{$fixed-width-columns-sum})*(#{$st-allowed-width} + #{$st-required-width})));
    }
    &--prior-authorization {
      margin: 5px calc(((100% - #{$fixed-width-columns-sum})*#{$um-details-width} + #{$quantity-limit-width} +
              #{$other-um-width} + #{$actions-width} + 9*#{$column-horizontal-margin})) 0
              calc((2*#{$column-horizontal-margin}));
      width: calc(((100% - #{$fixed-width-columns-sum})*(#{$pa-allowed-width} + #{$pa-required-width})));
    }
    &--title {
      width: $title-width;
    }
    &--lock {
      width: $lock-width;
      height: 28px;
      vertical-align: bottom;
      visibility: visible;
    }

    &--hidden {
      width: $lock-width;
      height: 28px;
      vertical-align: bottom;
      visibility: hidden;
    }
    &--tier-placement {
      @include width-calc-mixin($tier-placement-width);
    }
    &--tier-competition {
      @include width-calc-mixin($tier-competition-width);
    }
    &--competition-group {
      @include width-calc-mixin($competition-group-width);
    }
    &--competitors-tier {
      @include width-calc-mixin($competitors-tier-width);
    }
    &--st-allowed {
      @include width-calc-mixin($st-allowed-width);
    }
    &--st-required {
      @include width-calc-mixin($st-required-width);
    }
    &--pa-allowed {
      @include width-calc-mixin($pa-allowed-width);
    }
    &--pa-required {
      @include width-calc-mixin($pa-required-width);
    }
    &--quantity-limit {
      width: $quantity-limit-width;
    }
    &--other-um {
      width: $other-um-width;
    }
    &--um-details {
      @include width-calc-mixin($um-details-width);
    }
    &--with-info {
      &:hover {
        .fa-info-circle {
          visibility: visible;
        }
      }

      .fa-info-circle {
        visibility: hidden;
        vertical-align: middle;
        position: inherit;
        margin-left: 2px;
        margin-bottom: 4px;
      }
    }
  }
  &__item {
    margin: 0.5rem 0 0;
    border-bottom: 1px $gray dotted;
    &:last-child {
      border-bottom: none;
    }
  }
  &__item-list {
    height: 275px;
    overflow-y: scroll;
    border: 1px $gray solid;
  }
  &__item-header {
    display: inline-block;
    font-weight: 600;
    background-color: $theme-color;
    color: $white;
    padding: 0 5px;
  }
  &__item-lock {
    padding-left: 10px;
  }
  &__item-actions {
    padding-left: 5px;
    span {
      cursor: pointer;
    }
  }
}

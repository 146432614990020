@import "../shared/mixins";
@import "../themes/new-ui";

qv-date-picker {
  display: inline-flex;

  & > * {
    display: inline-flex;
  }

  .mat-datepicker-toggle {

    & > .mat-icon-button {
      display: flex;
      justify-content: center;
      width: rem(30px);
      height: rem(30px);
      line-height: rem(30px);
    }
  }
}

qv-date-range-filter {

  .mat-datepicker-toggle {

    &-active {
      color: $royal-purple;
    }

    .mat-icon-button {
      width: rem(30px);
      height: rem(30px);
      line-height: rem(30px);
    }
  }
}

.mat-calendar-body-selected {
  background-color: $royal-purple;
}

@import "../shared/mixins";
@import "../themes/new-ui";

$expansion-panel-header-height: 56px;

@mixin panel-item-hover() {
  background: rgba($black, .04);
  border-radius: rem(2px);
  cursor: pointer;
}

.mat-expansion-panel-header,
.mat-expansion-panel-header-title,
.mat-expansion-indicator::after {
  color: $eastern-blue;
}

.mat-drawer {
  filter: blur(0);
}

.mat-drawer-content {
  max-width: 100vw;
}

.mat-drawer-container {
  -webkit-transform: translateZ(0);
}

.qv-accordion {
  background-color: $white;
  padding: 0 rem(8px);
  overflow-x: hidden;
  border-top: rem(1px) solid $isabelline;

  .mat-expansion-panel {
    align-self: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: rem(1px) solid $isabelline;
    transition: height .3s;

    &:not([class*=mat-elevation-z]) {
      box-shadow: none;
    }

    &.mat-expanded:last-child {
      border-bottom: 0;
    }

    &:not(.mat-expanded) .mat-expansion-panel-header:hover {
      background-color: $white;
    }
  }

  .mat-expansion-panel-header {
    padding: 0;
    background-color: $white;
    @include fontSize(16px);
    min-height: rem($expansion-panel-header-height);

    .mat-content {
      align-items: center;
      height: rem(40px);
      padding: 0 rem(8px);

      &:hover {
        @include panel-item-hover();
      }
    }
  }

  .mat-expansion-panel-header-title {
    @include fontSize(14px);
    color: $medium-grey;
    text-transform: uppercase;
  }

  .mat-expansion-panel-header-description {
    justify-content: flex-end;
    margin: 0;
  }

  .mat-expansion-indicator {
    &::after {
      padding: rem(4px);
      color: $medium-grey;
    }
  }

  .mat-expansion-panel-content {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    background-color: $white;

    &.mat-expanded {
      overflow: auto;
      flex: 1;
      border-bottom: 1px solid $isabelline;
    }
  }

  .mat-expansion-panel-body {
    padding: rem(8px) rem(8px) rem(16px);
  }

  .mat-checkbox-layout {
    white-space: pre-line;
  }

  .mat-expansion-indicator {
    height: rem(28px);
  }

  .mat-expansion-panel-header-description > .mat-icon {
    @include colorizeIcon($silver)
  }

  .filter-menu-item {
    &:hover, &--active {
      @include panel-item-hover();
    }
  }

  &__single-menu-item {
    display: flex;
    align-items: center;
    height: rem($expansion-panel-header-height);
    border-bottom: rem(1px) solid $isabelline;
    padding: 0;

    .filter-menu-item {
      .filter-menu-item {
        &__title {
          @include fontSize(14px);
          color: $medium-grey;
          text-transform: uppercase;
          font-family: $font-family-default;
          font-weight: 500;
        }

        &__icon {
          @include resizeIcon(32px);
          margin-right: rem(4px);
        }
      }
    }

    .filter-menu-item-trigger.mat-menu-trigger {
      right: rem(-9px);
    }
  }
}

@import "../../../assets/styles/themes/new-ui";

.mce-content-body {
  font-weight: initial;
}

.mce-tinymce {
  box-shadow: none !important;
}

.mce-panel {
  background: $gallery !important;
}

.mce-btn:not(.mce-active) {
  background: $gallery !important;
}

.mce-top-part::before {
  display: none;
}

.mce-primary {
  button, button i {
    background: $slate-blue;
  }
}

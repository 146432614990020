@import "../../../assets/styles/themes/new-ui";
@import "../../../assets/styles/shared/mixins";

.mat-tab-group,
.mat-tab-nav-bar {
  font-family: $font-family-default;
  font-weight: 500;

  &.mat-primary .mat-ink-bar {
    height: em(4px);
    background-color: $daisy-bush-light;
  }
}

.tab-group {
  &--full-width {
    width: 100%;
  }

  .mat-tab-label {
    height: rem(48px);
    min-width: 0;
    padding: 0;
    opacity: 1;
    @include colorizeIcon($silver);

    &:hover {
      background-color: $tab-label-background-color--hover;
    }

    &-content {
      .tab-group__create-button {
        cursor: pointer;

        &:disabled {
          cursor: not-allowed;
        }

        &:hover:not(:disabled) {
          @include colorizeIcon($medium-grey);
        }

        &:active:not(:disabled) {
          @include colorizeIcon($studio);
        }
      }
    }
  }

  &--right-padding {
    .mat-tab-header {
      padding: 0 rem(56px) 0 0;
    }
  }

  .mat-tab-body-wrapper {
    overflow: auto;
    height: 100%;
  }

  .mat-tab-body-content {
    padding: rem(24px);
  }

  &--left-icon-space {
    .mat-tab-labels {
      margin-right: rem(48px);
    }
  }
}

.mat-tab-header {
  border: none;
  background-color: $tab-label-background-color;
}

.mat-tab-label-active {
  background-color: $tab-label-background-color--active;

  &:focus {
    background-color: $tab-label-background-color--active !important;
  }

  &:hover {
    background-color: $tab-label-background-color--hover !important;
  }

  .tab__title {
    color: $tab-label-color--active;
  }
}

.mat-tab-disabled:not(.mat-tab-label-active) {
  cursor: not-allowed !important;

  .tab__title {
    color: $tab-label-color--disable;
  }
}

.tab {
  &__title {
    @include fontSize(16px);
    font-weight: 500;
    color: $tab-label-color;
    margin: 0;
    padding: 0 rem(16px);
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &--upper-case {
      text-transform: uppercase;
    }

    &--with-menu {
      padding-right: 0;
    }
  }

  &__counter {
    margin-right: rem(16px);
  }
}

.mat-ink-bar {
  visibility: hidden !important;
}

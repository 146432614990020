@use "sass:math";
@import "../../ngx-src/assets/styles/themes/new-ui";
@import "../../ngx-src/assets/styles/shared/mixins";

.control-group {
  display: block;
  padding-top: 0;
  margin-bottom: 5px;

  &.error {
    color: $red;
  }
}

.sidebar-nav-fixed {
  padding-left: math.div($grid-gutter-width-base, 2);
}

.rfp-header-container{
  display: table;
  margin: 15px 0 15px 0;
  position: relative;
  width: 100%;
}

ul.rfp-header {
  display: block;
  margin: 0;
  padding: 0;
  width: 460px;

  li {
    position: relative;
    float: left;
    margin: 0;
    padding: 0;
    min-height: 40px;
    background-color: #d1dddf;
    &.spacer {
      border-bottom: 2px solid $white;
    }
  }
  .item-label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;

    box-sizing: border-box;
    width: 230px;
    padding: 10px 15px 0 0;

    background-color: #52627c;
    text-align: right;
    text-transform: uppercase;
    color: $white;
    font-size: 16px;
    font-weight: 500;
  }
  .item-value {
    display: block;
    float: left;
    width: 220px;
    min-height: 20px;
    margin-left: 230px;
    padding: 11px 10px 9px 10px;
    line-height: 16px;
  }

  .item-input {
    padding: 5px 10px 0 10px;

    .termview {
      margin-bottom: 0;
      padding: 0;
    }
    input {
      width: 200px;
    }
    .alert {
      margin: 0 0 10px 0;
      padding: 0;
      text-align: left;
    }
  }
}

@media only screen and (max-width: 900px) {
  .rfp-header-container{
    position: relative;
    float: left;
    clear: both;
    .rfp-header{
      clear: both;
    }
  }
}

ul.rfp-header-create {
  width: 100%;
  li {
    width: 100%;
  }
  .item-input{
    width: 500px;
    input {
      width: 480px;
    }
  }

}

.overflow-label {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-container {
  border-top: 0;
  padding: 10px 15px;
  overflow: hidden;
}

#createBidModal, .copy-bid, .create-internal-bid, .expandedContainer {

  hr {
    border: 0 solid $input-border-color;
    border-bottom-width: 1px;
  }

  input.attachment-path {
    border-color: $input-border-color;
    margin-bottom: 5px;
  }

  .input-mini.days {
    margin-right: 5px !important;
  }

  .graysection {
    &:not(:first-child),
    &--not-first-column {
      margin-left: 5px;
    }
    .graysection-term {
      display: inline-block;
    }
    .row {
      margin: 0;
    }
  }

  .paddingTop {
    padding-top: 15px;
  }

  .fullWidth {
    width: 100%;
  }

  .comments-term {
    .whitesection {
      background-color: $white;
      display: block;
      overflow-x: hidden;
      overflow-y: auto;
      height: 165px;

      .comments {
        display: block;
        word-wrap: break-word;
      }
      .comments-text {
        display: block;
        white-space: pre-line;
        word-break: break-all;
      }
    }
    textarea {
      height: 114px !important;
      max-width: inherit;
    }
  }

  .summary-left-panel {
    width: 210px;
    float: left;
  }
  .summary-right-panel {
    margin-left: 215px;
  }

  .graysection {
    background-color: $gray-lighter;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 5px;
    vertical-align: top;
    min-height: 150px;

    &.instructions-panel {
      padding: 7px 30px 10px 30px;

      textarea {
        margin-bottom: 0;
        height: 209px;
      }

      .termview {
        padding-top: 0;
        padding-bottom: 0;
        & > .control-group {
          margin-bottom: 0;
        }
      }
    }

    .alert {
      margin-bottom: 0;
      text-align: left;
    }

    .title {
      color: $black;
      display: block;
      font-weight: $bolded-font-weight;
      margin-bottom: 5px;

      .custom {
        display: block;
        margin-bottom: 15px;
        margin-top: 5px;
      }
    }

    .subtitle {
      color: $black;
      display: block;
      font-weight: $bolded-font-weight;
      margin-bottom: 5px;
    }

    .offset {
      margin-right: -$spacer-size;
    }

    &.minheight {
      min-height: 410px;

      .term-label {
        text-align: left;
      }
    }

    &.enlargeterms {
      .term-content {
        display: block;
      }

      .term-label {
        text-align: left;
        width: 100%;
      }
    }

    textarea {
      width: 100%;
      height: 200px;
    }

  }

  .largegraysection {
    background-color: $gray-lighter;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 5px;
    vertical-align: top;
    min-height: 150px;
  }

  table {
    tr, tbody {
        border: none;
    }

    .ndc-row {
      td {
        background-color: $gray-lighter;

        &.first {
          background-color: $gray;
        }
      }
    }
  }

  .dropdown-menu {
    color: $gray-dark;
    position: absolute;
    float: none;

    &:after {
      right: 10px;
      left: auto;
    }

    .termview {
      width: 210px;
      padding: 2px 10px;
    }

    .term-content {
      display: block;
    }
  }

  .prepended-price-currency {
    background-color: #ffffff;
    border: 1px solid $input-border-color;
  }

  .input-prepend .input-field {
    border-width: 1px 1px 1px 0 !important;
  }

  .input-append .input-field {
    border-width: 1px 0 1px 1px !important;
  }

  .range-or-units-and-rebates {
    .range-list {
      display: flex;
      flex-direction: column;
    }
    .range-container {
      display: flex;
      flex-direction: row;
      &:not(:first-child) {
        margin-top: 0.25rem;
      }
    }

  }

  .range-buttons {
    span {
      margin-left: 5px;
    }
  }

  .contracted-business-tabs {
    padding: 0;

    .contracted-business-tabs-container {
      padding-left: 0;
      min-height: 35px;
      border-bottom: 3px solid $slate-blue;
      width: 100%;

      &.stuck-container {
        z-index: $contracted-business-tabs-zindex;
      }

      .input-min-width-100 {
        min-width: 100px;
      }

      .navbar-nav {
        flex-direction: row;

        .nav-item {
          background-color: $gray-light;
          position: relative;
          height: 39px;
          margin: 0;
          border-width: 1px 1px 0 0;
          border-style: solid;
          border-color: $medium-grey;

          &.tab-class {
            height: 39px;
          }

          &.addplantype {
            padding: 2px;
            border-width: 1px 1px 0 0;
            border-style: solid;

            a {
              background-color: $gray-light;
              text-align: center;
              height: 19px;
            }
          }

          .nav-link {
            color: $medium-grey;
            margin: 1px 2px;
            padding: 7px 10px 10px;
            font-size: 14px;
            font-weight: 400;
            height: 20px;

            .details {
              font-size: 12px;
              font-weight: 300;
            }

          }

          .nav-link:hover {
            color: $gray-dark;
          }

          &.first {
            border-left: 1px solid $slate-blue;
          }

          &.active {
            background-color: $slate-blue;
            border-color: $slate-blue;
            color: $white;
            height: 35px;

            &.tab-class {
              height: 39px;
            }

            .nav-link, .nav-link:active, .nav-link:visited {
              border-bottom: 0 none;
              color: $white;
            }

            &:hover, .nav-link:hover {
              background-color: $tab-hover;
            }

            &.error {

              .nav-link:hover {
                background-color: $error-color !important;
              }
            }

            &.error {
              background-color: $error-color;
              border-color: $error-color;
            }

          }
        }
      }
    }
  }

  .savingError {
    background-color: $red;
    bottom: 0;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    display: none;
    font-weight: bold;
    height: 25px;
    left: 100%;
    margin-left: -100%;
    margin-top: -25px;
    position: fixed;
    text-align: center;
    top: 100%;
    width: 100%;
    z-index: $bid-details-save-zindex;

    .error {
      color: $white;
    }
  }

  .savingSuccess {
    background-color: $white;
    bottom: 0;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    display: none;
    font-weight: bold;
    height: 25px;
    left: 100%;
    margin-left: -152px;
    margin-top: -25px;
    position: fixed;
    text-align: center;
    top: 100%;
    width: 150px;
    z-index: $bid-details-save-zindex;

    .error {
      color: $red;
    }

    .bidssavingspinner {
      display: inline-block;
      width: 22px;
      height: 100%;
      background-size: contain;
      background: url("/assets/css/images/bids-saving-ajax-loader.gif") no-repeat center;
    }

    .bidssavingspinnermessage {
      padding-top: 2px;
      vertical-align: top;
      display: inline-block;
    }
  }

  .popover {
    width: 230px;
  }

  .modalDialogError {
    position: absolute;
    z-index: $modal-dialog-error-zindex;
    margin-top: -9px;

    .triangle-up {
      width: 0;
      height: 0;
      margin-left: 80px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 5px solid $gray-dark;
    }

    .modalDialogBox {
      position: relative;
      width: 1$row-min-height;
      padding: 5px;
      background: $white;
      border: 1px solid $gray-dark;
      color: $gray-darker !important;
      font-size: 14px;
      -moz-box-shadow: 1px 1px 6px $gray-dark;
      -webkit-box-shadow: 1px 1px 6px $gray-dark;
      box-shadow: 1px 1px 6px $gray-dark;

      .closeModal {
        color: $theme-color;
        font-size: 14px;
        font-weight: bold;
        position: relative;
        float: left;

        &:hover {
          color: $theme-color-hover;
          text-decoration: none;
        }
      }
    }
  }

}

.drugViewMode {
  // Hide :hover styles for "$NDC" links when drug is not in edit mode
  .link-item-nocolor {
    &:hover {
      cursor: default;
      color: $teal;
      display: inline-block;
      background-color: transparent;
    }
  }
}

.deleteActionContainer {
    cursor: default;
    display: block;
    text-align: right;
}

.expandable {
  display: block;
  margin-bottom: 30px;
  background-color: $white;
  transition: margin-top $header-animation-duration ease-in-out;

  .header {
    display: block;
    color: $slate-blue;
    position: relative;
    padding-bottom: 2px;
    border-bottom: 1px solid $gray-light;
    padding-left: 20px;
    margin-left: 0;

    .custom {
      margin: 4px 0;
    }

    .toggle {
      position: absolute;
      margin-left: -20px;
    }
  }

  .content {
    position: relative;
    padding-top: 10px;
  }

}

.maximized {
  border: 1px solid #000000;
  background-color: $white;
  margin-top: 112px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 10px;
  z-index: $maximized-zindex;
  transition: margin-top $header-animation-duration ease-in-out;

  .toggle {
    display: none;
  }

  .header {
    display: block;
    padding-bottom: 2px;
    border-bottom: 1px solid $gray-light;
    margin-left: 2px;

    .custom {
      margin: 4px 0;
    }
  }

  .content {
    display: block;
    background-color: $white;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    top: 30px;
    bottom: 0;
    left: 1px;
    right: 1px;
    padding-top: 10px;
  }
  .contracted-businesses {
    height: 60px;
    &--actions {
      display: flex;
      flex-direction: row;
    }
  }

  #drugsTable {
    .tall {
      top: 156px;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      &.editable {
        top: 177px;
      }
    }
  }
}

@media (max-width: 800px) {
  .backdown-body {
    margin-top: 110px;
  }

  .backdown-link {
    margin-top: 11px;
  }
}

#drugsTable {

  .tooltip {
    z-index: $drug-table-tooltip-zindex;
  }

  .tooltip.bottom {
    margin-top: 0;
  }

  .center-align {
    text-align: center;
  }

  table {
    margin-bottom: 0;

    td {
      padding: 0;

      &.bottom-bordered {
        border-bottom: 1px solid $slate-blue;
      }

      &.vertical {
        vertical-align: middle;
      }

      &.center {
        text-align: center;
      }

      &.terms {
        vertical-align: top;
        border-left: 1px solid $slate-blue-light;
        border-bottom: 2px solid $slate-blue;
        border-collapse: collapse;
      }

      .termsgroup {
        width: 100%;
        display: block;
        min-height: $row-min-height;
        vertical-align: middle;
        position: relative;
        border-bottom: 1px solid $slate-blue-light;

        &:nth-child(5) {
          border-bottom: none;
        }

        .term-content {
          display: inline-block;
          color: $black;
        }

        &--md {
          min-height: $row-min-height-md;
        }

        .price-protection {
          .term-value-noedit {
            min-height: 40px;
          }
        }

        .term-label {
          display: block;
          color: $gray-dark;
        }
        .term-label-big {
          width: 250px;
        }

        .label-color {
          color: $black !important;
        }

        .groupname {
          display: block;
          position: absolute;
          width: $group-width;
          float: left;
          text-align: center;
          line-height: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          overflow: visible;
          margin: 0;
          padding: 10px 0 0 0;
        }

        .dashed {
          border-right: 1px dashed $slate-blue-light;
          margin-right: 5px;
        }

        .groupvalue {
          display: block;
          margin-left: $group-width;
          min-height: $row-min-height;

          .term-wrapper:not(.not-inline) {
            position: relative;

            & > * {
              display: inline-block;
              vertical-align: middle;
            }
          }

          span {
            display: inline-block;
            vertical-align: middle;
          }

          .group {
            display: inline-block;
            border-width: 0 1px;
            border-style: dashed;
            border-color: $slate-blue-light;
            padding: 0 0 0 10px;
            min-height: $row-min-height;

            &.noborder {
                border: none;
            }

            &.noborder-right{
              border-right: none;
            }

            .cust-sprite {
                margin: 0;
                display:inline-block;
            }

            &.vgroup {
              padding: 0;
              .group-row {
                clear: both;
                float: left;
                padding: 0 10px 5px 10px;
                min-height: 59px;
              }
              .border-bottom-dashed {
                border: 0 dashed $slate-blue-light;
                border-bottom-width: 1px;
              }
            }
            &.vgroup-first {
              .group-row {
                padding-left: 15px;
              }
            }
          }

          .w1 {
            width: 80px;
            overflow: hidden;
          }
          .w2 {
            width: 150px;
          }

          .w3 {
            width: 200px;
          }

          .w4 {
            width: 250px;
          }

          .w5 {
            width: 450px;
            overflow: hidden;
          }

          .w6 {
            width: 110px;
            overflow: hidden;
          }

          .w7 {
            width: 220px;
            overflow: hidden;
          }
        }
        &.smallRow {
          min-height: $row-small-min-height;
          .w3 {
            width: 310px;
          }
          .groupvalue {
            min-height: $row-small-min-height;
          }
        }
        &.umRow {
          display: inline-block;
          min-height: $row-min-height-lg;
          .groupvalue, .group {
            min-height: $row-min-height-lg;
          }
        }
      }
    }
    &.table-bordered {
      border-right: 0 none;
    }

  }

  .tiny {
    width: 40px;
  }

  .medium {
    width: 160px;
  }

  .big {
    width: 250px;
  }

  .colorBox {
    border: 1px solid #808080;
    padding: 2px 3px 0 3px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;

    .innerColorBox {
      height: 14px;
      width: 14px;
      display: inline-block;
    }
  }
  .colorBoxLabel {
    margin-left: $spacer-size;
  }
  .colorBoxHeader {
    height: 30px;
  }
  // Fix bottom border in safari when zooming out; see #QU-5070
  .drugEditMode {
    .termsgroup.umRow {
      .groupvalue, .group {
        min-height: 131px;
      }
    }
  }
}

#createBidModal, .copy-bid, .create-internal-bid {

  .header-create-bid, .header-copy-bid {
    padding: 17px 20px 5px;
    margin-bottom: 0;
    height: 50px;
  }

  .content-create-bid, .content-copy-bid {
    @include stretched-flex-container();
    position: relative;
    padding: 10px 10px 0;
    border: 1px solid $gray;
    overflow: auto;
    margin-bottom: 50px;

    .tier-competition {
      padding: 0 0 0 12px !important;
    }

    .left {
        border-right: 1px solid $gray;
        float: left;
        min-height: 596px;
        width: 50%;
    }

    .right {
        display: inline;
        left: 50%;
        min-height: 586px;
        padding-top: 15px;
    }

    .btn-drugs-evaluated {
      text-align: left;
      height: 60px;
      margin-left: 0;
      h3 {
        display: inline-block;
        margin-top: 12px;
      }
    }

    .drugs-evaluated-info {
      background-color: $gray-lighter;
      height: 60px;
      padding: 6px;
      text-align: center;
    }

    .graysection {

      &.instructions-panel {
        margin: 0;
      }

      .term-label {
        text-align: left;
      }

      .navbar {
        margin-bottom: 10px;
      }

      .row {
        margin: 0;
      }

      .navbar {
        margin-bottom: 10px;
      }

      .row {
        margin: 0;
      }

      &.minheight {
        min-height: 500px;

        &.short {
          min-height: 100px;
        }

        .term-label {
          text-align: left;
          &.vertical-label {
            width: 100%;
            text-align: left;
          }
        }
      }

    }
  }
  .footer-create-bid, .footer-copy-bid {
    position: fixed;
    z-index: $bid-footer-zindex;
    padding-bottom: 5px;
    bottom: 0;
    width: $fixed-content-width;
    height: 50px;
    background-color: white;

    .footer-create-bid-inner, .footer-copy-bid-inner {
      padding: 10px 0 0 0;
      margin-right: 20px;
    }
    .actionButtonContainer {
      width: 215px;
      padding: 0;
    }
  }
  .ppSection {
    border-right: 1px dashed $slate-blue-light;
  }
}

.list {

  .icon {
    cursor: pointer;
  }

  .fa-remove, .fa-plus {
    position: absolute;
    right: 5px;
    top: 10px;
  }

  .groupDrug {
    display: block;
    flex-shrink: 0;

    .drugName {
      position: relative;
      padding: 8px 15px 8px 0;
      color: $gray-dark;

      &.drugRemoveStyle {
        color: $red;
        background-color: lighten($pinkish, 5%) !important;
      }
    }

  }

  .drugClassClickable{
    cursor: pointer;
    &:hover{
      background-color: lighten($group-section-checked, 5%);
      text-decoration: underline;
    }
  }

  .listItem {
    display: block;
    padding: 8px 15px;
    color: $black;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 0 solid $gray-light;
    border-bottom-width: 1px;

    &.clickable {
      cursor: pointer;
    }

    &.noRemove:hover {
      &.exists:hover {
        background-color: $group-section-checked !important;
        box-shadow: none;
      }
    }

    &.exists {
      background-color: $group-section-checked !important;



      &:hover {
        background-color: $pinkish !important;
        .fa-remove { display: block !important ; }
        .fa-plus { display: none !important ; }
      }
    }

    &.icons {
      .fa-remove { display: none; }
      .fa-plus { display: none; }

      &:hover {
        .fa-plus { display: block; }
      }
    }

    &.innerDrug {
      border: 1px solid $gray;
      border-top-width: 0;
      background-color: $gray-lighter;

      &:first-child {
        border-top-width: 1px;
      }
    }

    &.innerDrugRemoveStyle, .innerDrugRemoveStyle {
      background-color: $pinkish !important;
      box-shadow: 3px 3px 3px $box-shadow-color;
      z-index: $inner-drug-zindex;
    }

    input {
      margin-top: 0;
    }

    &:hover, &.selected {
      background-color: lighten($group-section-checked, 5%);
      box-shadow: 0 3px 3px $box-shadow-color;
      z-index: $group-section-checked-zindex;
    }

    &.checkedClass {
      background-color: $group-section-checked;
      border-right: 1px solid $gray;
    }

    &.hasLeftBorder {
      border-left: 1px solid $gray;
    }

    .too-many-results {
      margin-left: 0;
    }

    .listItem {
      border-width: 1px 0;
      border-style: solid;
      border-color: $gray-light;
      height: 21px;
      input.double-left-margin {
        margin-right: 2px;
      }
      &:hover {
        background-color: $group-section-checked;
        box-shadow: 1px 1px 1px 1px $box-shadow-color;
        z-index: $group-section-checked-nested-zindex;

        &.operation-info {
          background-color: transparent;
          box-shadow: none;
        }
      }
      &.checkedClass {
        background-color: $group-section-checked;
        &:hover {
        }
      }
      &.checkedClass.incompleteClass {
        background-color: transparent;
      }
      &.incompleteClass {
        background-color: transparent;
        cursor: not-allowed;

      }
    }

    &.removable {
      padding: 0;

      &:hover {
        background-color: $pinkish !important;
      }

      div {

        padding: 8px 15px;
        &:hover {
          background-color: $white;
        }
      }
    }

  }
}

.drugs-table {
    padding: 0;
    border: none;

    .top-bordered-thin {
      border-top: 1px solid $slate-blue;
    }
    .top-bordered-med {
        border-top: 2px solid $slate-blue;
    }
    .bottom-bordered-thin {
        border-bottom: 1px solid $slate-blue;
    }
    .bottom-bordered-med {
        border-bottom: 2px solid $slate-blue;
    }
    .left-bordered-med {
        border-left: 2px solid $slate-blue;
    }
    .left-border-thick {
      border-left: 3px solid $slate-blue;
    }
    .left-border-thin {
      border-left: 1px solid $slate-blue;
    }

    th {
      .ndcTH {
        width: 250px !important;
      }
    }

    tr {

        &.dismissed {
            td {
                .termsgroup {
                    .groupname {
                       background-color: $tableGrayDark;
                       color: $gray-darker !important;
                    }
                    .groupvalue {
                       background-color: $gray-silver;

                       .term-label, .term-content {
                           color: $tableGrayDarker !important;
                       }

                      .qv-icon-web1-icon-cart-empty:hover {
                        background-position:0 -827px;
                        cursor: default;
                      }

                      .qv-icon-web1-icon-notes-empty:hover {
                        background-position:0 -1517px;
                        cursor: default;
                      }
                    }

                }
                .notvisible {
                    background: none;
                }
                &.ndcGroupTD, &.groupArrowTD, &.checkboxTD, &.ndcTD, &.ndcCheckboxTD {
                    background-color: $tableGrayDark;
                }

                &.checkboxTD {
                   background-color: $checkbox-td;
                }
            }
        }

        &.enabled {
          &:hover {
            td.terms {
              @include background-2color-h(darken($tr-hover, 10%), transparent, $group-width);
            }
            background-color: darken($tr-hover, 5%) !important;

            .checkboxTD, .groupname, .groupArrowTD, .drugname, .ndcCheckboxTD {
              background-color: darken($tr-hover, 10%) !important;
            }

            .notvisible {
              background-color: darken($tr-hover, 10%);
            }

          }
        }

        &.trIsGroup {
          background-color: $ndc-td;
          td.terms {
            @include background-2color-h($ndc-group-td, $ndc-td, $group-width);
          }
          .groupname, .groupArrowTD, .drugname {
            background-color: $ndc-group-td;
          }
        }

        &.trIsNotGroup {

          background-color: lighten($ndc-td, 5%);
          td.terms {
            @include background-2color-h(lighten($ndc-group-td, 5%), lighten($ndc-td, 5%), $group-width);
          }

          .groupname, .groupArrowTD, .ndcCheckboxTD, .drugname {
            background-color: lighten($ndc-group-td, 5%);
          }

          .notvisible {
            background-color: lighten($ndc-group-td, 5%);

            .groupname {
              color: $tableGrayDarker !important;
            }

            .groupvalue {
              .term-label, .term-content {
                color: $tableGrayDarker !important;
              }
            }
          }

        }

        td {
            &.checkboxTD {
                background-color: $checkbox-td;
                border-left: 1px solid $slate-blue;
                border-bottom: none;
            }

            &.drugname {
                width: 160px;
                color: $black;
            }
        }
        &.dismissed {
            td {
                &.terms {
                    @include background-2color-h($tableGrayDark, $gray, $group-width);
                }
            }
        }
    }
  &--drug {
    width: 15%;
  }
  &--rebate-eligible {
    width: 8%;
  }
  &--pharma {
    width: 15%;
  }
  &--drug-class {
    width: 25%;
  }
  &--active {
    width: 5%;
  }
  &--rx-otc {
    width: 12%;
  }
  &--name {
    width: 12%;
  }
  &--feedback {
    width: 8%;
  }
}

#tinymce {
  font-size: 12px;
}

.groupArrowTD .icon {
  cursor: pointer;
}

.icon.error{
  width: 17px;
  height: 17px;
  display: inline-block;
  background: url("/assets/css/images/error-icon.png") left top no-repeat;
  padding-top: 2px;
  &:hover{
    background-position: left -21px;
  }
}

.stuck-container{
  transition: top $header-animation-duration ease-in-out;

  .planTypeEditContainer, .addplantype {
    display: none!important;
  }
  .planTypeDisplayContainer {
    display: block!important;
  }
}

// Set Drug List & Show/Hide behaviour depending on window width
@media (max-width: 600px) {
  .maximized #drugsTable .tall {
    top: 306px;
    &.editable {
      top: 327px;
    }
  }
  .drugListFilter {
    .item {
      width: 100%;
      margin: 0;
      float: left;
    }
  }
}

@media (min-width: 601px) and (max-width: 670px) {
  .maximized #drugsTable .tall {
    top: 222px;
    &.editable {
      top: 243px;
    }
  }
}

@media (min-width: 671px) and (max-width: 1300px) {
  .maximized #drugsTable .tall {
    top: 192px;
    &.editable {
      top: 215px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1300px) {
  .drugListFilter {
    max-width: 1000px;
  }
}

@use "sass:math";
@import "../shared/mixins";
@import "../themes/new-ui";
@import "../qv/icons";

$row-height: 48px;
$ndc-row-vertical-margin: 8px;
$grid-border: 1px solid  rgba(0, 0, 0, 0.1);
$grid-border-radius: rem(4px);
$grid-header-height: rem(102px);
$grid-left-side-width: rem(476px); // Property used like config in GridViewConfigService
$grid-border-width: 1px; // Property used like config in GridViewConfigService
$gridDrugInfoMinHeight: rem(21px);

.grid {
  font-family: $font-family-default;
  display: flex;
  flex-direction: column;
  min-width: 100%;

  &__substrate {
    background-color: $wild-sand;
    z-index: $zindex-grid-substrate;
    will-change: transform, opacity;
    -webkit-font-smoothing: subpixel-antialiased;
    @include prefix(backface-visibility, hidden);
    transition: opacity 0.5s ease-in-out, z-index 1s ease-in-out;

    @include supportsPositionSticky() {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      left: 0;
    }

    &--placeholder {
      margin-left: -$grid-left-side-width;
      margin-right: $grid-left-side-width;

      &-hidden, .grid__drug-wrapper--placeholder {
        z-index: $zindex-grid-substrate - 1;
        opacity: 0;
      }
    }

    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: rem(16px);
      height: 100%;
      background-color: $wild-sand;
    }
  }

  &__drug-wrapper {
    background-color: $white;
    margin-left: rem(16px);
    padding-bottom: rem(12px);
    transition: opacity 0.5s ease-in-out, z-index 1s ease-in-out;
    -webkit-font-smoothing: subpixel-antialiased;
    @include prefix(backface-visibility, hidden);

    &--placeholder {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
      z-index: $zindex-grid-row-placeholder;
      width: calc(#{$grid-left-side-width} - #{rem(16px)});
    }

    &--right-side-hidden {
      position: absolute;
      opacity: 0;
      z-index: 0;
    }

    &--group-start {
      margin-top: rem(32px);
      padding-top: rem(12px);
      border-top: $grid-border;
    }

    &--group-end {
      border-bottom: $grid-border;
    }

    &--left-side {
      margin-right: 0;
      margin-left: rem(16px);
      border-left: $grid-border;

      &.grid__drug-wrapper--group-start {
        border-top-left-radius: $grid-border-radius;
      }

      &.grid__drug-wrapper--group-end {
        border-bottom-left-radius: $grid-border-radius;
      }

      .grid__scenario-row > .grid__row {
        margin-right: 0;
      }
    }

    &--fixed-size {
      width: calc(#{$grid-left-side-width} - #{rem(16px)});
    }

    &--right-side {
      margin-left: 0;

      &.grid__drug-wrapper--group-start {
        border-top-right-radius: $grid-border-radius;
      }

      &.grid__drug-wrapper--group-end {
        border-bottom-right-radius: $grid-border-radius;
      }

      .grid__scenario-row > .grid__row {
        margin-left: 0;
        min-width: calc(100vw - #{$grid-left-side-width} - #{rem(48px)});
      }

      &-placeholder {
        margin-left: rem(16px);
        padding-left: calc(#{$grid-left-side-width} - #{rem(16px)});
      }
    }

    .grid__cell {
      margin-top: 0 !important;

      &--border-half {
        &:not(.grid__cell--active) {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: rem(24px);
            border-left: solid 1px $silver;
          }
        }

        &.grid__cell--active {
          border-left: solid 1px;
        }
      }

      &--active {
        & + .grid__cell--border-half {
          &:before {
            border-left-width: 0;
          }
        }
      }
    }

    .grid__column {
      &--empty {
        border-top: solid 1px $silver;
        border-bottom: solid 1px $silver;
        background-color: $alto;
      }

      &--ndc, &--ndc + .grid__column {
        background-color: $snuff;
      }

      &--allowed, &--allowed + .grid__column {
        background-color: $catskill-white;
      }

      &--locked, &--locked + .grid__column {
        background-color: $catskill-white;
      }

      &--required, &--required + .grid__column {
        background-color: $light-yellow;
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    height: 100%;

    &--body {
      height: rem($row-height);
      position: relative;
      z-index: $zindex-grid-row;
    }

    &--header {
      margin: 0 rem(32px) 0 0;
      height: $grid-header-height;
    }

    &--border {
      border: solid 1px $slate-gray;
      border-top: none;
    }

    &--border-right {
      border-right: solid 1px $silver;
    }

    &--stretched {
      flex-grow: 1;
    }

    &--section {
      flex: 1 0 rem(36px);
    }

    &--drug-info-empty {
      height: rem(42px);
    }

    &--ndc-info-empty {
      height: $gridDrugInfoMinHeight;
    }
  }

  &__column {
    display: flex;
    min-height: rem(32px);
    flex-direction: column;

    &--fixed-width {
      flex: 1 0 rem(176px);
    }

    &--cb,
    &--drug-status {
      flex: 0 0 rem(133px);
    }

    &--border {
      border: solid 1px $slate-gray;
    }

    &--border-top {
      border-top: solid 1px $slate-gray;
    }

    &--border-bottom {
      border-bottom: solid 1px $slate-gray;
    }

    &--border-left {
      border-left: solid 1px $slate-gray;
    }

    &--border-right {
      border-right: solid 1px $slate-gray;
    }

    &--border-h {
      border-right: solid 1px $slate-gray;
      border-left: solid 1px $slate-gray;
    }

    &--border-v {
      border-top: solid 1px $slate-gray;
      border-bottom: solid 1px $slate-gray;
    }

    &--empty {
      flex: 0 0 auto;
      min-width: rem(24px);
      max-width: rem(24px);
    }
  }

  &__cell {
    display: flex;
    flex: 1 1 auto;
    min-height: rem(32px);

    &--section-state {
      min-width: rem(24px);
      max-width: rem(24px);
      flex-direction: column;

      &.grid__cell--active {
        border-left: solid 1px;
        border-right: solid 1px;
      }

      .term.term--no-layout {
        margin: 0;
      }
    }

    &--center {
      text-align: center;
      justify-content: center;
      flex-direction: column;
    }

    &--left {
      text-align: left;
      justify-content: flex-start;
      align-items: center;
    }

    &--padding-h {
      padding: 0 rem(16px);
    }

    &--icon {
      align-items: stretch;
      max-width: rem(74px);
    }

    &--actions {
      flex-direction: column;
      align-items: start;
      justify-content: center;
      padding: 0 rem(5px);

      @include supportsPositionSticky() {
        padding: 0 rem(14px);
      }
    }

    &--disabled {
      background-color: $white-smoke;
      cursor: not-allowed;
    }

    &--border {
      border: solid $grid-border-width $slate-gray;
    }

    &--border-v {
      border-top: solid $grid-border-width $slate-gray;
      border-bottom: solid $grid-border-width $slate-gray;
    }

    &--border-h {
      border-left: solid $grid-border-width $slate-gray;
      border-right: solid $grid-border-width $slate-gray;
    }

    &--border-left {
      border-left: solid $grid-border-width $slate-gray;
    }

    &--border-right {
      border-right: solid $grid-border-width $slate-gray;
    }

    &--border-top {
      border-top: solid $grid-border-width $slate-gray;
    }

    &--border-bottom {
      border-bottom: solid $grid-border-width $slate-gray;
    }

    &--xsm {
      flex: 1 1 auto;
      min-width: rem(84px);
      max-width: rem(84px);
    }

    &--sm {
      flex: 1 1 auto;
      min-width: rem(104px);
      max-width: rem(104px);
    }

    &--md {
      flex: 1 1 auto;
      min-width: rem(128px);
      max-width: rem(128px);
    }

    &--lg {
      flex: 1 1 auto;
      min-width: rem(168px);
      max-width: rem(168px);
    }

    &--xl {
      flex: 1 1 auto;
      min-width: rem(256px);
      max-width: rem(256px);
    }

    &--xxl {
      flex: 1 1 auto;
      min-width: rem(320px);
      max-width: rem(320px);
    }

    &--rouar-2 {
      flex: 1 1 auto;
      min-width: rem(240px);
      max-width: rem(240px);

      &-edit {
        min-width: rem(288px);
        max-width: rem(288px);
      }
    }

    &--rouar-4 {
      flex: 1 1 auto;
      min-width: rem(464px);
      max-width: rem(464px);

      &-edit {
        min-width: rem(528px);
        max-width: rem(528px);
      }
    }

    &--rouar-6 {
      flex: 1 1 auto;
      min-width: rem(688px);
      max-width: rem(688px);

      &-edit {
        min-width: rem(774px);
        max-width: rem(774px);
      }
    }

    &--rouar-8 {
      flex: 1 1 auto;
      min-width: rem(912px);
      max-width: rem(912px);

      &-edit {
        min-width: rem(1022px);
        max-width: rem(1022px);
      }
    }

    &--rouar-10 {
      flex: 1 1 auto;
      min-width: rem(1136px);
      max-width: rem(1136px);

      &-edit {
        min-width: rem(1230px);
        max-width: rem(1230px);
      }
    }
  }

  &__action {
    display: flex;
    height: rem(32px);

    .mat-button-wrapper {
      display: flex;
      align-items: center;

      mat-icon {
        @include resizeIcon(20px);
        margin-right: rem(16px);
      }

      span {
        line-height: normal;
      }
    }

    &.mat-button {
      padding-left: rem(8px);

      &[disabled] {
        color: $silver;
        cursor: not-allowed;
      }
    }

    &.mat-checkbox {
      padding-left: rem(10px);

      .mat-checkbox-inner-container {
        margin-right: rem(17px);
      }

      .mat-checkbox-layout {
        align-items: center;
      }

      .mat-checkbox-label {
        font-size: rem(14px);
        font-weight: 500;
        color: $shark;
      }

      &.mat-checkbox-disabled .mat-checkbox-label {
        color: $silver;
      }
    }

    &--active {
      color: $purple;
    }

    &--filter.mat-button {
      height: rem(55px);
      min-width: rem(55px);
      padding: 0;

      .mat-button-wrapper .mat-icon {
        margin: 0;
        width: rem(30px);
        height: rem(30px);
      }
    }
  }

  &__ndc-rows {
    position: relative;
    padding-top: rem(16px);

    & > .grid__ndc-row {
      display: flex;
      position: relative;
      flex-direction: column;
      z-index: $zindex-grid-row;

      &--left-margin {
        margin-left: rem(50px);
      }

      & > .grid__row {
        margin: rem($ndc-row-vertical-margin) 0;

        &--body-ndc {
          height: rem($row-height);
          margin-right: rem(16px);

          .grid__column--empty {
            background-color: $snuff;
          }
        }
      }
    }

    &--history:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: rem(math.div($row-height, 2) + $ndc-row-vertical-margin);
      width: rem(20px);
      left: rem(30px);
      border-left: 1px solid $silver;
    }
  }

  &__flex-wrapper {
    display: flex;

    &--fixed-size {
      width: $grid-left-side-width;
    }

    &--fixed {
      position: fixed;
      left: rem($system-menu-width);
      height: $grid-header-height;
      padding-left: rem(16px);
      background-color: white;
      z-index: $zindex-grid-wrapper-fixed;
      transform: translate3d(0,0,0);
      -webkit-font-smoothing: subpixel-antialiased;
      @include prefix(backface-visibility, hidden);

      @include supportsPositionSticky() {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        padding-left: 0;
      }

      & + .grid__flex-wrapper {
        margin-left: $grid-left-side-width;

        @include supportsPositionSticky() {
          margin-left: 0;
        }
      }
    }

    &--full-width {
      width: 100%;
      margin-left: rem(16px);

      @include supportsPositionSticky() {
        width: auto;
        margin-left: 0;
      }
    }
  }

  &__drug-status {
    display: flex;
    margin-left: rem(8px);
  }

  .range-or-unit-and-rebate {
    &__body {
      max-width: rem(216px);
      width: rem(216px);
    }

    .range-rebate-form__input {
      max-width: rem(43px);
      min-width: rem(43px);
    }

    &__body-wrap {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    &__value {
      max-width: rem(50px);
      text-align: right;
      width: rem(50px);
    }
  }

  .grid__section {
    display: flex;
    border-right: solid 1px $silver;

    &--last-cell-active {
      border-right-color: $purple;
    }

    &--edit-mode {
      .grid__cell:not(.grid__cell--readonly):not(.grid__cell--error):not(.grid__cell--warn):not(.grid__cell--disabled) {
        background-color: $white;
      }

      .grid__cell:not(.grid__cell--readonly) {
        .term {
          &--no-layout, &--layout-two-column .term__column-right {
            > * {
              padding: rem(1px);
            }
          }

          &--no-layout {
            margin: 0;

            .ng-select.ng-select-single .ng-select-container .ng-value-container {
              padding-left: rem(16px);
            }

            .term--view, .term-date--view {
              margin: rem(0 16px);
            }
          }

          &--layout-two-column {
            .ng-select.ng-select-single .ng-select-container .ng-value-container {
              padding-left: 0;
            }

            .term {
              &__column-right {
                margin: 0;

                .term__side--right {
                  .term--view, .term-date--view {
                    margin: rem(0 16px 0 0);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.grid-header {
  position: fixed;
  top: rem(112px);
  left: rem($system-menu-width);
  right: 0;
  overflow-x: hidden;
  margin-right: rem(16px);
  padding: rem($header-padding) 0;
  background-color: $white;
  box-shadow: 0 rem(1px) rem(10px) rgba(0, 0, 0, .1);
  z-index: $zindex-grid-header;
  transform: translate3d(0,0,0);
  -webkit-font-smoothing: subpixel-antialiased;
  @include prefix(backface-visibility, hidden);

  @supports (-ms-ime-align:auto) {
    top: 0;
  }

  @include supportsPositionSticky() {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    overflow-x: initial;
  }

  .grid__column {
    background-color: $white;

    &--accent {
      background-color: $cadet-blue;
    }

    &--cb,
    &--drug-status {
      flex: 0 0 rem(133px);
      border-left: 0;
    }

    &--cb {
      .grid__cell {
        padding: rem(16px);

        .grid-header-cell {
          &__sort-icon {
            top: calc(50% + 10px);
            left: rem(58px);
          }
        }
      }
    }

    &--drug-status {

      .grid__cell {
        padding: rem(30px);
      }
    }
  }
}

:not(.grid__ndc-row) .grid__row--body {
  .grid__cell:not(.grid__cell--error):not(.grid__cell--warn):not(.grid__cell--disabled) {
    background-color: $isabelline;
  }
}

.grid__row--body-ndc, .grid__ndc-row .grid__row--body {
  .grid__cell:not(.grid__cell--error):not(.grid__cell--warn):not(.grid__cell--disabled) {
    background-color: $white-lilac;
  }
}

.grid__row--body, .grid__ndc-row {
  .grid__cell {
    &.grid__cell {
      &--warn {
        background-color: $light-yellow;
      }

      &--error {
        background-color: $pink-lady;

        .term__field, .ng-select .ng-select-container, .term-date-picker__field {
          background-color: transparent;
        }

        &.grid__cell--border-half:not(.grid__cell--active) {
          border-left: $grid-border;

          &::before {
            display: none;
          }
        }

        .term__input {
          color: $error-color;

          .term__value--empty {
            color: $tundora;
          }
        }
      }

      .ndc-header {
        qv-lock ::ng-deep {
          .lock {
            display: block;

            &__button {
              display: flex;
              justify-content: center;

              .mat-button-wrapper {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}


.grid__row--body, .grid__row--body-ndc {
  .grid__cell {
    border-color: $silver;
    outline: none;

    &--active {
      border-color: $purple;

      & + .grid__cell--border-left, & + .grid__cell--border {
        border-left-color: $purple;
      }

      & + .grid__cell--error, & + .grid__cell--warn {
        &.grid__cell--border-half, &.grid__cell--border {
          border-left-color: $purple !important;
        }
      }

      & + .grid__cell--border-half {
        border-left: solid 1px $purple;
      }
    }
  }
}

.grid__scenario-row > .grid__row {
  margin: 0 rem(16px);

  &-expanded {
    cursor: pointer;
  }
}

.grid__row.grid__row--ndc-info, .grid__row.grid__row--drug-info {
  width: calc(100vw - 82px);
  margin: 0 rem(16px) 0 0;
  align-items: center;
  background-color: transparent;
  min-height: $gridDrugInfoMinHeight;
  @include truncate-text();
}

.grid__row.grid__row--drug-info > .drug {
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: rem(16px);
}

//--------------------------------------
// Border colors for Selected Drugs
//--------------------------------------

.grid__scenario-row {
  $scenarioBorderColor: $dusty-gray;
  .grid__row.grid__row--selected.grid__row--body {
    .grid__cell--border-v, .grid__column--empty, .grid__cell--border {
      border-top-color: $scenarioBorderColor;
      border-bottom-color: $scenarioBorderColor;
    }

    .grid__column--fixed-width .grid__cell--border-left {
      border-left-color: $scenarioBorderColor;
    }

    .grid__column:last-child .grid__cell:last-child.grid__cell--border {
      border-right-color: $scenarioBorderColor;
    }
  }
}

.grid__ndc-row {
  $ndcBorderColor: $light-wisteria;
  .grid__row.grid__row--body.grid__row--selected, .grid__row.grid__row--body-ndc.grid__row--selected {
    .grid__cell--border-v, .grid__column--empty, .grid__cell--border {
      border-top-color: $ndcBorderColor;
      border-bottom-color: $ndcBorderColor;
    }

    .grid__column--ndc-name .grid__cell--border {
      border-left-color: $ndcBorderColor;
    }

    .grid__column:last-child .grid__cell:last-child.grid__cell--border {
      border-right-color: $ndcBorderColor;
    }
  }
}

//--------------------------------------
// Overrides styles for dismissed drugs
//--------------------------------------
.grid__scenario-row--dismissed,
.grid__ndc-row--dismissed {

  .grid__cell {
    background-color: $alto !important;
  }

  .grid__column--empty {
    background-color: $silver !important;
  }

  .grid__cell,
  .term__input,
  .at-ndc,
  .ndc-header,
  .mat-icon-button,
  .term,
  .term__validation--error {
    color: $medium-grey !important;
  }

  .scenario-title {
    background-color: $medium-grey !important;
  }

  .mat-icon {
    @include colorizeIcon($medium-grey);
    @include colorizeIconWithBackground($white-lilac, $medium-grey);
  }
}

.grid__row {
  &--dismissed {
    color: $medium-grey;

    .wac__item,
    .wac__value--ndc,
    .at-ndc {
      color: $medium-grey !important;
    }
  }
}

@import "../shared/mixins";
@import "../themes/new-ui";

.term {
  &__input-wrapper {
    height: rem(40px);
    width: rem(98px);

    .term__field {
      border: rem(1px) solid $alto;
      padding: 0 rem(28px) 0 rem(16px);
    }
  }

  &__input {
    display: flex;
  }

  &-date-picker {
    &__field {
      border: rem(1px) solid $alto;
    }
  }

  &__value {
    align-self: center;

    &--empty {
      flex: 1 1 auto;

      &:after {
        content: "–";
      }
    }
  }
}

.term-notes,
.term-um-details {
  display: flex;

  .qv-button {
    &--at-ndc {
      width: auto;

      &.mat-icon-button {
        width: auto;

        .mat-button-focus-overlay {
          opacity: 0;
          background-color: transparent;
        }
      }
    }
  }

  .at-ndc {
    padding: 0;
    margin: 0;
    color: $royal-purple;
    font-family: $font-family-default;
    font-weight: 500;
    line-height: 1;
    border: 0;
    outline: none;
    background: none;
    cursor: pointer;
  }
}

/**
 * Internal Bid Styles
 */
internal-payer-picker, .internal-payer-picker {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  overflow-y: auto;
}

.manufacturer-drug-picker {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  overflow-y: auto;
}

.listItem.payerItem {
  min-height: 20px;
}

@import "../../styles/themes/new-ui";
@import "../../styles/shared/mixins";

.qv-sidebar-section {
  margin-bottom: rem(56px);

  &:last-of-type {
    margin-bottom: 0;
  }

  .section-toolbar {
    display: flex;
    margin-bottom: rem(16px);
    padding-right: rem(16px);
    justify-content: space-between;
    align-items: center;

    &__title {
      display: inline-flex;
      align-items: center;

      h2 {
        font-family: $font-family-default;
        font-size: 18px;
        font-weight: bold;
        line-height: normal;
        margin: 0 rem(16px) 0 0;
        color: $genoa;
      }

      .mat-icon {
        display: inline-flex;
        width: rem(16px);
        height: rem(16px);
        color: $silver;

        &:hover {
          color: $tundora;
        }
      }
    }
  }

  .row-items {
    margin-top: rem(7px);

    & > *:not(:first-child) {
      margin-top: rem(12px);
    }

    .row-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: rem(8px) rem(8px) rem(8px) rem(12px);
      border-radius: 2px;
      background-color: $isabelline;
      font-family: $font-family-default;
      font-size: 14px;
      line-height: normal;
      color: $tundora;

      &:hover {
        background-color: $white-lilac;
      }

      &__content {
        overflow: hidden;
        p {
          margin: rem(8px) 0 0 0;
        }
      }

      &__title {
        display: block;
        margin: 0;
        font-weight: bold;
        text-decoration: none;
        color: $tundora;
        @include truncate-text();

        &:hover {
          color: $royal-purple;
        }
      }

      &__actions {
        display: flex;

        .mat-icon-button {
          width: rem(24px);
          height: rem(24px);
          line-height: 24px;

          .mat-icon {
            display: inline-flex;
            color: $medium-grey;

            &:hover {
              color: $royal-purple;
            }
          }
        }
      }
    }
  }

  &__no-data {
    font-family: $font-family-default;
    font-size: 14px;
    color: $medium-grey;
    padding-left: rem(16px);
  }

  .term-date-picker {
    height: rem(34px);
    width: rem(141px);
  }

  .add-link-button, .delete-link-button, .edit-link-button {
    //TODO check if this needed after updating angular material to version higher then 8.1.3
    .mat-ripple {
      display: none;
    }

    &:hover {
      .mat-icon {
        @include colorizeIcon($royal-purple);
      }
    }

    .mat-icon {
      @include colorizeIcon($medium-grey);
    }
  }

  .add-link-button {
    .mat-button-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .mat-button-focus-overlay {
      background-color: transparent;
    }
  }
}

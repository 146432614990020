.table {
  th,
  td {
    padding: 10px;
    vertical-align: bottom;
    border-top: 0;
    font-weight: $medium-bolded-font-weight;
    font-size: inherit;
    color: $gray-dark;
  }
  th {
    font-size: 12px;
    font-weight: $bolded-font-weight;
    color: $white;
  }

  td {
    padding-top: 13px;
    padding-bottom: 13px;
  }

  // Bottom align for column headings
  thead th {
    vertical-align: middle;
    background-color: $slate-blue;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

//
// Condensed table w/ half padding
//

.table-sm {
  th,
  td {
    padding: $table-sm-cell-padding;
    height: 100%;
  }
}

.table-bordered {
  th,
  td {
    border: none;
    border-left: $table-border-width solid $table-border-color;
  }
}

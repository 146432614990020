@import "../themes/new-ui";
@import "../shared/mixins";

.range-form {
  display: flex;

  & > * {
    display: inline-flex;
    align-items: center;
    height: rem(32px);
  }

  .input {
    position: relative;
    font-family: $font-family-default;
    font-size: $base-font-size;
    color: $medium-grey;

    &__field {
      height: 100%;
      border: rem(1px) solid $alto;
      font-size: rem(14px);
      color: $cape-code;
      caret-color: $royal-purple;
      outline: none;
      @include placeholder {
        color: $silver;
      }

      &--no-suffix {
        padding: 0 rem(16px);
      }

      &--error {
        background-color: $pink-lady;
      }

      &:hover {
        border-color: $tundora;
      }

      &:focus {
        border-color: $royal-purple;
      }
    }

    &__suffix {
      position: absolute;
      top: 50%;
      right: rem(8px);
      transform: translateY(-50%);
    }

    &__prefix {
      position: absolute;
      top: 50%;
      left: rem(4px);
      transform: translateY(-50%);
    }
  }

  .separator {
    margin: 0 rem(4px);
    color: $silver;
    font-size: 11px;
  }

  .qv-button {
    margin-left: rem(8px);
    padding: 0 rem(12px);
    min-width: auto;
  }
}

.mat-menu-panel.range-filter-menu {
  max-width: initial;
}

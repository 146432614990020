@import "../themes/new-ui";
@import "../shared/mixins";

.qv-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: rem(24px);
  min-height: rem(24px);
  padding-top: rem(2px);
  color: $white;
  background: $eastern-blue;
  font-size: rem(14px);
  font-weight: 500;
  border-radius: 50%;
}
//
// FORMS
// --------------------------------------------------
.uneditable-input,
input[type=color],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
textarea {
  &:not(.qv-input) {
    background-color: $white;
    border: 1px solid #989898;
    color: $black;
  }
}

.uneditable-input,
input[type=color],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
select,
textarea {
  &:not(.qv-input) {
    display: inline-block;
    height: 28px;
    padding: 3px 2px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    color: $black;
    border-radius: 2px;
    vertical-align: middle;
    width: auto;
    @include placeholder {
      color: $gray-light;
    }
  }
}

.uneditable-input,
input[type=color],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
select {
  &:not(.qv-input) {
    height: 1.75rem;
  }

}

select[multiple] {
  height: auto;
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  &:not(.qv-input) {
    cursor: not-allowed;
    background-color: $white;

    &.dateinput {
      border-color: $input-border-color;
    }
  }
}
select {
  max-width: 180px;
  background-color: $custom-select-bg;
}

.dateinput {
  max-width: 90px;
}

// Block level inputs

.input-append {
  .input-field,
  .input-medium,
  .search-input,
  .search-input-large {
    border-radius: 0;
    border-width: 1px 0 1px 1px !important;
  }
}

.input-block-level {
  display: block !important;
  width: 100% !important;
  max-width: initial !important;
}

.input-mini {
  width: 70px!important;
  min-width: 70px !important;
  max-width: 70px!important;
}

.input-auto {
  width: auto!important;
  max-width: 220px!important;
}

.input-small {
  width: 90px!important;
  min-width: 90px !important;
  max-width: 90px!important;
}

.input-medium {
  width: 150px!important;
  max-width: 150px!important;
}

.input-large {
  width: 210px!important;
  max-width: 210px!important;
}

.input-xlarge {
  width: 270px!important;
  max-width: 270px!important;
}

.input-xxlarge {
  width: 530px!important;
  max-width: 530px!important;
}

.search-input {
  width: 240px!important;
  max-width: 240px!important;
}

.search-input-large {
  width: 300px!important;
  max-width: 300px!important;
}

.appended-suffix {
  background-color: #ffffff;
  border: 1px solid $input-border-color;
}

.form-control {
  padding: 0.185rem 0.125rem;
}

// ----------------------
//       Datepicker
// ----------------------
.dateterm {
  .input-group-addon {
    padding: 0 0.3rem;
  }
}

date-picker {
  .btn-secondary {
    background-color: $white;
    border-color: $white;
  }
  .btn-link {
    color: $theme-color;
    &:hover, &:active, &:focus {
      color: $theme-color-hover;
    }

  }
  .text-info{
    color: $theme-color-hover !important;
  }
}

#contact-feedback-text {
  height: auto;
}

// Set lighter gray fo placeholder text
input.form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: $gray;
}
input.form-control:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: $gray;
}
input.form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: $gray;
}
input.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $gray;
}
input.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: $gray;
}

.input-group {
  &-nowrap {
    flex-wrap: nowrap;
  }
}

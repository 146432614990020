$package-list-border-color: #D0D0D0;
$tab-list__item-color: #6b6b6b;
$tab-list__item-color--active: #4E2679;

#productEnrollmentPage {
  .package-list {
    border-bottom: 2px solid $package-list-border-color;
    margin-bottom: 20px;
    display: block;
  }

  .tab-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    .tab-list__item {
      font-size: 16px;
      padding: 1px 10px;
      cursor: pointer;
      color: $tab-list__item-color;
      &--active {
        color: $tab-list__item-color--active;
        border-bottom: 2px solid;
      }
    }
  }
}

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~sassdash";
@import "~sass-svg/index";
@import "~ngx-toastr/toastr.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "shared/functions.scss";
@import "shared/mixins.scss";
@import "themes/new-ui.scss";

@import "../../../dist/angular-components-common/assets/fonts/icomoon/style.css";
@import "../../../dist/angular-components-common/assets/scss/overrides/button";
@import "../../../dist/angular-components-common/assets/scss/overrides/ng-select";
@import "../../../dist/angular-components-common/assets/scss/overrides/checkbox";
@import "../../../dist/angular-components-common/assets/scss/overrides/spinner";
@import "../../../dist/market-basket/assets/scss/index";

@import '../../../dist/quantuvis-angular-common/assets/scss/toastr';
@import '../../../dist/quantuvis-angular-common/assets/scss/modal-dialog';
@import '../../../dist/quantuvis-angular-common/assets/scss/paginator';

@import "overrides/buttons";
@import "overrides/checkbox";
@import "overrides/datepicker";
@import "overrides/dropdown";
@import "overrides/file-drop-zone";
@import "overrides/menu";
@import "overrides/tabs";
@import "overrides/tooltip";
@import "overrides/checkbox";
@import "overrides/expansion-form-panel";
@import "overrides/pdf-viewer";
@import "overrides/popover";
@import "overrides/select";
@import "overrides/slide-toggle";
@import "overrides/radio-button";
@import "overrides/tinymce";
@import "overrides/input";
@import "overrides/snack-bar";
@import "overrides/html-editor";
@import "overrides/button-toggle-group";
@import "overrides/paginator";

@import "overrides/reset.scss";

@import "qv/icons";
@import "qv/breadcrumbs";
@import "qv/drug-add-modal";
@import "qv/cb-import-modal";
@import "qv/toolbar";
@import "qv/helpers";
@import "qv/sidebar";
@import "qv/table";
@import "qv/grid";
@import "qv/grid-term";
@import "qv/grid-scenario-placeholder";
@import "qv/standard-view";
@import "qv/market-basket-modal";
@import "qv/delete-drug-modal";
@import "qv/market-share";
@import "qv/term";
@import "qv/authentication";
@import "qv/bid-list-table";
@import "qv/range-filter";
@import "qv/counter";
@import "qv/wizard";
@import "qv/internal-fields";
@import "qv/list";

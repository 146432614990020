@import "../../styles/themes/new-ui";
@import "../../styles/shared/mixins.scss";

.bid-breadcrumbs {
  display: inline-flex;
  align-items: center;

  & > * {
    font-family: $font-family-default-condensed;
    @include fontSize(18px);
    font-weight: normal;
    font-style: normal;
    font-stretch: condensed;
    line-height: normal;
    letter-spacing: normal;
    color: $scorpion;

    &:last-child {
      @include fontSize(22px);
      font-weight: bold;
      color: $meteorite;
    }

    &:not(:first-child)::before {
      content: url("/assets/images/next.svg");
      display: inline-block;
      padding: 0 rem(6px);
    }
  }

  a {
    &:hover {
      color: $meteorite;
      text-decoration: none;
    }
  }

  .bid-title {
    @include truncate-text();
    max-width : 539px;

    @include breakpoint-up($desktop-md-plus) {
      max-width: 280px;
    }
  }
}

@import "../themes/new-ui";

.ng2-pdf-viewer-container {
  border: rem(24px) solid $white-smoke;
  overflow-y: auto;

  background-color: $white-smoke;

  .canvasWrapper {
    box-shadow: 0 0 rem(10px) 0 rgba(0, 0, 0, 0.11);
  }
}

h1 { font-size: $font-size-base * 2; line-height: $line-height-base;}
h2 { font-size: $font-size-base * 1.5; line-height: $line-height-base}
h3 { font-size: $font-size-base * 1.125; line-height: $line-height-base }
h4 { font-size: $font-size-base; line-height: $line-height-base * 0.8 }
h5 { font-size: $font-size-base; }
h6 { font-size: $font-size-base * 0.85; }

.bolded {
  font-weight: $bolded-font-weight;
}

.mediumBold {
  font-weight: $medium-bolded-font-weight;
}

.heavyBold {
  font-weight: bold;
}

.small-label {
  font-size: 1rem;
  font-weight: $bolded-font-weight;
  color: $gray-dark;
}

.section {
  font-weight: $medium-bolded-font-weight;
  font-size: 1.25rem;
  color: $slate-blue;

  span {
    font-size: 1rem;
    margin-left: 1.25rem;
  }
}
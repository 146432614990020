@import "../../styles/shared/mixins";
@import "../../styles/themes/new-ui";

.mat-radio-group {
  .mat-radio-button:not(:first-child) {
    margin-left: rem(56px);
  }
}

.mat-radio-button {
  .mat-radio-outer-circle {
    transform: scale(0.9);
    border-color: $medium-grey;
  }

  .mat-radio-label-content {
    padding-left: rem(12px);
    color: $cape-code;
    font-size: rem(14px);
  }

  .mat-radio-ripple {
    left: calc(50% - #{rem(16px)});
    top: calc(50% -  #{rem(16px)});
    height: rem(32px);
    width: rem(32px);
    border-radius: rem(16px);
  }

  &.mat-accent {
    .mat-radio-ripple {
      .mat-ripple-element {
        background-color: rgba(78, 78, 78, .16) !important;
      }
    }

    .mat-radio-inner-circle {
      background-color: $royal-purple;
    }

    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: $royal-purple;
      }
    }
  }
  &.cdk-focused .mat-ripple-element.mat-radio-persistent-ripple {
    opacity: 0;
  }
}

$paginator-border-color: #e1e1e1;
$paginator-height: 32px;

.qv-paginator {

  &--primary {

    .mat-paginator {
      &-container {
        justify-content: flex-start;
        height: $paginator-height;
        min-height: auto;
        border-top: 1px solid $paginator-border-color;
        box-sizing: border-box;
      }

      &-page-size {
        order: 1;

        &-value {
          display: none;
        }

        &-label {
          font-size: 14px;
        }
      }

      &-range-label {
        display: none;
      }

      &-range-actions {
        height: 100%;

        .mat-icon-button {
          width: $paginator-height;
          line-height: $paginator-height;
        }
      }

      &-icon {
        height: 24px;
      }

      &-navigation {
        &-next,
        &-previous {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }
  }

  &--hidden {
    height: 0;
    overflow: hidden;
  }
}

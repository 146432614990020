@import "../shared/mixins";
@import "../themes/new-ui";

.drug-add-modal {
  .drug-list {
    &__header {
      .mat-sort-header-container {
        display: flex;
        position: relative;
        width: 100%;

        .mat-sort-header-button {
          display: inline-block;
        }
      }
    }

    &__row-parent, &__row-child {
      .mat-checkbox-label {
        font-size: rem(14px);
      }
    }

    &__row-parent {
      .mat-checkbox-label {
        font-weight: 600;
        font-family: $font-family-bold;
      }
    }

    &__row-child {
      .mat-checkbox {
        .mat-checkbox-layout {
          margin-left: rem(16px);
        }
      }
    }

    .mat-tree-node {
      justify-content: space-between;
    }
  }

  .mat-checkbox {
    .mat-checkbox-label {
      margin-left: rem(8px);
      line-height: rem(18px);
    }
    .mat-checkbox-layout {
      margin: 0;
      white-space: normal;
    }
  }

  .qv-modal-dialog-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.qv-html-editor {
  width: 100%;

  editor {
    ol {
      list-style: decimal;
    }

    ul {
      list-style: disc;
    }

    ol, ul, dl {
      padding-left: 2rem;
    }
  }
}

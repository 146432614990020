@import "../themes/new-ui";
@import "../shared/mixins";

.qv-scrollable-inside-menu-backdrop + * {
  .cdk-overlay-pane {
    margin: -8px 0;
  }

  .mat-menu-panel {
    overflow: visible;
  }

  .mat-menu-content,
  .mat-menu-content > div {
    height: 100%;
    box-sizing: border-box;
  }
}

.mat-menu-panel.qv-menu {
  max-width: rem(400px);
  min-height: 100%;
  overflow: hidden;

  .mat-menu-content,
  .mat-menu-content > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &--288 {
    .mat-menu-content {
      width: 288px;
      padding: 16px 0 0;
    }
  }

  &__item {
    &--active {
      color: $mine-shaft;
      background-color: #ebf5ff;
      font-weight: 600;
    }
  }
}

.actions-menu {
  .cdk-program-focused:not(:hover), .cdk-focused:not(:hover) {
    background-color: transparent;
  }
}

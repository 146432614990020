@import "../themes/new-ui";
@import "../shared/mixins";

.qv-table {
  font-family: $font-family-default;
  font-size: rem(14px);
  color: $mine-shaft;

  &__body {
    display: flex;
    flex-direction: column;

    &--striped {
      background-color: $white;

      & > :nth-child(odd) {
        background-color: $white-smoke;
      }
    }
  }

  &__row {
    position: relative;
    display: flex;
    align-items: center;
    padding: rem(12px) rem(16px);

    &--header {
      display: flex;
      height: rem(40px);
      margin-bottom: rem(16px);
      padding: rem(12px) rem(16px);
      justify-content: space-between;
      align-items: center;
      background-color: $alto;
      font-weight: bold;
    }
  }

  &__cell {
    display: flex;

    &--col {
      flex-basis: 0;
      flex-grow: 1;
    }

    &--upper {
      text-transform: uppercase;
    }
  }
}

.table-section {
  display: flex;
  flex-direction: column;

  .ng-select {
    width: rem(192px);
  }

  &--striped {
    background-color: $white;

    & > :nth-child(even) {
      background-color: $white-smoke;
    }
  }

  &--two-equal-columns {
    .table-section__cell {
      flex-basis: 50%;
      position: relative;
    }

    .table-section__lock {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__lockable-term-wrapper {
    display: flex;
    flex-basis: 100%;
  }

  &__row {
    display: flex;
    align-items: center;
    padding: rem(12px) rem(16px);

    &--highlighted {
      background-color: $white-smoke
    }

    &--fixed-height {
      height: rem(40px);
    }

    &--align-baseline {
      & > .table-section__cell {
        align-items: baseline;
      }
    }
  }

  &__cell {
    display: flex;

    .table-section__cell.table-section__cell--inline {
      flex: 0 0 auto;
      margin-right: rem(4px);
    }

    &--error {
      .term__field, .ng-select-container {
        background-color: $pink-lady;
      }
    }
  }

  &__suffix {
    align-self: center;
  }

  &__title, &__value, .term__value, .term__suffix {
    @include fontSize(14px);
    text-decoration: none;
    line-height: normal;
  }

  &__title {
    margin-right: em(16px);
    color: $medium-grey;
  }

  &__value, .term__value, .term__suffix {
    color: $tundora;
  }

  &__value {
    display: inline-flex;
    align-items: center;
    flex: 1;

    &--signature {
      @include fontSize(20px);
      font-family: $font-family-signature;
      font-weight: 600;
      max-width: rem(200px);
      display:inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      flex: none;

      &::before {
        content: '';
        display: block;
      }
    }
  }

  .term__value {
    &--empty {
      &:after {
        content: "";
      }
    }
  }

  &__info.mat-icon {
    display: inline-flex;
    width: rem(16px);
    height: rem(16px);
    color: $silver;

    &:hover {
      color: $tundora;
    }
  }

  &__error {
    @include absoluteFormFieldErrors();
  }
}


.multifilter {
    margin-top: 10px;
    display: block;
    font-size: $font-size-base;
    font-weight: 400;
    input {
        width: 100%;
        padding: 5px;
        margin: 0;
    }
    ul {
        margin-left: 0;
        overflow: auto;
    }
    li {
        white-space: nowrap;
    }
}

.multifilterTool {
    margin: 15px 0 15px 0;
    padding: 4px 0 4px 0;
    border-left: 0;
    border-right: 0;
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
}

.multiFilterContent {
    width: 300px;
    ul {
        display: flex;
        flex-direction: column;
        max-height: 150px;
    }
}

// Remove ms-clear icon in search fields because it's event doesn't trigger model update
// https://github.com/angular/angular.js/issues/11193
input[type=search]::-ms-clear {
    display: none;
}
@import "../shared/mixins";
@import "../themes/new-ui";

.grid {
  .term {
    @include flexFullHeight();
    flex-direction: row;
    align-items: center;

    overflow: auto;
    font-family: $font-family-default;
    font-weight: 500;
    color: $tundora;

    &__lock, &__input, &__column-left, &__column-right, &__value, &__select {
      display: flex;
    }

    &--no-layout {
      margin: 0 rem(16px);
    }

    &--inline {
      display: inline-flex;
      flex-wrap: wrap;
      margin: rem(0 0 0 8px);
    }

    &__column-left {
      flex-basis: rem(24px);
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      padding-bottom: rem(4px);
      position: relative;
    }

    &__column-right {
      @include flexFullHeight();
      align-self: stretch;
      justify-content: flex-start;
      margin-right: rem(16px);
    }

    &__side {
      flex: 1;
      display: flex;

      &--right {
        justify-content: flex-end;

        .term {
          &__field, &__input, &__select, &__value, &-date {
            justify-content: flex-end;
            text-align: right;
          }
        }
      }

      &--left {
        justify-content: flex-start;

        .term {
          &__field, &__input, &__select, &__value, &-date {
            justify-content: flex-start;
            text-align: left;
          }
        }
      }

      &--center {
        justify-content: center;

        .term {
          &__field, &__input, &__select, &__value {
            justify-content: center;
          }
        }
      }
    }

    &__input {
      @include flexFullHeight();
      align-items: right;
      align-self: center;
    }

    &__input-wrapper {
      .term__field {
        border: none;
        padding: 0;
      }
    }

    &__lock-icon.mat-icon {
      display: flex;
      width: rem(16px);
      height: rem(16px);
      margin: rem(4px);
    }

    & .mat-button-wrapper, & .mat-icon {
      display: inline-flex;
    }

    &__side--right {
      .term__input-wrapper {
        margin-right: rem(8px);

        .term__field {
          padding: 0 rem(18px) 0 0;
        }
      }
    }
  }

  //--------------
  // Overrides
  //--------------
  .term__input-wrapper,
  .term__field,
  .term__side,
  .term-date-picker,
  .term-date-picker__field,
  .ng-select,
  .ng-select.ng-select-single .ng-select-container {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    border: none;
    overflow: hidden;
  }

  .grid__column--empty {
    .term--no-layout {
      width: 100%;
      margin: 0;
    }
  }
}

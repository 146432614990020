$table-border-color: #999;
$cell-border-color: #eee;

th {
  font-size: 16px;
  font-weight: 500;

  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.sortable {
    cursor: pointer;

    .sortBlock {
      padding-right: 25px;
      position: relative;
      display: inline-block;

      &:after, &:before {
        content: "";
        border-width: 0 5px 5px;
        border-style: solid;
        border-color: #FFF transparent;
        visibility: visible;
        right: 5px;
        top: 50%;
        position: absolute;
        opacity: .3;
        margin-top: -6px;
      }
      &:before {
        margin-top: 1px;
        border-bottom: none;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #FFF;
      }
    }

    div:hover:after, div:hover:before {
      opacity: 1;
      visibility: visible;
    }
    &.sort-desc, &.sort-asc {

      div:after {
        margin-top: -2px;
      }
      div:before {
        visibility: hidden;
      }
    }
    &.sort-asc div:after, &.sort-asc div:hover:after {
      visibility: visible;
    }
    &.sort-desc div:after {
      border-bottom: none;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #FFF;
      visibility: visible;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
  &.filter {

    padding-top: 0;
    padding-bottom: 0;

    .input-filter {
      margin: 0;
      display: block;
      width: 100%;
      min-height: 30px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

.pagination {
  margin-top: 0;
}


.ng-table {


    tr {
        &.selected {
          background-color: $table-row-hover !important;
        }


        &:hover {
          background-color: $table-row-hover !important;
        }

        td {
          padding-top: 5px;
          padding-bottom: 5px;

        }

        &.odd {
          background-color: $table-odd;
        }

        &.even {
          background-color: $table-even;
        }
    }
}

@media only screen and (max-width: 800px) {
    .ng-table-responsive {
        border-bottom: 1px solid $table-border-color;
        tr {
            border-top: 1px solid $table-border-color;
            border-left: 1px solid $table-border-color;
            border-right: 1px solid $table-border-color;
        }
        td:before {
            position: absolute;
            padding: 8px;
            left: 0;
            top: 0;
            width: 50%;
            white-space: nowrap;
            text-align: left;
            font-weight: bold;
        }
        thead {
            tr {
                th {
                    text-align: left;
                }
                &.ng-table-filters {
                    th {
                        padding: 0;

                        form > div {
                            padding: 8px;
                        }
                    }
                }
            }
        }
        td {
            border: none;
            border-bottom: 1px solid $cell-border-color;
            position: relative;
            padding-left: 50%;
            white-space: normal;
            text-align: left;

            &:before {
                content: attr(data-title-text);
            }
        }
        &, thead, tbody, th, td, tr {
            display: block;
        }
    }

    .ng-table-pager {}
    .ng-table-pagination {}
    .ng-table-counts {}
}

.inner-title-larger {
  float: left;
  font-size: 18px;
  color: $gray-dark;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 3px;
}
.table td.text-center{
  text-align: center;
}
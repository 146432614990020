@import "../shared/mixins";
@import "../themes/new-ui";

button.mat-menu-item {
  @include fontSize(14px);
  line-height: 30px;
  height: auto;
}

.mat-menu-item-highlighted:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item:hover:not([disabled]) {
  background: $main-theme-color-light;
}

.qv-menu-line .mat-menu-content > .mat-menu-item:not(.mat-menu-item-submenu-trigger),
.qv-menu-line .mat-menu-content qv-menu-item-button > .mat-menu-item:not(.mat-menu-item-submenu-trigger) {
    &.mat-menu-item-highlighted .mat-menu-ripple, &.mat-menu-item:hover .mat-menu-ripple {
      border-left: 4px solid $main-theme-color-dark;
      transition: border-left-width 250ms;
    }
}

.mat-divider.profile-divider {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

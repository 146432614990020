@import "../shared/mixins";
@import "../themes/new-ui";

.qv-modal-dialog.delete-drug-modal {

  tbody {

    &::before,
    &::after {
      content: "";
      display: block;
      height: rem(20px);
    }
  }

  .qv-modal-dialog-actions {
    padding: rem(28px) rem(32px);
  }

  .qv-modal-dialog-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: calc(100vh - 370px);
    max-height: rem(580px);
    padding: rem(32px) rem(32px) 0;

    &__data {
      flex: 1;
      max-height: inherit;
      overflow-x: hidden;
      overflow-y: hidden;

      .mat-sort-header-container {
        .mat-sort-header-button {
          text-align: left;
        }
      }
    }
  }

  .qv-mat-checkbox {
    .mat-checkbox-layout {
      margin-bottom: 0;
    }

    .mat-checkbox-label {
      font-size: rem(14px);
    }
  }
}


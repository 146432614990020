@import "mixins";

$modal-font-family: "Roboto", sans-serif;
$modal-title-color: #4f2480;
$modal-content-color: #4e4e4e;
$modal-info-color: #4e4e4e;
$modal-info-color-medium-grey: #8a8a8a;
$modal-error-color: #e22500;
$modal-primary-border-color: #efefef;
$modal-secondary-border-color: #dcdcdc;

$modal-xbutton-color: #b8b8b8;
$modal-xbutton-hover-color: #969696;
$modal-xbutton-active-color: #4e297d;

$modal-item-bg: #7b4aba;
$modal-item-color: rgba(0, 0, 0, 0.6);
$modal-item-disabled-bg: #efefef;
$modal-item-disabled-color: #969696;
$modal-item-primary-bg: #4f2480;
$modal-item-primary-color: #ffffff;

$modal-field-focus-border-color: #623b94;
$modal-field-hover-border-color: #4e4e4e;
$modal-field-placeholder-color: #b8b8b8;
$modal-label-hint-color: #b8b8b8;

$modal-actions-bg: #ffffff;

.qv-modal-dialog-wrapper .mat-dialog-container {
  padding: 0 !important;

  .qv-modal-dialog {
    &-title {
      position: relative;
      display: flex;
      margin: 0;
      height: 40px;
      border-bottom: 1px solid $modal-primary-border-color;
      align-items: center;
      justify-content: center;

      &--no-margin {
        margin: 0;
      }

      &__text {
        padding: 10px;
        margin: 0;
        color: $modal-content-color;
        font-family: $modal-font-family;
        font-size: 1rem;
        letter-spacing: 0.5px;
        font-weight: normal;
      }

      &__xbutton {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        width: 3rem;
        height: 100%;
        outline: none;
        color: $modal-xbutton-color;
        background: none;
        border: 0;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          color: $modal-xbutton-hover-color;
        }

        &:active {
          color: $modal-xbutton-active-color;
        }

        svg {
          vertical-align: initial;
          path {
            fill: currentColor;
          }
        }
      }
    }

    &-content {
      margin: 0;
      padding: 0 16px;
      font-family: $modal-font-family;
      font-size: 0.875rem;
      font-weight: 500;
      color: $modal-content-color;

      &--primary-padding {
        padding: 24px 16px;
      }

      &--padding-top-32 {
        padding-top: 32px;
      }

      &--padding-bottom-32 {
        padding-top: 32px;
      }

      &--padding-horizontal-32 {
        padding-right: 32px;
        padding-left: 32px;
      }

      &--padding-vertical-32 {
        padding-top: 32px;
        padding-bottom: 32px;
      }

      &--padding-horizontal-24 {
        padding-right: 24px;
        padding-left: 24px;
      }

      &--padding-vertical-24 {
        padding-top: 24px;
        padding-bottom: 24px;
      }

      &--no-padding {
        padding: 0;
      }

      &--scrollable {
        height: calc(100vh - 185px);
        overflow-y: auto;
        max-height: 680px;
        min-height: 200px;
      }

      &--border-bottom {
        border-bottom: 1px solid $modal-secondary-border-color;
      }

      &__data {
        max-height: 220px;
        border: 1px solid $modal-primary-border-color;
        overflow-y: auto;
      }

      &__message {
        padding: 24px 8px 24px 0;
        font-family: Roboto, "sans-serif";
        font-size: 16px;
        font-weight: normal;
        line-height: 1.5;
        letter-spacing: 0.5px;
        color: $modal-content-color;
      }

      &--font-light {
        font-weight: normal;
      }

      &__label {
        display: flex;
        color: $modal-content-color;
        font-family: $modal-font-family;
        font-size: 14px;
        font-weight: 400;
        justify-content: space-between;
        margin-bottom: 8px;

        &-text {
          &--required {
            &:after {
              margin-left: 3px;
              color: $modal-error-color;
              content: "*";
            }
          }
        }

        &-hint {
          color: $modal-label-hint-color;
          font-size: 12px;
          padding-top: 3px;
        }
      }

      &__field {
        font-family: $modal-font-family;
        font-size: 14px;
        line-height: 14px;
        border: solid 1px $modal-secondary-border-color;
        outline: none;
        padding: 10px;

        &.ng-invalid.ng-dirty {
          border: solid 1px $modal-error-color;
        }

        @include placeholder {
          color: $modal-field-placeholder-color;
        }
        &:focus, &:active, &:focus:hover, &:active:hover {
          border: solid 1px $modal-field-focus-border-color;
        }
        &:hover {
          border: solid 1px $modal-field-hover-border-color;
        }
      }

      &--height-xl {
        max-height: 75vh;
      }
    }

    &-actions {
      display: flex;
      padding: 16px;
      min-height: auto;
      margin: 0;
      justify-content: flex-end;
      background: $modal-actions-bg;

      &--border-top {
        border-top: 1px solid $modal-primary-border-color;
      }

      &--two-column {
        justify-content: space-between;
        align-items: center;
      }

      &__left-block {
        flex: 1 1;
      }

      &__right-block {
        flex: 0 0 auto;
      }

      &__info {
        max-width: 280px;
        font-family: $modal-font-family;
        font-size: 14px;
        letter-spacing: 0.1px;
        color: $modal-info-color;

        &--full-width {
          max-width: initial;
        }

        .mat-checkbox-frame {
          border-color: $modal-info-color-medium-grey;
        }

        &--checkbox {
          font-size: 0;

          .qv-checkbox {
            margin-right: 10px;
          }
        }

        &--flex-align-center {
          display: flex;
          align-items: center;
          max-width: none;
        }

        &--message-tooltip {
          .mat-icon {
            font-size: 22px;
            height: 22px;
            width: 22px;
            margin-left: 8px;
            color: $modal-info-color-medium-grey;
          }
        }
      }

      &__info-text {
        font-size: 14px;
      }

      &__item {
        display: inline-flex;
        height: 32px;
        margin-left: 12px;
        min-width: auto;
        padding: 0 8px;
        align-items: center;
        letter-spacing: 0.4px;
        font-size: 14px;
        font-weight: 500;
        font-family: $modal-font-family;
        color: $modal-item-color;
        background-color: inherit;

        .mat-button-wrapper {
          line-height: 16px;
        }

        &:hover {
          background-color: $modal-secondary-border-color;
        }

        &--primary {
          min-width: 56px;
          align-items: center;
          justify-content: center;
          background-color: $modal-item-primary-bg;
          color: $modal-item-primary-color;

          &:hover:not(:disabled) {
            background-color: $modal-item-bg;
            color: $modal-item-primary-color;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
          }

          &:active:not(:disabled) {
            background-color: $modal-item-bg;
            color: $modal-item-primary-color;
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);
          }

          &:disabled {
            background-color: $modal-item-disabled-bg;
            color: $modal-item-disabled-color;
          }

          .mat-button-focus-overlay {
            background-color: transparent;
          }
        }
      }

      &--padding-sm {
        padding-top: 18px;
      }
    }

    .actions-bar {
      height: 16px;
      margin: 8px 0;
      text-align: right;
      flex: 0 0 16px;

      & > * {
        padding: 0 0 0 16px;
      }

      &__item {
        display: inline-block;
      }
    }
  }
}


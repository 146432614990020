@import "../../../assets/styles/shared/mixins";

snack-bar-container.mat-snack-bar-container {
  background-color: transparent;
  padding: 0;
  min-width: rem(144px);
  margin-bottom: rem(16px);
  min-height: 0;

  &--bottom-center {
    max-width: 100vw;
    margin-bottom: rem(56px);
  }

  .snack-bar {
    &__icon {
      &--pending {
        padding: 0;
        border: 0;

        &:before {
          content: url("/assets/images/spinner-circle.svg");
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

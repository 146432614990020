//Fix of transparent scrollbars in IE/Edge
body {
  -ms-overflow-style: scrollbar;
}

label {
  font-size: 1rem;
}

ol,
ul,
dl {
  padding-left: 0;
  list-style: none;
}

button,
optgroup,
select,
textarea {
  font-family: $font-family-base;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

//----------------------------
// Content alignment
//----------------------------

.align-content, dashboard-component {
  display: flex;

  &.center {
    align-items: center;
  }
}

//----------------------------
// Text alignment
//----------------------------
.align {

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.middle {
    vertical-align: middle;
  }

  &.top {
    vertical-align: top !important;
  }

}

.text-narrow {
  word-break: break-all;
  word-wrap: break-word;
}

.min-width {
    display: inline-block;

    &.width40 {
        min-width: 40px;
    }

    &.width50 {
        min-width: 50px;
    }

    &.width60 {
        min-width: 60px;
    }

    &.width80 {
        min-width: 80px;
    }

    &.width160 {
        min-width: 160px;
    }

    &.width200 {
        min-width: 160px;
    }
}

//-------------------------
// No margin/paddings/borders defs
//-------------------------
.no {
  &.top-margin {
    margin-top: 0 !important;
  }

  &.bottom-margin {
    margin-bottom: 0 !important;
  }

  &.left-margin {
    margin-left: 0 !important;
  }

  &.right-margin {
    margin-right: 0 !important;
  }

  &.top-padding {
    padding-top: 0 !important;
  }

  &.bottom-padding {
    padding-bottom: 0 !important;
  }

  &.left-padding {
    padding-left: 0 !important;
  }

  &.right-padding {
    padding-right: 0 !important;
  }

  &.padding {
    padding: 0 !important;
  }

  &.margin {
    margin: 0 !important;
  }

  &.border {
    border: none !important;
  }
  &.border-left {
    border-left: none !important;
  }
  &.border-right {
    border-right: none !important;
  }
  &.border-bottom {
    border-bottom: none !important;
  }
  &.border-top {
    border-top: none !important;
  }
}

.purple {
  color: $theme-color;
}

.darkergray {
  color: $gray-darker;
}

.textnoresize {
  resize: none;
}

.spacer {

  &.all-margin {
    margin: $spacer-size;
  }

  &.left-margin {
    margin-left: $spacer-size;
  }

  &.double-left-margin {
    margin-left: $spacer-size*2;
  }
  &.double-right-margin {
    margin-right: $spacer-size*2;
  }

  &.right-margin {
    margin-right: $spacer-size;
  }

  &.top-margin {
    margin-top: $spacer-size;
  }

  &.double-top-margin {
    margin-top: $spacer-size*2;
  }

  &.bottom-margin {
    margin-bottom: $spacer-size;
  }

  &.all-padding {
    padding: $spacer-size;
  }

  &.left-padding {
    padding-left: $spacer-size;
  }

  &.right-padding {
    padding-right: $spacer-size;
  }

  &.top-padding {
    padding-top: $spacer-size;
  }

  &.bottom-padding {
    padding-bottom: $spacer-size;
  }

}

.bgcolor {
  &purple {
    background-color: $theme-color
  }
}

//------------------
// Text colouring
//------------------
.color {

  &.bright-red {
    color: $btn-delete-background-highlight !important;
  }

  &.orange {
    color: $warning-color !important;
  }

  &.red {
    color: $red !important;
  }

  &.black {
    color: $black !important;
  }

  &.gray {
    color: $gray;
  }

  &.white {
    color: $white;
  }

  &.green {
    color: $green !important;
  }

  &.purple {
    color: $theme-color !important;
  }

}

//------------------
// Background colouring
//------------------
.background-color- {

  &black {
    background-color: $black !important;
  }

  &gray {
    background-color: $gray;
  }

  &white {
    background-color: $white;
  }

}

.decoration {

  &.none {
    text-decoration: none;
  }

  &.through {
    text-decoration: line-through;
  }

}

.link-item-nocolor {
  color: $teal;
  display: inline-block;

  &:hover {
    cursor: pointer;
    color: $white;
    background-color: $teal;
  }
}

.link-item {
  color: $teal;
  background-color: $white;
  display: inline-block;

  &.block {
    display: block;
  }

  &:hover {
    cursor: pointer;
    color: $white;
    background-color: $teal;
  }

  &:active {
    cursor: pointer;
    color: $black;
    background-color: $gray-light;
  }

}

.link-download, .router-link {
  color: $teal;

  &:hover {
    cursor: pointer;
    color: $link-hover-color;
    text-decoration: underline;
  }
}

.inliner {
  display: inline-block !important;
}

.filler {
  display: block !important;
}

.baseFontSize {
  font-size: $font-size-base;
}

.largerFontSize {
  font-size: $font-size-larger;
}

.largeFontSize {
  font-size: $font-size-large;
}

.border {
  border: none !important;

  &.bottom {
    padding-bottom: 4px;
    border-bottom: 1px solid $gray-light !important;
  }

  &.top {
    padding-top: 4px;
    border-top: 1px solid $gray-light !important;
  }
}

.font-size-mini {
  font-size: $font-size-mini;
}

.medium-grey {
  color: $medium-grey;
}

.cust-sprite:hover {
  cursor: pointer;
}

.pull- {
  &up {
    &.px-50 {
      margin-top: -50px;
    }
  }
  &down {
    &.px-50 {
      margin-top: 50px;
    }
  }
}

.clickable {

  &:hover {
    cursor: pointer;
  }

}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.reset {
    clear:both;
}

.positioner {

    &.relative {
        position: relative;
    }

    &.absolute {
        position: absolute;
    }

}

.font {

  &.bolded {
    font-weight: $bolded-font-weight;
  }

  &.strong-bolded {
    font-weight: $strong-bolded-font-weight;
  }
}

.cursor {

  &.default {
    cursor: default !important;
  }
}


.overflow {

    &.horizontal-auto {
        overflow-x: auto;
    }

    &.vertical-auto {
        overflow-x: auto;
    }

    &.auto {
        overflow: auto;
    }

}

.fa-copy {
  background-position: -1px -166px;
}

.qv-icon-no-edit {
  display: inline-flex;
  background-image: url('/assets/css/images/no_edit_256.png');
}

.qv-icon-send {
  background: url('/assets/css/images/icon-send.svg') 0 0;
  background-size: contain;
}

.qv-icon-info {
  background: url('/assets/css/images/icon-info.svg') 0 0;
  background-size: contain;
}

a:hover > .qv-icon-send, .disabled .qv-icon-send {
  background-image: url('/assets/css/images/icon-send-hover.svg') !important;
}

.qv-icon-large {
  height: 15px;
  width: 20px;
  transform: scale(1.6);
  vertical-align: middle;
}

.label-accept {
  background-color: #0bf316;
}

.non-collapsible:after {
  content: '';
  display: inline-block;
}

.fa {
  color: $white;
  &.qv-icon-gray {
    color: $gray-dark;
  }

  &.qv-icon-black {
    color: $black;
  }
}

.root-view {
  height: 100%;
  & > *.ng-scope.ng-isolate-scope {
    width: 100%;
  }
}

@import "../../styles/themes/new-ui";

.bid-list-table {
  .mat-checkbox-layout {
    margin: 0;
  }

  .mat-sort-header-stem {
    background: $daisy-bush;
  }

  .mat-sort-header-arrow,
  .mat-sort-header-indicator {
    color: $daisy-bush;
  }
}
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?704yad');
  src:  url('fonts/icomoon.eot?704yad#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?704yad') format('truetype'),
    url('fonts/icomoon.woff?704yad') format('woff'),
    url('fonts/icomoon.svg?704yad#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-assignment-turned-in:before {
  content: "\e971";
}
.icon-autorenew:before {
  content: "\e972";
}
.icon-ia:before {
  content: "\e970";
}
.icon-hexagon-filled-120:before {
  content: "\e96e";
}
.icon-hexagon-filled-360:before {
  content: "\e96d";
}
.icon-hexagon-filled-240:before {
  content: "\e963";
}
.icon-q:before {
  content: "\e964";
}
.icon-sa:before {
  content: "\e966";
}
.icon-rn:before {
  content: "\e967";
}
.icon-cm:before {
  content: "\e968";
}
.icon-iq:before {
  content: "\e965";
}
.icon-note:before {
  content: "\e96c";
}
.icon-preset-icon:before {
  content: "\e962";
  color: #8a8a8a;
}
.icon-add-user-icon:before {
  content: "\e961";
  color: #8a8a8a;
}
.icon-baseline-add-box-24-px:before {
  content: "\e960";
  color: #8a8a8a;
}
.icon-edit2:before {
  content: "\e95f";
  color: #8a8a8a;
}
.icon-quantuvis-logo .path1:before {
  content: "\e95d";
  color: rgb(88, 46, 136);
}
.icon-quantuvis-logo .path2:before {
  content: "\e95e";
  margin-left: -3.2060546875em;
  color: rgb(78, 79, 79);
}
.icon-sa-logo .path1:before {
  content: "\e95b";
  color: rgb(189, 164, 220);
}
.icon-sa-logo .path2:before {
  content: "\e95c";
  margin-left: -1.103515625em;
  color: rgb(98, 59, 148);
}
.icon-send-file:before {
  content: "\e96f";
}
.icon-acrobat-file:before {
  content: "\e900";
}
.icon-actions:before {
  content: "\e901";
}
.icon-add-doc:before {
  content: "\e902";
}
.icon-add-filled:before {
  content: "\e903";
}
.icon-add-note:before {
  content: "\e904";
}
.icon-add-outline:before {
  content: "\e905";
}
.icon-add:before {
  content: "\e906";
}
.icon-alert-filled:before {
  content: "\e907";
}
.icon-alert-outline:before {
  content: "\e908";
}
.icon-archive:before {
  content: "\e909";
}
.icon-arrow-bottom:before {
  content: "\e90a";
}
.icon-arrow-dropdown:before {
  content: "\e90b";
}
.icon-arrow-left:before {
  content: "\e90c";
}
.icon-arrow-right:before {
  content: "\e90d";
}
.icon-arrow-top:before {
  content: "\e90e";
}
.icon-attach-file:before {
  content: "\e90f";
}
.icon-avatar:before {
  content: "\e910";
}
.icon-user:before {
  content: "\e969";
}
.icon-basket:before {
  content: "\e911";
}
.icon-bell:before {
  content: "\e912";
}
.icon-block:before {
  content: "\e913";
}
.icon-calendar:before {
  content: "\e914";
}
.icon-case:before {
  content: "\e915";
}
.icon-check-filled:before {
  content: "\e916";
}
.icon-check-outline:before {
  content: "\e917";
}
.icon-chevron-bottom:before {
  content: "\e918";
}
.icon-chevron-left:before {
  content: "\e919";
}
.icon-chevron-right:before {
  content: "\e91a";
}
.icon-chevron-top:before {
  content: "\e91b";
}
.icon-circle-bottom:before {
  content: "\e91c";
}
.icon-circle-empty:before {
  content: "\e91d";
}
.icon-circle-top:before {
  content: "\e91e";
}
.icon-clear:before {
  content: "\e91f";
}
.icon-circle-clear:before {
  content: "\e96a";
}
.icon-close-filled:before {
  content: "\e920";
}
.icon-close-outline:before {
  content: "\e921";
}
.icon-copy:before {
  content: "\e922";
}
.icon-delete:before {
  content: "\e923";
}
.icon-details:before {
  content: "\e924";
}
.icon-doc-check:before {
  content: "\e925";
}
.icon-done:before {
  content: "\e926";
}
.icon-download:before {
  content: "\e927";
}
.icon-edit:before {
  content: "\e928";
}
.icon-error-color .path1:before {
  content: "\e929";
  color: rgb(226, 37, 0);
}
.icon-error-color .path2:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-error-filled:before {
  content: "\e92b";
}
.icon-error-outline:before {
  content: "\e92c";
}
.icon-excell-file:before {
  content: "\e92d";
}
.icon-filter:before {
  content: "\e92e";
}
.icon-grid:before {
  content: "\e92f";
}
.icon-history-cm:before {
  content: "\e930";
}
.icon-history:before {
  content: "\e931";
}
.icon-image-filled:before {
  content: "\e932";
}
.icon-image-outline:before {
  content: "\e933";
}
.icon-info-filled:before {
  content: "\e934";
}
.icon-info-outline:before {
  content: "\e935";
}
.icon-key:before {
  content: "\e936";
}
.icon-lock:before {
  content: "\e937";
}
.icon-menu:before {
  content: "\e938";
}
.icon-more-horiz:before {
  content: "\e939";
}
.icon-more-vert:before {
  content: "\e93a";
}
.icon-ndc:before {
  content: "\e93b";
}
.icon-notes-cm:before {
  content: "\e93c";
}
.icon-notes:before {
  content: "\e93d";
}
.icon-pill:before {
  content: "\e93e";
}
.icon-print:before {
  content: "\e93f";
}
.icon-redo:before {
  content: "\e940";
}
.icon-remove-filled:before {
  content: "\e941";
}
.icon-remove-outline:before {
  content: "\e942";
}
.icon-remove:before {
  content: "\e943";
}
.icon-reopen:before {
  content: "\e944";
}
.icon-revision-arrow:before {
  content: "\e945";
}
.icon-revision-circle:before {
  content: "\e946";
}
.icon-search:before {
  content: "\e947";
}
.icon-search-left:before {
  content: "\e96b";
}
.icon-send:before {
  content: "\e948";
}
.icon-sign-off-doc:before {
  content: "\e949";
}
.icon-sign-off-done:before {
  content: "\e94a";
}
.icon-sign-off-error:before {
  content: "\e94b";
}
.icon-sign-off-remove:before {
  content: "\e94c";
}
.icon-sign-off-signing:before {
  content: "\e94d";
}
.icon-sort-arrow-bottom:before {
  content: "\e94e";
}
.icon-sort-arrow-top:before {
  content: "\e94f";
}
.icon-tiles:before {
  content: "\e950";
}
.icon-timeline:before {
  content: "\e951";
}
.icon-undo:before {
  content: "\e952";
}
.icon-unlock:before {
  content: "\e953";
}
.icon-upload-doc:before {
  content: "\e954";
}
.icon-upload:before {
  content: "\e955";
}
.icon-view:before {
  content: "\e956";
}
.icon-warning-color .path1:before {
  content: "\e957";
  color: rgb(255, 190, 50);
}
.icon-warning-color .path2:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(78, 78, 78);
}
.icon-zoom-in:before {
  content: "\e959";
}
.icon-zoom-out:before {
  content: "\e95a";
}

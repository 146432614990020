$field-focus-color: #4f2480;

.date-picker {
  &__input {
    align-items: center;
    display: flex;

    &-field {
      width: 168px;
      padding: 0 40px 0 16px;
      background: none;
      pointer-events: none;
    }

    &-prefix {
      margin-left: -40px;

      &--no-ripple {
        &.mat-datepicker-toggle {
          .mat-icon-button:hover {
            .mat-button-focus-overlay {
              background: none;
            }
          }
        }
      }

      &.mat-datepicker-toggle-active {
        color: $field-focus-color;
      }
    }
  }
}

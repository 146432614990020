$field-color: #666666;
$field-border-color: #dcdcdc;
$field-label-color: #666666;
$field-error-color: #e22500;

.form-field {
  width: 100%;

  &__label {
    margin-bottom: 8px;
    font-size: 12px;
    color: $field-label-color;
  }

  &__input {
    border: solid 1px $field-border-color;
    padding: 10px 16px;
    border-radius: 2px;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    line-height: 14px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: $field-color;
    outline: none;
    background-color: white;

    &--error {
      border-color: $field-error-color;
    }
  }
}

@use "sass:math";
@import '~bootstrap/scss/_variables.scss';

//
// Font-Awesome Custom variables
// ---------------------------------------------------------------------
$fa-font-path: "~font-awesome/fonts" !default;

//
// General
// ---------------------------------------------------------------------
$spacer-size:                     15px;
$header-height:                   56px;
$header-border-width:             1px;
$content-margin-top:              32px;
$group-width:                     120px;
$row-min-height:                  70px;
$row-min-height-md:              95px;
$row-small-min-height:            34px;
$row-min-height-lg:               125px;
$cell-width:                      200px;
$border-radius-base:              4px;
$border-radius-large:             6px;
$line-height-computed:            1.5;
$enable-shadows:                  true;
$modal-backdrop-bg:               $white;


//
// Grid
// ---------------------------------------------------------------------
$grid-gutter-width-base: 30px;

//
// Colors
// ---------------------------------------------------------------------
$report-title-color:              #8d8d8d;
$theme-color:                     #4F2480;
$theme-color-hover:               #6620b2;
$theme-color-hit:                 #300064;
$theme-color-lite:                #B4A9CF;
$warning-color:                   #f89406;
$error-color:                     #992626;
$box-shadow-color:                #888888;
$tab-hover:                       #356bba;
$link-hover-color:                #185961;
$background-gray:                 #F2F2F2;

$white:                           #fff;     //Should be named properly
$black:                           #000;     //Should be named properly

$gray:                            #b7b7b7;  //Should be named properly
$gray-silver:                     lighten($gray, 5%);   //Should be named properly
$gray-light:                      lighten($gray, 10%);   //Should be named properly
$gray-lighter:                    lighten($gray, 20%);   //Should be named properly
$gray-dark:                       darken($gray, 30%);   //Should be named properly
$gray-darker:                     darken($gray, 50%);   //Should be named properly
$medium-grey:                     #8A8A8A;  //Should be named properly

$green:                           #46a546;  //Should be named properly
$red:                             #9d261d;  //Should be named properly
$teal:                            #009ca8;  //Should be named properly
$slate-blue:                      #51617C;  //Should be named properly
$ink-blue:                        #013C78;  //Should be named properly
$slate-blue-light:                lighten($slate-blue, 20%);  //Should be named properly
$ocean-teal-lite:                 #BFD2D6;  //Should be named properly
$pinkish:                         #e5c8c8;  //Should be named properly

$backgroundGray:                  #F2F2F2;  //Should be named properly
$backgroundGray2:                 #EAEAEA;  //Should be named properly

$table-odd:                       #f1f1f1;  //Should be named properly
$table-even:                      $white;  //Should be named properly
$table-row-hover:                 #c6d3d4;  //Should be named properly

$jungle-green:                    #30B296;
$upgrade-enterprise-color:        $jungle-green;

//DRUGS TABLE COLORS
$headerCheckbox:                  #A9B0BD;  //Should be named properly
$tableGrayDark:                   darken($gray, 10%);  //Should be named properly
$tableGrayDarker:                 darken($gray, 20%);  //Should be named properly

//Table colors
$checkbox-td:                     #D6D6D6;  //Should be named properly
$ndc-td:                          #F5F5F5;  //Should be named properly
$ndc-group-td:                    #E5E5E5;  //Should be named properly
$tr-hover:                        #d1dedf;  //Should be named properly
$group-section-checked:           #CCEBED;  //Should be named properly
$table-head-color:                $white;
$table-head-bg:                   $slate-blue;

$errorback:                       #f3cccc;  //Should be named properly
$pagination-border:               #ddd;     //Should be named properly
$alert-danger-border:             #f2dede;  //Should be named properly
$alert-danger-bg:                 #eed0d6;  //Should be named properly
$alert-warning-bg:                #fcf8e3;  //Should be named properly
$alert-warning-border:            darken($alert-warning-bg, 3%);  //Should be named properly
$alert-info-bg:                   #d9edf7;  //Should be named properly
$alert-info-border:               darken($alert-info-bg, 7%);  //Should be named properly
$state-info-bg:                   #d9edf7;  //Should be named properly
$state-info-border:               darken($alert-info-bg, 7%);  //Should be named properly

$dismissed-in-review-mode-color:  #e5e5e5;

//
// Buttons
// ---------------------------------------------------------------------
$btn-primary-color:               $white;
$btn-primary-bg:                  $theme-color;
$btn-primary-border:              $theme-color;
$btn-secondary-color:             #333;
$btn-secondary-bg:                #cbcbcb;
$btn-secondary-border:            #cbcbcb;
$btn-border-width:                2px;
$input-btn-border:                $btn-border-width solid $theme-color;
$btn-box-shadow:                  none;

$btn-delete-background:           #cc3333;
$btn-delete-background-highlight: #e30000;
$btn-cancel-background:           #cbcbcb;
$btn-cancel-background-highlight: #dddddd;
$btn-cancel-text-color:           #333333;


//
// Typography
// ---------------------------------------------------------------------
$base-font-size-px:               16;
$font-size-base:                  0.875rem; //14px
$font-size-h1:                    28px;
$headings-font-weight:            300;
$font-family-base:                'Roboto Condensed', 'sans-serif';
$signature-font-family:           'Allura', cursive, "Times New Roman", Times, serif;
$font-size-larger:                #{math.div(18, $base-font-size-px)}rem;
$font-size-large:                 #{math.div(16, $base-font-size-px)}rem;
$font-size-small:                 #{math.div(12, $base-font-size-px)}rem;
$font-size-mini:                  #{math.div(11, $base-font-size-px)}rem;
$medium-bolded-font-weight:       400;
$bolded-font-weight:              500;
$strong-bolded-font-weight:       700;

//
// Forms
// ----------------------------------------------------------------------
$input-border-color:              #989898;
$input-border-radius:             2px;
$cursor-disabled:                 not-allowed !default;

//
// Links
// ----------------------------------------------------------------------
$link-color:                        $teal;
$navbar-light-active-color:         $theme-color;
$navbar-light-hover-color:          $navbar-light-active-color;
$navbar-light-color:                $gray-dark;

//
// Dropdown
// ----------------------------------------------------------------------
$dropdown-border-color:             $slate-blue;
$dropdown-box-shadow:               1px 1px 1px $gray-dark;
$dropdown-link-color:               $gray-dark;
$dropdown-link-active-color:        $white;
$dropdown-link-active-bg:           $theme-color-hover;
$dropdown-link-hover-bg:            $dropdown-link-active-bg;
$dropdown-link-hover-color:         $dropdown-link-active-color;
$dropdown-link-focus-color:         $dropdown-link-active-color;
$dropdown-link-disabled-color:      $gray-light;
$dropdown-min-width:                11rem;

//
// Tooltips
// ----------------------------------------------------------------------
$tooltip-max-width:                 250px;
$tooltip-color:                     $black;
$tooltip-bg:                        $white;
$tooltip-opacity:                   1;
$tooltip-arrow-color:               $slate-blue;

//
// Tables
// ----------------------------------------------------------------------
$table-sm-cell-padding:             4px 5px;
$table-border-width:                1px;
$table-border-color:                $slate-blue;

//
//Devices
//--------------------------
$item-border-color:                 #d1d1d1;
$active-item-color:                 #339bb9;

//
// z-index
//--------------------------
$drug-row-zindex:                       1;
$group-section-checked-nested-zindex:   1;
$lock-unlock-switch-zindex:             1;
$modal-dialog-error-zindex:             10;
$drug-scenario-textarea-zindex:         10;
$drug-scenario-textarea-focus-zindex:   11;
$bid-footer-zindex:                     100;
$inner-drug-zindex:                     101;
$group-section-checked-zindex:          101;
$devices-item-zindex:                   101;
$item-zindex:                           101;
$maximized-zindex:                      700;
$alert-fixed-top-zindex:                800;
$ngdropdown-options-zindex:             890;
$drug-table-tooltip-zindex:             890;
$term-value-popup-zindex:               897;
$sticky-container-zindex:               898;
$contracted-business-tabs-zindex:       899;
$simple-drop-zindex:                    924;
$bid-details-save-zindex:               1000;
$loading-zindex:                        9999;

.ngDropdown {
    position:relative;
    display:inline-block;

    .selected {
       display: block;

       .sel {
         padding: 1px ;
         vertical-align: top;
         display: inline-block;
         min-width: 50px;
       }
    }

    .selector {
        display: none;
        padding: 7px 4px 5px;

        .triangle {
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid $slate-blue;
        }

    }

    &.enabled {
        .selector {
            display: inline-block;
        }

        &:hover {
            color: $slate-blue;
            cursor: pointer;

            .sel {
                background-color: $white;
            }

            .selector {
                background-color: $slate-blue;

                .triangle {
                    border-top: 4px solid $white;
                }
            }
        }
    }

    .options {
        position: absolute;
        display:none;
        background-color: $white;
        top: 0;
        left: 0;
        right: 16px;
        z-index: $ngdropdown-options-zindex;

        &.visible {
            display: block;
        }

        .item {
            display:block;
            background-color: $white;
            padding: 1px;

            &:hover {
                background-color: $slate-blue;
                color: $white;
            }
        }
    }
}

.Required {
    color: $theme-color;
}

.submenu {
  min-height: 0;
  border-bottom: 1px solid $gray-light;
  width: 100%;

  .navbar-nav {
    flex-direction: row;
  }

  .nav-item > .nav-link {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

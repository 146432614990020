@import "../shared/mixins";
@import "../themes/new-ui";

.market-share-range-or-units-and-rebates-value {
  &__action {
    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .mat-icon {
      display: flex;
      width: rem(16px);
      height: rem(16px);
      @include colorizeIcon($silver);
    }
  }
}

.range-rebate--floating {
  .range-rebate-container {
    &__clear {
      .mat-icon {
        color: $tundora;
      }
    }
  }
}

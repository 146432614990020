@import "../shared/mixins";
@import "../themes/new-ui";

$chevron-top-svg: "M7.3,15.3 C7.7,15.7 8.3,15.7 8.7,15.3 L12.6,11.4 L16.5,15.3 C16.9,15.7 17.5,15.7 17.9,15.3 C18.3,14.9 18.3,14.3 17.9,13.9 L13.3,9.3 C12.9,8.9 12.3,8.9 11.9,9.3 L7.3,13.9 C6.9,14.3 6.9,14.9 7.3,15.3 Z";
$chevron-bottom-svg: "M16.9,9.3 C16.5,8.9 15.9,8.9 15.5,9.3 L11.6,13.2 L7.7,9.3 C7.3,8.9 6.7,8.9 6.3,9.3 C5.9,9.7 5.9,10.3 6.3,10.7 L10.9,15.3 C11.3,15.7 11.9,15.7 12.3,15.3 L16.9,10.7 C17.3,10.3 17.3,9.7 16.9,9.3 Z";

.more-horiz {
  @include rotate(90deg);
}

.qv-icon {
  &--center {
    align-self: center;
  }

  @include emptyState();
}

.qv-button-icon {
  &--center {
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  @include emptyState();
  @include fullState();

  .mat-icon {
    display: inline-flex;
  }

  &.mat-icon-button {
    display: flex;
    justify-content: center;
    width: rem(32px);
    height: rem(32px);

    .mat-button-focus-overlay {
      transition: opacity .2s cubic-bezier(.35, 0, .25, 1), background-color .2s cubic-bezier(.35, 0, .25, 1);
    }

    .mat-button-wrapper {
      display: inline-flex;
    }
  }

  &--small.mat-icon-button {
    width: rem(24px);
    height: rem(24px);
  }
}

.mat-icon.validation-icon {
  width: rem(16px);
  height: rem(16px);
  min-width: rem(16px);

  &--warn {
    @include colorizeIconWithBackground($tundora, $sunglow);
  }

  &--error {
    @include colorizeIconWithBackground($white-smoke, $scarlet);
  }

  &--arrow {
    @include colorizeIcon($error-color);
  }

  &--input-prefix {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
}

.term__validation-icon {
  margin: rem(-1px) rem(5px) 0 0;
}

.section-state__icon {
  width: rem(16px);
  height: rem(16px);

  &--warning {
    @include colorizeIconWithBackground($tundora, $sunglow);
  }

  &--error {
    @include colorizeIconWithBackground($white-smoke, $scarlet);
  }

  &--unlock {
    @include colorizeIcon($eastern-blue);
  }

  &--lock {
    @include colorizeIcon($ocean_teal);
  }

  &--ndc {
    @include colorizeIcon($royal-purple);
  }
}

.mat-icon-button .mat-icon, .mat-icon {
  &.qv-icon--16 {
    @include resizeIcon(16px);
  }

  &.qv-icon--18 {
    @include resizeIcon(18px);
  }

  &.qv-icon--20 {
    @include resizeIcon(20px);
  }

  &.qv-icon--22 {
    @include resizeIcon(22px);
  }

  &.qv-icon--24 {
    @include resizeIcon(24px);
  }
}


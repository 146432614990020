@import "../themes/new-ui";

button.qv-button--authentication {
  width: 100%;
  height: rem(40px);
  text-transform: uppercase;
  font-weight: 400;
  font-family: $font-family-default;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: rem(24px);
}

.authentication-link {
  font-family: $font-family-default;
  font-size: rem(14px);
  letter-spacing: rem(0.44px);
  text-decoration: underline;
  color: $purple;
  background: none;
  border: 0;
  padding: 0;

  &:hover, &:active {
    color: $studio;
  }
}

//--------------------------
// Notifications
//--------------------------
@import 'variables.scss';

.notifications {
    position: relative;

    &--scrollable {
        max-height: calc(100vh - #{$header-height} - #{$header-border-width});
        overflow: auto;
        padding-top: 32px;
        margin-top: -32px;
    }

    .bell {
        background: url("/assets/css/images/bell-large.png") no-repeat;
        width: 120px;
        height: 140px;
        display: inline-block;
        vertical-align: middle;
    }

    .message {
        font-weight: $bolded-font-weight;
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        max-width: 270px;
    }

    .section {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .notificationRow {
        padding: 0 15px 5px 15px;

        & > * {
            padding-left: 5px;
            padding-right: 5px;
        }

        .cust-sprite {
            margin-top: -2px;
            min-height: 0;
        }
        .time {
            min-width: 85px;
            max-width: 85px;
        }
        .notifier {
            word-break: break-all;
            word-wrap: break-word;
        }
        .notification-icon {
            max-width: 24px;
            padding: 0;
        }

        .notification-description {
            word-break: break-word;
            word-wrap: break-word;
        }

        &:hover {
            background-color: lighten($table-row-hover, 8%) !important;
            cursor: pointer;
        }

    }

    .time {
        min-width: 40px;
    }
}

//--------------------------
// Dashboard
//--------------------------
.dashboard-widget-left {
    padding-left: 10px;
    padding-right: 5px;
}

.dashboard-widget-right {
    padding-left: 5px;
    padding-right: 10px;
}

.status-graph-section {
  &.widget-frame {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.dashboard_img {
    img {
        width: 100%;
    }
}

//--------------------------
// Bid Revision Comparison
//--------------------------
#bidRevisionComparisonPage {

    table {
        border: 0 none;
        table-layout: fixed;

        .plantype {
            background-color: $slate-blue;
            color: $white;

            &.removed {
                background-color: $red;
            }

            &.added {
                background-color: #007300;
            }

            .name {
                font-weight: bold;
            }

            .attrs {
                font-size: 14px;
                text-align: baseline;
            }
        }
        .comments-section {
            display: block;
            word-wrap: break-word;

            .comments {
                display: block;
                word-wrap: break-word;
            }
            .comments-text {
                white-space: pre-line;
            }
        }

        th {
            background-color: $ocean-teal-lite;
            color: $slate-blue;
            font-size: 16px;
            border-left: 0 none;
            border-bottom: 0 none;

            &.first {
                width: 20%;
            }

            &.second {
                width: 20%;
            }

            &.third {
                width: 30%;
            }

            &.fourth {
                width: 30%;
            }
        }

        tr {
            &.drug {
                background-color: $ndc-td;

                td.first {
                    background-color: $ndc-group-td;
                }
            }

            &.ndc {
                background-color: $white;

                td.first {
                    background-color: $gray-lighter;
                }
            }
        }

        td {
            border-left: 0 none;
            border-bottom: 1px solid $gray-light;
            vertical-align: top;

            &.first {
                color: $black;
                padding-left: $spacer-size;
                border-bottom: 1px solid $gray-light;
            }

            &.second {
                border-right: 1px solid $gray-light;
            }

            &.third {
                border-right: 1px solid $gray-light;
            }

            .old {
                color: $gray-dark;
                white-space: pre-line;
            }

            .new {
                color: $black;
                white-space: pre-line;
            }

        }
    }
}

//--------------------------
// Drug Search
//--------------------------
#drugsearch {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    height: 100%;
    min-height: 0; // fix full height for FF (https://stackoverflow.com/a/44948507)

    tr.trIsNotGroup {
        td.drug-name-column {
            padding-left: 22px;
        }
    }
    td.drug-name-column {
        width: 30%;
    }
    .drugs-table-container {
        overflow-y: auto;
        position: relative;
    }
}

.drug-search-modal {
    #drugsearch {
        height: 100%;
        overflow-y: auto;
    }
}

//--------------------------
// Profile settings
//--------------------------
.profile-settings-layout {
    .main-info {
        min-width: 430px;
    }
}

// My Accounts widget
.my-accounts {
    width: 320px;

    .toolbar {
        display: flex;
        justify-content: space-between;
        padding: 0;

        h2 {
            display: inline-flex;
            cursor: pointer;
        }
        .add-my-accounts {
            .btn {
                min-width: 45px;
                padding: 4px 6px;
            }
        }
    }

    .group-drug {
        width: 100%;
        padding: 0;
    }
    .inner-drug-container {
        max-height: 450px;
        overflow: auto;
    }
}

@media (max-width: 959px) {
    .profile-settings-layout {
        .my-accounts {
            clear: left;
        }
        .col-3 {
            margin: 15px;
        }
    }
}

.signature {
    font-family: $signature-font-family !important;
    color: $ink-blue;
    font-size: 26px;
    word-break: break-all;
}

#createCompanyPage {
    .search-input {
        margin-bottom: 0;
    }
}

//---------------------------
// Edit User Page, Profile page
//---------------------------
#editUserPage,
#createCompanyPage,
.profile-settings-section {
    .col-form-label {
        font-size: 1rem;
    }
    .form-group {
        align-items: center;
        margin-bottom: 0.5rem;

        .form-control-lg {
            width: 100%;
            max-width: inherit;
        }
    }
    .form-wrapper {
        margin-bottom: 25px;
    }
    .input-group {
        align-items: center;
        .input-group-addon {
            height: 28px;
        }
        .attachment-path {
            margin: 0;
        }
    }
}

.profile-settings-section .form-group .form-control-lg {
    height: auto;
}

//---------------------------
// Drug Search Page
//---------------------------
#drugsearch {
    .drug-search-input-wrapper {
        margin-top: 20px;
    }

    .navbar {
        padding-left: 0;
        padding-right: 0;
    }
    .navbar-nav {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .form-control {
        max-width: 40%;
    }
}

//---------------------------
// Drug Exceptions Page
//---------------------------
.drug-exceptions {
    .navbar {
        padding-left: 0;
        padding-right: 0;
    }
    .navbar-nav {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.enterprise {
  .underline {
    text-decoration: underline;
  }

  .center {
    margin-left: auto;
    margin-right: auto;
  }

  .help {
    border-bottom: 1px dashed $gray;
  }

  .contact {
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    font-weight: bold;
    position: fixed;
    right: 20px;
    top: 129px;
    background-color: lighten($gray-light, 10%);
    padding: 20px;
    .light {
      font-weight: normal;
    }
    .btn {
      font-size: 27px;
      line-height: 33px;
    }

  }

  .first {
    text-align: center !important;
    background-color: lighten($gray-light, 10%);
    color: $gray-darker;
    vertical-align: top;
  }

  @mixin _header($bgColor, $color) {
    color: $color;
    line-height: 36px;
    padding:10px 0;
    margin: 30px 0;
    display: block;
    width: 100%;
    text-align: center;
    background-color: $bgColor;
    font-weight: bold;
    font-size: 24px;
  }

  @mixin descriptionBase($bgColor) {
    background-color: $bgColor;
  }
}

@import 'mixins';

/*
  Two coloured background horizontally
  (http://www.cssmatic@mixin com/gradient-generator)
  $color1 - first color (hex, rgb, rgba)
  $color2 - second color (hex, rgb, rgba)
  $stop1 - split point (percentage or pixels)
*/
@mixin background-2color-h($color1: gray, $color2: white, $stop1: 30%) {
    // Old Browsers
    background: $color1;
    // FF3.6+
    background: -moz-linear-gradient(left, $color1 0%, $color1 $stop1, $color2 $stop1, $color2 100%);
    // Chrome, Safari4+
    background: -webkit-gradient(left top, right top, color-stop(0%, $color1), color-stop($stop1, $color1), color-stop($stop1, $color2), color-stop(100%, $color2));
    // Chrome10+,Safari5.1+
    background: -webkit-linear-gradient(left, $color1 0%, $color1 $stop1, $color2 $stop1, $color2 100%);
    // Opera 11.10+
    background: -o-linear-gradient(left, $color1 0%, $color1 $stop1, $color2 $stop1, $color2 100%);
    // IE 10+
    background: -ms-linear-gradient(left, $color1 0%, $color1 $stop1, $color2 $stop1, $color2 100%);
    // W3C
    background: linear-gradient(to right, $color1 0%, $color1 $stop1, $color2 $stop1, $color2 100%);
    // IE6-9 - remove due to unconsistent behaviour
    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color1', endColorstr='$color2', GradientType=1);
}

@mixin ellipsis-nodisplay() {
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin ellipsis () {
    @extend .ellipsis-nodisplay;
    display: block;
}

@mixin vertical-gradient-bottom($startingColor, $endingColor, $percentageStartingColor: 20%, $percentageEndingColor: 90%) {
    background-image: linear-gradient(bottom, $endingColor $percentageStartingColor, $startingColor $percentageEndingColor);
    background-image: -o-linear-gradient(bottom, $endingColor $percentageStartingColor, $startingColor $percentageEndingColor);
    background-image: -moz-linear-gradient(bottom, $endingColor $percentageStartingColor, $startingColor $percentageEndingColor);
    background-image: -webkit-linear-gradient(bottom, $endingColor $percentageStartingColor, $startingColor $percentageEndingColor);
    background-image: -ms-linear-gradient(bottom, $endingColor $percentageStartingColor, $startingColor $percentageEndingColor);
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.20, $endingColor), color-stop(0.90, $startingColor));

    background-color: $endingColor;
}

@mixin vertical-gradient-top($startingColor, $endingColor, $percentageStartingColor: 20%, $percentageEndingColor: 90%) {
    background-image: linear-gradient(top, $endingColor $percentageStartingColor, $startingColor $percentageEndingColor);
    background-image: -o-linear-gradient(top, $endingColor $percentageStartingColor, $startingColor $percentageEndingColor);
    background-image: -moz-linear-gradient(top, $endingColor $percentageStartingColor, $startingColor $percentageEndingColor);
    background-image: -webkit-linear-gradient(top, $endingColor $percentageStartingColor, $startingColor $percentageEndingColor);
    background-image: -ms-linear-gradient(top, $endingColor $percentageStartingColor, $startingColor $percentageEndingColor);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0.20, $endingColor), color-stop(0.90, $startingColor));

    background-color: $endingColor;
}

@mixin horizontal-gradient ($startColor: #eee, $endColor: white, $percentageStartingColor: 20%, $percentageEndingColor: 90%) {
    background-color: $startColor;
    background-image: -webkit-gradient(linear, left top, right top, from($startColor $percentageEndingColor), to($endColor $percentageEndingColor));
    background-image: -webkit-linear-gradient(left, $startColor $percentageStartingColor, $endColor $percentageEndingColor);
    background-image: -moz-linear-gradient(left, $startColor $percentageStartingColor, $endColor $percentageEndingColor);
    background-image: -ms-linear-gradient(left, $startColor $percentageStartingColor, $endColor $percentageEndingColor);
    background-image: -o-linear-gradient(left, $startColor $percentageStartingColor, $endColor $percentageEndingColor);
}

.savingError {
    background-color: $red;
    bottom: 0;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    display: none;
    font-weight: bold;
    height: 25px;
    left: 100%;
    margin-left: -100%;
    margin-top: -25px;
    position: fixed;
    text-align: center;
    top: 100%;
    width: 100%;
    z-index: $bid-details-save-zindex;

    .error {
        color: $white;
    }
}

.savingSuccess {
    background-color: $white;
    bottom: 0;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    display: none;
    font-weight: bold;
    height: 25px;
    left: 100%;
    margin-left: -152px;
    margin-top: -25px;
    position: fixed;
    text-align: center;
    top: 100%;
    width: 150px;
    z-index: $bid-details-save-zindex;

    .error {
        color: $red;
    }

    .bidssavingspinner {
        display: inline-block;
        width: 22px;
        height: 100%;
        background-size: contain;
        background: url("/assets/css/images/bids-saving-ajax-loader.gif") no-repeat center;
    }

    .bidssavingspinnermessage {
        padding-top: 2px;
        vertical-align: top;
        display: inline-block;
    }
}

// Recreate standard bootstrap button behaviour
.btn-std {
    // Hover/focus state
    &:hover,
    &:focus {
        color: $gray-dark;
        text-decoration: none;
        cursor: pointer;
    }

    // Focus state for keyboard and accessibility
    &:focus {
        @include tab-focus();
    }

    // Active state
    &.active,
    &:active {
        background-image: none;
        outline: 0;
    }

    // Disabled state
    &.disabled,
    &[disabled] {
        cursor: default;
        background-image: none;
        opacity: 0.35;
        box-shadow: none;
    }
}
$toast-info-svg: "M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24zM12,2.7c-5.2,0-9.3,4.2-9.3,9.3s4.2,9.3,9.3,9.3s9.3-4.2,9.3-9.3c0.1-5-3.8-9.2-8.8-9.3C12.4,2.7,12.2,2.7,12,2.7zM10.7,17.4V12c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3v5.4C13.3,19.3,10.7,19.2,10.7,17.4zM12,9c-0.8,0-1.5-0.7-1.5-1.5S11.2,6,12,6s1.5,0.7,1.5,1.5S12.8,9,12,9z";
$toast-warning-svg: "M12,16.7a1.5,1.5,0,1,0,1.5,1.5A1.54,1.54,0,0,0,12,16.7Zm1.5-8.3a1.5,1.5,0,1,0-3,0v5.7a1.5,1.5,0,1,0,3,0V8.4ZM24,20.9A1.11,1.11,0,0,1,22.9,22H1.1A1.11,1.11,0,0,1,0,20.9a1.42,1.42,0,0,1,.2-.6h0L11.1,2.6h0a1.05,1.05,0,0,1,.9-.5,1.1,1.1,0,0,1,1,.6h0L23.9,20.4h0a1.21,1.21,0,0,1,.1.5Z";

$toast-font-family: "Roboto Condensed", sans-serif;

$toast-info-color: #ffffff;
$toast-info-bg: #188b97;
$toast-info-secondary-bg: #7b4aba;

$toast-warning-color: #2e2e2e;
$toast-warning-bg: #ffbe32;

$toast-link-color: #585858;
$toast-link-hover-color: #4f2480;

$toast-close-button-color: #000000;

.toast-top-right {
  top: 184px;
  right: 24px;
}

.toast-container {

  .ngx-toastr,
  .toast {
    width: 420px;
    max-width: 420px;
    margin-bottom: 16px;
    padding: 22px 16px 18px 64px;
    background-position: 24px center;
    background-repeat: no-repeat;

    font-family: $toast-font-family;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;

    border-radius: 2px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
    opacity: 0.85;

    pointer-events: auto;

    &:hover {
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);
      opacity: 1;
    }

    a {
      color: $toast-link-color;
      text-decoration: underline;

      &:hover {
        color: $toast-link-hover-color;
      }
    }

    .toast-close-button {
      right: 0;
      font-weight: normal;
      color: inherit;
      text-shadow: none;
      transform: scale(1.17, 1);
      outline: none;
    }

    .toast-close-button:hover,
    .toast-close-button:focus {
      color: $toast-close-button-color;
    }

    &-info {
      @include svg((viewBox: 0 0 24 24)) {
        @include svg('path', (fill: $toast-info-color, d: $toast-info-svg))
      }
      background-size: 24px 24px;
      background-color: $toast-info-bg;
      color: $toast-info-color;
    }

    &-info-secondary {
      background-color: $toast-info-secondary-bg;
    }

    &-warning {
      @include svg((viewBox: 0 0 24 24)) {
        @include svg('path', (fill: $toast-warning-color, d: $toast-warning-svg))
      }
      background-size: 24px 24px;
      background-color: $toast-warning-bg;
      color: $toast-warning-color;
    }
  }
}

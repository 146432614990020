@import "../../styles/shared/mixins";
@import "../../styles/themes/new-ui";

input:disabled {
  background-color: $white-smoke;
  border-color: $alto;
}

input::-ms-clear {
  display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.qv-input {
  height: rem(40px);
  padding: rem(10px);
  font-family: $font-family-default;
  font-size: rem(14px);
  line-height: rem(14px);
  border: solid rem(1px) $alto;
  outline: none;

  @include placeholder {
    color: $silver;
  }

  &:focus, &:active, &:focus:hover, &:active:hover {
    border: solid rem(1px) $royal-purple;
  }

  &:hover {
    border: solid rem(1px) $tundora;
  }

  &--primary {
    border-radius: rem(2px);
    width: 100%;
    padding: rem(10px) rem(16px);
    &.ng-invalid.ng-touched {
      border: solid rem(1px) $scarlet;
    }
  }

  &-label {
    &--primary {
      position: relative;
      display: block;
      font-size: rem(12px);
      letter-spacing: rem(0.4px);
      color: $medium-grey;
      margin-bottom: 0;

      .title {
        display: inline-block;
        margin-bottom: rem(4px);
      }
    }
  }
}

.dropdown-toggle.no-toggle-icon {
    &::after {
        display: none;
    }
}
.dropdown-menu {
    padding: 0.3rem 0;

    &::after {
        content: '';
        display: inline-block;
        border-left: 0.4rem solid transparent;
        border-right: 0.4rem solid transparent;
        border-bottom: 0.4rem solid $white;
        position: absolute;
        top: -0.4rem;
        left: 0.6rem;
    }
    .dropdown-item {
        padding-bottom: 10px;
        padding-top: 10px;
        cursor: pointer;

        &.disabled, &:disabled {
          pointer-events: auto;
          cursor: default;

          .qv-icon-gray {
            color: $dropdown-link-disabled-color;
          }
        }
    }

    &.dropdown-menu-right {
        left: auto;
        right: 0;
    }
    &.no-arrow {
        &::after {
            display: none;
        }
    }
    &:focus {
        outline: none;
    }
}

//---------------------------------------------
// Dropdown submenus
// Copied from Bootstrap 2
// TODO: Improve UX by rewriting templates that use it. (Should be discussed first)
//---------------------------------------------
.dropdown-submenu { position: relative }
.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.dropdown-submenu:hover>.dropdown-menu { display: block }
.dropdown-submenu>.dropdown-submenu-toggle:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-right-width: 0;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}
.dropdown-submenu:hover>.dropdown-submenu-toggle:after { border-left-color: #ccc }
.dropdown-submenu.pull-left { float: none }
.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

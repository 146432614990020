@import "../../ngx-src/assets/styles/shared/mixins";
@import "../../ngx-src/assets/styles/themes/new-ui";

.modal-header {
    padding: 0 15px;
    border-bottom: 1px solid $theme-color;
    background-color: $theme-color;
    color: $white;
    font-weight: 600;
    h1, h2, h3 {
        padding-top: 0.5rem;
        font-weight: 600;
    }
    .pull-up {
        margin-top:-10px;
    }
}
.modal-content {
    overflow: hidden;
    height: 100%;
    text-align: initial;
}

.modal-body {
    font-size: $font-size-base;
    padding-bottom: 0;
}

.modal-backdrop {
    &.fade {
        opacity: 0.5;
    }
}

.modal-scrollable {
    overflow-x: hidden;
}

@media (max-width: 979px) {
    .footerModals {
        height: 90% !important;
        width: 80% !important;
        left: 10%;
        .heightFixed {
            overflow: auto !important;
        }
    }
}

.modal-footer {
    input[type="checkbox"] {
        margin-bottom: 5px;
    }
}
#drugSearch.modal {
    width: 80%;
    left: 10%;
    margin-left: 0;
}
//--------------------------
// Market Basket Popup
//--------------------------
#mbPicker {
    .modal-dialog {
        width: 80%;
        max-width: 100%;
    }

    .modal-content {
        min-height: 300px;
        height: 80vh;
        max-height: 80vh;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;
        padding: 0 0 62px;
        overflow-y: auto !important;
    }

    .picker-main-content {
        padding-top: 60px;
        .list,
        .info {
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1 1 0%;
        }

        .mb-form {
            .row {
                align-items: center;
                margin-bottom: 12px;
            }

            .picker-toolbar > .row {
                margin-bottom: 0;
            }
        }
    }

    .alert-danger-top {
        top: -15px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        text-align: center;
        width: 600px;
        background-color: $pinkish;
    }

    @include breakpoint-up($laptop) {
        .alert-danger-top {
            width: 500px;
        }
    }

    .alert-info-top {
        @extend .alert-danger-top;
        background-color: $state-info-bg;
    }

    .modal-footer {
        position: absolute;
        bottom: 1px;
        right: 0;
        left: 0;
        padding-top: 12px;
    }

    .mbName {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 8px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        .text {
            vertical-align: middle;
            margin-left: 7px;
        }

    }
}

.picker-main-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    overflow-y: auto;

    .list {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        height: auto;
    }

    .content {
        display: flex;
        flex: 1 1 0%;
        overflow-y: auto;
    }

    .info-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;
    }

    i.fa-remove {
        position: absolute;
        margin: 7px 0 0 -20px;
    }

    .search-input-large {
        z-index: inherit;
        padding-right: 20px;

        &::-ms-clear {
            //this is for IE10+ to not display the 'x' into the input
            display: none;
        }
    }

    .left-side, .right-side {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.modal.confirm-sign-out-modal {
    margin-left: 0;
    left: 33%;
    width: 34%;
}

@include media-breakpoint-up(xlg) {
  .drug-search-modal {
      max-width: 100%;
      min-width: 100%;
  }
}

.modal-scrollable {
  height: 90%;
  .modal-content {
    display: block;
    .modal-body {
      overflow-x: hidden;
      overflow-y: auto;
      height: calc(100% - 103px); // 103px - header and footer height for standard modal
    }
  }
}

.window-top {
  border: none;
}

.modal {
  text-align: center;
  padding: 0!important;
}

.modal::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal.fade.in {
  opacity: 1;
}

$ng-select-highlight: #4f2480;
$ng-select-primary-text: #666666;
$ng-select-secondary-text: #333333;
$ng-select-disabled-text: #b8b8b8;
$ng-select-divider: #f1e6f4;
$ng-select-border: #cacaca;
$ng-select-border-hover: #4e4e4e;
$ng-select-error-color: #e22500;
$pizzaz: #ff8a00;

.acc-ng-select {
  &--default {
    font-family: "Roboto", sans-serif;

    &.ng-select {
      padding-bottom: 0;
      .ng-select-container {
        border-radius: 2px;
        font-size: 16px;
        color: $ng-select-secondary-text;
        &:after {
          border: solid 1px $ng-select-border;
          box-sizing: border-box;
          border-radius: 2px;
        }
        &.ng-appearance-outline {
          height: 40px;
          padding: 0 16px;
          min-height: auto;
          align-items: center;
          &:after {
            height: 40px;
          }
          &:hover:after {
            border-color: $ng-select-border-hover;
            border-width: 1px;
          }
          &.ng-has-value {
            .ng-value-container {
              .ng-placeholder {
                display: none;
              }
            }
          }
          .ng-value-container {
            .ng-placeholder {
              position: relative;
              top: auto;
              padding: 0;
              transform: none;
              background-color: transparent;
            }
          }
        }
        .ng-value-container {
          align-items: center;
          padding: 0;
          border-top: none;
          color: $ng-select-primary-text;
        }
      }

      &.ng-select-single {
        .ng-select-container {
          &.ng-appearance-outline {
            .ng-arrow-wrapper {
              display: flex;
              align-items: center;
              align-self: center;
              width: auto;
              bottom: auto;
              cursor: pointer;
            }
          }

          .ng-value-container {
            .ng-input {
              flex: 0;
              top: 0;
              left: auto;
              width: auto;
              height: 100%;
              max-width: 100%;
              padding: 0;

              input {
                font: inherit;
                height: 100%;
              }
            }
          }
        }
        &.ng-select-filtered {
          .ng-select-container {
            .ng-value-container {
              .ng-value {
                display: none;
              }
            }

            .ng-placeholder {
              display: none;
            }
          }
        }
      }
    }

    &.ng-select-focused {
      .ng-select-container {
        &.ng-appearance-outline {
          &:after,
          &:hover:after {
            border-color: $ng-select-highlight;
            border-width: 1px;
          }
        }
        .ng-value-container {
          .ng-placeholder {
            display: none;
          }
        }
      }

      &:not(.ng-select-opened) > .ng-select-container {
        border-color: $ng-select-highlight;
        box-shadow: none;
      }
    }

    &.ng-select-opened {
      > .ng-select-container {
        .ng-arrow {
          top: 0;
        }
      }
    }

    &.ng-dropdown-panel,
    .ng-dropdown-panel {
      &.ng-select-bottom {
        top: calc(100% + 8px);
      }

      .ng-dropdown-panel-items {
        .ng-option {
          color: $ng-select-secondary-text;
          line-height: 40px;
          min-height: 40px;
          text-decoration: none;
          position: relative;
          text-align: left;
          padding: 0 16px;

          &.ng-option-selected {
            background: $ng-select-divider;
            .ng-option-label {
              font-weight: normal;
            }
          }
          &.ng-option-disabled {
            color: $ng-select-disabled-text;
            cursor: default;
          }
          .truncated-option {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &--height-24 {
    &.ng-select {
      .ng-select-container {
        font-size: 14px;

        &.ng-appearance-outline {
          height: 24px;
          padding: 0 8px;

          &:after {
            height: 24px;
          }
        }

        .ng-value-container {
          font-size: 12px;
        }
      }
    }
  }


  &--height-42 {
    &.ng-select {
      .ng-select-container {
        &.ng-appearance-outline {
          height: 42px;

          &:after {
            height: 42px;
          }
        }
      }
    }
  }

  &--padding-default {
    &.ng-select {
      .ng-select-container {
        &.ng-appearance-outline {
          padding: 0 0.5em;
        }
      }
    }
  }

  &--panel-width-auto {
    .ng-dropdown-panel {
      width: auto;
      min-width: 100%;
    }
  }

  &--panel-align-right {
    .ng-dropdown-panel {
      right: 0;
      left: initial;
    }
  }

  &--arrow-smaller {
    &.ng-select {
      .ng-select-container {
        &.ng-appearance-outline {
          .ng-arrow-wrapper {
            .ng-arrow {
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              border-top: 4px solid;
            }
          }
        }
      }
    }
  }

  &--outlined-arrow {
    &.ng-select {
      .ng-select-container {
        &.ng-appearance-outline {
          .ng-arrow-wrapper {
            .ng-arrow {
              width: 8px;
              height: 8px;
              transform: rotate(45deg);
              border-bottom: 2px solid #8a8a8a;
              border-right: 2px solid #8a8a8a;
              border-top: 0;
              border-left: 0;
            }
          }
        }
      }
    }
  }

  &--multi-select {

    &.ng-select {
      &.ng-select-multiple {
        .ng-select-container {
          .ng-value-container {
            align-items: center;
            padding: 0;

            .ng-input {
              flex: 0;
              padding: 0;
            }
          }
        }
      }

      .ng-select-container {
        padding: 0 12px;

        .ng-value-container {
          .ng-placeholder {
            transform: none;
          }
        }
      }
    }

    .ng-dropdown-panel {
      .ng-dropdown-header {
        border: 0;
        min-height: initial;
        padding: 16px 16px 8px 16px;
        line-height: initial;
      }
    }
  }

  &--error {
    &.ng-select {
      .ng-select-container {
        &.ng-appearance-outline {
          &:after,
          &:hover:after {
            border-color: $ng-select-error-color;
          }
        }
      }
    }
  }

  &--warning {
    &.ng-select {
      .ng-select-container {
        &.ng-appearance-outline {
          &:after,
          &:hover:after {
            border-color: $pizzaz;
          }
        }
      }
    }
  }

  &--disabled {
    &.ng-select {
      .ng-select-container.ng-appearance-outline {
        &:after {
          background: transparent;
        }

        &:hover:after {
          border-color: $ng-select-border;
        }
      }
    }
  }
}

@import "../themes/new-ui";
@import "../shared/mixins";
@import "../qv/icons";

.pointer {
  cursor: pointer;
}

.qv-hidden {
  display: none !important;
}

.qv-read {
  @include fontSize(14px);
  font-family: 'Roboto', sans-serif;
  display: inline-flex;
  color: $royal-purple;
  background-color: $white-lilac;
  font-weight: 500;
  border: none;
  cursor: pointer;
  outline: none;
  line-height: rem(20px);

  &[disabled] {
    background-color: $gallery;
    color: $dusty-gray;
    cursor: not-allowed;
  }

  &--more, &--less {
    float: left;
    margin-right: rem(8px);
  }

  &--more:before, &--less:before {
    display: inline-flex;
    content: "";
    width: rem(16px);
    height: rem(16px);
    margin-right: 4px;
    color: $royal-purple;
    background-repeat: no-repeat;
    transform: scale(2);
    position: relative;
    top: rem(2px);
  }

  &--more:before {
    @include svg((
            viewBox: 0 0 24 24
    )) {
      @include svg('path', (
              fill: $royal-purple,
              d: $chevron-bottom-svg
      ))
    }
  }

  &--less:before {
    @include svg((
            viewBox: 0 0 24 24
    )) {
      @include svg('path', (
              fill: $royal-purple,
              d: $chevron-top-svg
      ))
    }
  }

  &--external {
    .mat-icon {
      height: rem(14px);
      width: rem(14px);
      margin-right: rem(4px);
    }
  }
}

.qv-read-more-container {
  position: relative;
  overflow: hidden;
  transition: height $default-animation-duration ease;

  &--fade {
    &:after {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100px;
      content: "";
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $white);
    }
  }

}

.term-loading-spinner {
  svg {
    width: rem(20px);
    height: rem(20px);
  }
}

.ng1-wrapper-container {
  display: block;
  width: 100%;
  padding-left: rem(15px);
  padding-right: rem(15px);
}

@import "../../styles/shared/mixins";
@import "../../styles/themes/new-ui";

.mat-button,
.mat-icon-button {
  &:hover:not(:disabled), &.cdk-program-focused:hover:not(:disabled) {
    .mat-button-focus-overlay {
      opacity: 0.16;
      background-color: $tundora;
    }
  }

  /*
  TODO: Use "restoreFocus" parameter instead this one after migration to 7 version
  https://material.angular.io/components/dialog/api#MatDialogConfig
  */
  &.cdk-program-focused {
    .mat-button-focus-overlay {
      opacity: 0 !important;
    }
  }
}

button.qv-button {
  @include fontSize(14px);
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: rem(0.4px);
  line-height: 1.33;
  color: $white;

  &--primary {
    color: $white;
    background-color: $meteorite;

    &:hover:not(:disabled) {
      background-color: $studio;
      color: $white;
      box-shadow: 0 1px 2px 0 rgba(black, 0.4);
    }

    &:active:not(:disabled) {
      background-color: $studio;
      color: $white;
      box-shadow: 0 4px 12px 0 rgba(black, 0.3);
    }

    &:disabled {
      background-color: $gallery;
      color: $dusty-gray;
    }

    .mat-button-focus-overlay {
      background-color: transparent;
    }
  }

  &--h-32 {
    height: rem(32px);

    .mat-icon {
      width: rem(16px);
      height: rem(16px);
    }
  }

  .mat-button-wrapper >* {
    display: inline-flex;
    align-items: center;
  }

  .qv-button__icon-left {
    margin-right: rem(8px);
  }

  .qv-button__icon-right {
    margin-left: rem(8px);
  }
}

button.qv-text-btn {
  @include fontSize(12px);
  font-weight: 500;
  color: $royal-purple;
  text-transform: uppercase;

  &:hover:not(:disabled) {
    color: $studio;
    background-color: $snuff;
  }

  &:active:not(:disabled) {
    color: $royal-purple;
  }

  &:disabled {
    color: $silver;
  }

  .mat-button-focus-overlay {
    width: 0;
  }

  &--white {
    padding: 0;
    border: none;
    background: transparent;
    color: $white;

    &:hover:not(:disabled) {
      color: $white;
      opacity: 0.8;
    }
  }
}

.close-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: rem(24px);
  height: rem(24px);
  padding: 0;
  border: 0;
  color: $medium-grey;
  background: none;

  &:hover:not(:disabled) {
    color: $studio;
  }

  &:active:not(:disabled) {
    color: $royal-purple;
  }

  &:disabled {
    color: $silver;
  }
}

button.qv-button-enterprise {
  background-color: $upgrade-enterprise-color;
  line-height: 34px;
}

qv-action-button {
  display: inline-block;

  &:focus {
    outline: none;
  }
}

button.mat-icon-button, button.tab-group {
  &:disabled,
  &[disabled][disabled] {
    color: $dusty-gray;
    box-shadow: none;
    cursor: not-allowed;
  }
}

button.toolbar-button {
  height: 32px;
  min-width: 32px;
  border-radius: 2px;
  background-color: $control-color-dark;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  padding: 0 rem(7px);
  margin-left: rem(10px);

  font-family: $font-family-default;
  @include fontSize(14px);
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $white;

  &:active {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);
  }

  &:disabled,
  &[disabled][disabled] {
    background-color: $control-color-disabled;
    color: $dusty-gray;
    box-shadow: none;
    cursor: not-allowed;
  }

  .mat-button-wrapper>* {
    display: inline-flex;
    align-items: center;
  }

  .mat-icon {
    width: 20px;
    height: 20px;
  }

  .toolbar-button__title {
    margin-left: rem(10px);

    &:not(.toolbar-button__title--exclude-breakpoint) {
      display: none;

      @include breakpoint($laptop) {
        display: inherit;
      }
    }
  }

  .toolbar-button__icon-right {
    margin-left: rem(8px);
  }

  &--primary {
    background-color: $control-color-dark;

    &:hover,
    &:active {
      background-color: $control-color-middle-dark;
    }
  }

  &--secondary {
    background-color: $control-color-light;

    &:hover,
    &:active {
      background-color: $control-color-lighter;
    }
  }

  &--warn {
    background-color: $control-color-warn;

    &:hover,
    &:active {
      background-color: $control-color-warn-lighter;
    }
  }

  &--extra {
    color: $cape-code;
    background-color: $gallery;

    &:hover,
    &:active {
      background-color: $mercury;
    }
  }

  &--transparent {
    color: $silver;
    background-color: transparent;
    margin-left: rem(8px);

    &.mat-raised-button.toolbar-button {
      box-shadow: none;
      &:active {
        box-shadow: none;
      }
    }
    &:hover,
    &:active {
      background-color: transparent;
    }
  }

  &--upgrade {
    color: $upgrade-enterprise-color;
    border: 1px solid $upgrade-enterprise-color;
    box-shadow: none;

    &:disabled,
    &[disabled][disabled] {
      color:  $upgrade-enterprise-color;
    }
  }
}

button.toolbar-button.mat-raised-button {
  -ms-transform: none;
}

button.menu-button {
  font-family: $font-family-default;
  @include fontSize(14px);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $cape-code;

  .mat-icon {
    width: 20px;
    height: 20px;
    margin-right: rem(10px);
  }

  &--upgrade {
    color: $upgrade-enterprise-color;

    .mat-icon:not([color]) {
      color: $upgrade-enterprise-color;
    }
  }
}

.qv-toggle-button {
  height: 32px;
  margin-right: rem(8px);

  .mat-button-toggle {
    display: flex;
    align-items: center;
    font-family: $font-family-default;
    color: $medium-grey;
    @include fontSize(14px);
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    background-color: $white;

    &.cdk-focused .mat-button-toggle-focus-overlay {
      background: none;
    }

    .mat-button-toggle-button {
      margin: 0;
      @include fontSize(14px);

      .mat-button-toggle-label-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 rem(10px);
        line-height: 1.33;

        & >* {
          display: inline-flex;
        }

        .mat-icon {
          width: 20px;
          height: 20px;
        }

        .title {
          display: none;
          margin-left: rem(7px);

          @include breakpoint($laptop) {
            display: block;
          }
        }
      }
    }

    &-checked {
      background-color: $control-color-dark;
      color: $white;
    }
  }
}

.link-btn {
  padding: 0;
  border: none;
  background: none;
  font-family: $font-family-default;
  color: $royal-purple;
  cursor: pointer;

  &:hover {
    color: $studio;
  }

  &:disabled {
    color: $silver;
    cursor: not-allowed;
  }

  &--upgrade {
    color: $upgrade-enterprise-color;

    &:hover {
      color: $upgrade-enterprise-color;
    }
  }
}

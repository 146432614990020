@import "../shared/mixins";
@import "../themes/new-ui";

@mixin colorizeScenarioSection($color) {
  .standard-scenario-section {
    background-color: $color;

    &-container {
      background-color: $color;
    }

    .range-or-unit-and-rebate {
      background-color: $color;
    }
  }
}

.sidenav-content {
  width: 100%;
}

.standard-view {
  margin: rem(24px) rem(24px) 0;

  .mat-tab-body-wrapper {
    height: calc(100vh - 200px);
    background-color: $white;
  }

  .mat-tab-body-content {
    display: flex;
    flex-direction: column;
    padding: rem(16px) 0 0 0;
  }

  .tab-group {
    border: solid 1px $isabelline;
  }

  .ng-select {
    margin-right: rem(16px);
  }

  &__empty-scenarios {
    margin-left: rem(16px);
    margin-top: rem(8px);
    color: $medium-grey;
  }

  .term-date-picker {
    width: rem(130px);
    height: rem(40px);
  }
}

.standard-scenario-section {
  display: flex;
  background-color: $white-smoke;
  border-bottom: rem(1px) solid $alto;

  &-container {
    position: relative;
    width: rem($standard-scenario-section-name-width);
    min-width: rem($standard-scenario-section-name-width);
    padding: rem(12px) rem(16px);
    background-color: $isabelline;
    height: 100%;
    border-right: rem(1px) solid $alto;
    border-bottom: rem(1px) solid $alto;
  }

  &__terms {
    border-bottom: rem(1px) solid $alto;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex: 1;
    padding: 0 rem(16px) rem(12px);
  }

  &__term {
    display: flex;
    margin: rem(12px) rem(22px) 0 0;

    &:last-child {
      margin-right: 0;
    }

    .mat-icon {
      color: $silver;
    }
  }

  .term {
    &__validation {
      &--error {
        color: $error-color;

        .ng-select-container {
          background-color: $pink-lady;
        }

        .term__field {
          background-color: $pink-lady;
        }

        input{
          background-color: $pink-lady;
        }
      }
    }

    &__notes, &__basket {
      position: relative;
      left: -4px;

      .qv-button--at-ndc {
        padding-left: rem(4px);
      }

      .mat-icon-button {
        width: rem(20px);
        height: rem(20px);

        .mat-icon {
          width: rem(16px);
          height: rem(16px);
        }
      }
    }
  }

  &--edit-mode {
    .standard-um-details,
    .term-date,
    .term__input,
    .term-currency,
    .term__select,
    .standard-scenario-section__term--range-or-units-and-rebates {
      qv-at-ndc {
        display: flex;
        height: rem(40px);
        align-items: center;
      }
    }
  }
}

//--------------------------------------
// Overrides styles for dismissed drugs
//--------------------------------------
.standard-scenario, .standard-ndc {
  &--dismissed {

    .mat-icon {
      @include colorizeIcon($medium-grey);
      @include colorizeIconWithBackground($white-lilac, $medium-grey);
    }

    .standard-scenario-section-container {
      &__status, &__at-ndc {
        color: $medium-grey !important;
      }
    }

    .standard-scenario-header, .standard-ndc-header {
      background-color: $isabelline !important;

      &__toggler {
        @include colorizeIcon($white);
      }

      .scenario-title, &__toggler-icon {
        background-color: $medium-grey !important;
      }

      &__left-block, &__drug-name, &__drug-description, &__wac {
        color: $medium-grey !important;
      }

      .wac {
        &__item, &__value--ndc {
          color: $medium-grey !important;
        }
      }
    }

    .standard-scenario__body, .standard-ndc__body {
      @include colorizeScenarioSection($isabelline);

      .standard-cell {
        &__content-container, &__name-container {
          color: $medium-grey !important;

          .at-ndc, .term__validation--error {
            color: $medium-grey !important;
          }
        }
      }

      .standard-scenario-state-select {
        color: $medium-grey !important;
      }
    }
  }
}

.standard-ndc {
  @include colorizeScenarioSection($white-lilac);

  .standard-scenario-section {
    &-container {
      width: rem($standard-ndc-section-name-width);
      min-width: rem($standard-ndc-section-name-width);
    }
  }
}
